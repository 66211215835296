import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {userSelector} from "../store/user/userSlice";
import {config} from "../config";

const IgnoreRoute = () => {
    const location = useLocation();
    const user = useSelector(userSelector)
    
    return (
        user?.auth
            ? <Navigate to={config.routes.ROOT} state={{from: location}} replace/>
            : <Outlet/>
    );
}

export default IgnoreRoute;

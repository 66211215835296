import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Switch from 'react-switch';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import { useSocketEmit } from '../../../hooks/useSocketEmit';
import Button from '../../ui/Button';
import Input from '../../ui/Input';
import {Controller, useForm} from 'react-hook-form';
import Text from '../../ui/Text';
import {theme} from '../../../theme';
import {config} from '../../../config'


import {InfinitySpin} from 'react-loader-spinner'
import {ErrorMessage} from '@hookform/error-message';
import { userSelector} from '../../../store/user/userSlice';
import { productsSelector} from '../../../store/products/productsSlice';
import Product from './Product';

const Edit = ({onEdited, onError, user, onClose}) => {
    const {register, control, watch, handleSubmit, formState: {errors}} = useForm({});
    const {t} = useTranslation();
    const issuer = useSelector(userSelector);
    const allProducts = useSelector(productsSelector).products;
    const [productIds, setProductIds] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [result, setResult] = useState(null);
    const watchName = watch('name')
    const watchLon = watch('lon')
    const watchLat = watch('lat')
    const watchFarmArea = watch('farmArea')
    const watchHeadNumber = watch('headNumber')
    const {editUser} = useSocketEmit();

    useEffectOnce(()=>{
        if(user?.extraData?.products?.length>0){
            setProductIds(user.extraData.products.map(p=>p.id))
        }

    }, [user])

    const onClick = async () => {

        setProcessing(true);
        setResult(null);
        try {
            const jwt = issuer.auth.accessToken
            const responseID = await fetch(process.env.REACT_APP_API_URL + `user/${user.id}/updateData`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt,
                        'app-id': process.env.REACT_APP_APP_ID
                    },
                    body: JSON.stringify({
                        "name": watchName
                    })
                }
            )

            if (!responseID.ok) {
                throw new Error(t('routes.users.errors.generic') + responseID.statusText);
            }

            const userData = {
                name: watchName,
                userId: user.id,
            }
            if(user.role === config.roles.ORIGIN) {
                userData.location =  {lat:watchLat, lon:watchLon};
                userData.products =  productIds;
                userData.farmArea = watchFarmArea;
                userData.headNumber = watchHeadNumber;
            }
            const responseBE = await editUser(userData);

            console.log('responseBE - ', responseBE)
            setResult({name:watchName})
            userData.products=allProducts.filter(p=>productIds.includes(p.id));
            onEdited(userData);
        } catch (error) {
            onError(error.message)
        }

        setProcessing(false)

    }


    const buildProductIds = id => {
        let prods = [...productIds];
        if (prods.includes(id)) {
            prods = prods.filter(p => p !== id);
        } else {
            prods.push(id)
        }

        setProductIds(prods);
    }

    const getErrorType = () => {
        if (errors.name) {
            return 'name';
        }
        if (errors.lat) {
            return 'lat';
        }
        if (errors.lon) {
            return 'lon';
        }
        if (errors.headNumber) {
            return 'headNumber';
        }
        if (errors.farmArea) {
            return 'farmArea';
        }
        if (errors.milk && errors.mountainMilk) {
            return 'milk';
        }
        return null;
    };

    return (
      <div style={{
          textAlign: 'center',
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
      }}>
            <Text variant={'h2'}>{t('routes.users.details.editTitle')}</Text>
            <form style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={styles.fieldContainer}>
                    <Input style={styles.name}
                           disabled={processing}
                           type="text"
                           onKeyDown={()=>setResult(null)}
                           defaultValue={user.name}
                           placeholder={t('routes.users.namePlaceholder')}
                           {...register("name", {
                               required: t('routes.users.errors.nameEmpty'),
                               minLength: {value: 3, message: t('routes.users.errors.nameTooShort')},
                           })}
                    />
                </div>
                {user.role===config.roles.ORIGIN &&<>
                    <div style={styles.fieldContainer}>
                        <Input
                          style={styles.leftField}
                          disabled={processing}
                          type="text"
                          onKeyDown={()=>setResult(null)}
                          defaultValue={user.extraData?.location?.lat}
                          placeholder={t('routes.users.latitudePlaceholder')}
                          {...register("lat", {
                              required: t('routes.users.errors.latitudeEmpty'),
                              pattern: {
                                  value: /^\d+\.\d+$/,
                                  message: t('routes.users.errors.invalidLatitude')
                              },
                          })}
                        />
                        <Input
                          disabled={processing}
                          type="text"
                          onKeyDown={()=>setResult(null)}
                          placeholder={t('routes.users.longitudePlaceholder')}
                          defaultValue={user.extraData?.location?.lon}
                          {...register("lon", {
                              required: t('routes.users.errors.longitudeEmpty'),
                              pattern: {
                                  value: /^\d+\.\d+$/,
                                  message: t('routes.users.errors.invalidLongitude')
                              },
                          })}
                        />
                    </div>
                    <div style={styles.fieldContainer}>
                        <Input
                          style={styles.leftField}
                          disabled={processing}
                          type="text"
                          onKeyDown={()=>setResult(null)}
                          placeholder={t('routes.users.farmAreaPlaceholder')}
                          defaultValue={user.extraData?.farmArea}
                          {...register("farmArea", {
                              required: t('routes.users.errors.farmAreaEmpty'),
                              pattern: {
                                  value: /^\d+\.?\d*$/,
                                  message: t('routes.users.errors.invalidFarmArea')
                              },
                          })}
                        />
                        <Input disabled={processing}
                           type="number"
                           onKeyDown={()=>setResult(null)}
                           placeholder={t('routes.users.headNumber')}
                           defaultValue={user.extraData?.headNumber}
                           {...register("headNumber", {
                               required: t('routes.users.errors.headNumberEmpty'),
                               pattern: {
                                   value: /^\d+$/,
                                   message: t('routes.users.errors.invalidHeadNumber')
                               },
                           })}
                        />
                    </div>
                </>}

                {user.role === 'origin' &&
                    <div style={styles.productList}>
                        {allProducts.map(p=><Product
                          key={p.id}
                          product={p}
                          control={control}
                          buildProductIds={buildProductIds}
                          productIds={productIds}/>)}
                    </div>
                }

                <ErrorMessage
                    errors={errors}
                    name={getErrorType()}
                    render={({message}) => <Text variant={'p1'} color={theme.colors.warning}
                                                 style={{marginRight: theme.spacing['spacing-03']}}>{message}</Text>}
                />


                <div style={styles.buttonsContainer}>
                    <Button disabled={processing} stylex={{ marginRight: theme.spacing['spacing-02'], paddingBottom: theme.spacing['spacing-02'], paddingTop: theme.spacing['spacing-02']}} type={'submit'}
                            onClick={handleSubmit(d => onClick(d))} text={t('routes.users.details.editButton')}/>
                    <Button disabled={processing} stylex={{ paddingBottom: theme.spacing['spacing-02'], paddingTop: theme.spacing['spacing-02']}}
                            onClick={onClose} text={t('routes.users.details.closeButton')}/>
                </div>

            </form>


            {processing &&
                <InfinitySpin
                    width='200'
                    color="#4fa94d"
                />}
            {result && <Text variant='default_text' color={theme.colors.success}
                             >{t('routes.users.resultMessage', {name:result.name, email:result.email})}</Text>}

        </div>
    );
}

const styles = {
    name: {
        marginRight: theme.spacing['spacing-05'],
    },
    leftField: {
        marginRight: theme.spacing['spacing-05'],
    },
    fieldContainer: {
        height: theme.spacing['spacing-09'],
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing['spacing-05'],
    },
    role: {
        borderWidth: 1,
        border: 'solid',
        borderColor: theme.colors.lightGrey,
        borderRadius: 10,
        ...theme.components.Text.variants.default_text,
        width: '100%',
        display: 'flex',
        outline: 'none',
        color: theme.colors.black,
        backgroundColor: theme.colors.white,
        marginBottom: theme.spacing['spacing-05'],
        paddingLeft: theme.spacing["spacing-05"],
        paddingRight: theme.spacing["spacing-05"],
        height: theme.spacing['spacing-09'],
    },
    productList: {
        width: '100%'
    },
    product: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: theme.spacing['spacing-05']
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
    }
}

export default Edit;

export function plateIdValidator(trucks) {
  return {
    required: true,
    minLength: { value: 7, message: 'trucks.common.errors.plateIdLength' },
    maxLength: { value: 7, message: 'trucks.common.errors.plateIdLength' },
    validate: value => {return !trucks.find(t=>t.plateId===value) ? true : 'trucks.common.errors.plateIdUsed'}
  }
};


export const descriptionValidator = {
  required: false,
  minLength: { value: 3, message: 'trucks.common.errors.minDescription' },
  maxLength: { value: 1000, message: 'trucks.common.errors.maxDescription' },
};

export function tankIdValidator(hasTanks) {
  return hasTanks ? { required: false } : {
    required: true,
    minLength: { value: 3, message: 'trucks.common.errors.tankIdMinLength' },
    maxLength: { value: 10, message: 'trucks.common.errors.tankIdMaxLength' },
  }
}

export function tankCapacityValidator (hasTanks) {
  return hasTanks ? {required: false} : {
    required: true,
    min: { value: 1, message: 'trucks.common.errors.tankCapacity' },
  };

};



import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'App-id': process.env.REACT_APP_APP_ID,
    },
    timeout: 3000,
});

export default instance;

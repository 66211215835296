import {createContext, useContext, useEffect, useState} from "react";
import {useSocketEmit} from "../hooks/useSocketEmit";
import {config} from "../config";
import PolyvalentModel from "../models/processingItems/PolyvalentModel";
import {useSelector} from "react-redux";
import {userSelector} from "../store/user/userSlice";

export const ProcessingItemsContext = createContext();

export const useProcessingItems = () => useContext(ProcessingItemsContext)

export const ProcessingItemsProvider = ({children}) => {
    const [processingItems, setProcessingItems] = useState([]);
    const {getProcessItems} = useSocketEmit()
    const user = useSelector(userSelector)

    const refreshProcessingItems = () => {
        getProcessItems().then(res => {
            if (res.steps) {
                let arr = []
                for (const property in res.steps) {
                    arr.push({step: property, items: res.steps[property]})
                }
                setProcessingItems(arr)
            }
        }).catch(console.error)
    }

    const getProcessingItemByStepAndId = (step, itemId) => {
        const stepFound = findStep(step)
        const found = stepFound?.items?.find(item => item.id === itemId)
        return found
    }

    const getLoadsForStepById = (step, itemId) => {
        const stepFound = findStep(step)
        const found = stepFound?.items?.find(item => item.id === itemId)
        const loads = found?.loads?.map(item => item).reverse()
        return loads
    }

    const findStep = (step) => {
        const stepFound = processingItems?.find(item => item.step === step)
        if (stepFound?.items?.length > 0)
            return stepFound
        else return null
    }

    const getPolyvalents = () => {
        const polyvalentsArray = processingItems?.find(processingItem => processingItem.step === config.processingSteps.STEP_02)
        if (polyvalentsArray.items?.length > 0) {
            const withModel = polyvalentsArray.items.map(item => PolyvalentModel(item))
            return withModel
        } else {
            return []
        }
    }

    const getStepItems = (step, model) => {
        const stepItemsArray = processingItems?.find(processingItem => processingItem.step === step)
        if (stepItemsArray.items?.length > 0) {
            const withModel = stepItemsArray.items.map(item => model(item))
            return withModel
        } else {
            return []
        }
    }

    const updateStepById = (step, id, data) => {
        const stepFound = findStep(step)
        if (stepFound) {
            const updated = stepFound.items?.map(stepItem => {
                if (stepItem.id === id) {
                    return {...stepItem, ...data}
                } else return stepItem
            })

            setProcessingItems(processingItems => {
                return processingItems.map(processingItem => {
                    if (processingItem.step === step) {
                        return {...processingItem, items: updated}
                    } else return processingItem
                })
            })
        }
    }

    const updatePolyvalentById = (id, data) => {
        const polyvalents = findStep(config.processingSteps.STEP_02)
        if (polyvalents) {
            const updated = polyvalents.items?.map(polyvalent => {
                if (polyvalent.id === id) {
                    return {...polyvalent, ...data}
                } else return polyvalent
            })

            setProcessingItems(processingItems => {
                return processingItems.map(processingItem => {
                    if (processingItem.step === config.processingSteps.STEP_02) {
                        return {...processingItem, items: updated}
                    } else return processingItem
                })
            })
        }
    }

    useEffect(() => {
        if ([config.loginRoles.PROCESSOR, config.loginRoles.STORAGE_MANAGER].includes(user.roles[0]))
            refreshProcessingItems()
    }, [])

    return (
        <ProcessingItemsContext.Provider
            value={{
                processingItems,
                setProcessingItems,
                refreshProcessingItems,
                getLoadsForStepById,
                updatePolyvalentById,
                getPolyvalents,
                getStepItems,
                getProcessingItemByStepAndId,
                updateStepById
            }}>
            {children}
        </ProcessingItemsContext.Provider>
    )
};

import React from 'react';
import {getStatusIcon} from "../../assets/icons/cards/actions";

const StatusIcon = (props) => {
    const {status, dispute} = props
    const icon = getStatusIcon({status, dispute})
    
    return (
        <div style={{
            display: 'flex',
            width: 38,
            height: 38,
            backgroundColor: icon.color,
            borderRadius: 55,
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        }}
             {...props}>
            <img src={icon.src} style={{width: 22, height: 22}} alt={'status-icon'}/>
        </div>
    );
};

export default StatusIcon;

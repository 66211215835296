import * as React from 'react';
import {useState} from 'react';
import {ButtonProps} from "./types";
import {StyledButton} from "./styles";

const Button = (props: ButtonProps) => {
    const [clicked, setClicked] = useState(false)

    return (
        <StyledButton
            clicked={clicked}
            onMouseDown={() => setClicked(!clicked)}
            onMouseUp={() => setClicked(!clicked)}
            {...props}>
            {props.text}
        </StyledButton>
    );
};

export default Button;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import LoadsTab from "../LoadsTab";
import StepperModal from "../StepperModal";
import {hotChamberLoadsColumns} from "../../../../data/tables/hotChamberLoadsColumns";
import AddTab from "../AddTab";
import {hotChamberAddColumns} from "../../../../data/tables/hotChamberAddColumns";
import {FormProvider} from "../../../../hooks/useForm";
import {config} from "../../../../config";
import useProcessingItem from "../../../../hooks/useProcessingItem";

const HotChamberModel = (data) => {
    const sourceItems = (data.sourceItems[0].blends.length <= 0) ? [] : data.sourceItems
    return {...data.item, sourceItems: sourceItems}
}

const HotChamber = ({id}) => {
    const {t} = useTranslation()
    const {processItem, loading, refreshProcessingItems} = useProcessingItem(id, HotChamberModel)
    const [tabData, setTabData] = useState(null)

    useEffect(() => {
        if (processItem && !loading) {
            const tab1 = <LoadsTab loads={processItem.blends}
                                   extraData={{columns: hotChamberLoadsColumns}}/>
            const tab2 = <AddTab loads={processItem.sourceItems} itemId={id} columns={hotChamberAddColumns}
                                 refreshProcessingItems={refreshProcessingItems}
                                 step={config.processingSteps.STEP_04}/>

            setTabData({
                tabs: [t('processing.tank.tab.load'),
                    t('processing.tank.tab.add')],
                contentItems: [tab1, tab2]
            })
        }
    }, [processItem, loading])


    return (
        <FormProvider>
            {tabData &&
                <StepperModal tabData={tabData} initialTabIndex={1}/>
            }
        </FormProvider>
    );
};

export default HotChamber;

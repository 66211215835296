import React from 'react';
import {config} from "../../config";

const ProcessPackagingModel = (row, itemId) => {
    return {
        step: config.processingSteps.STEP_07,
        source: {
            chainCode: row.chainCode,
        },
        destination: {
            itemId: itemId,
            modifiers: row?.modifiers || [],
            waste: row?.waste || 0,
        },
    }
};

export default ProcessPackagingModel;

import React from 'react';
import {useSelector} from "react-redux";
import {userSelector} from "../store/user/userSlice";

const useAuth = () => {
    const user = useSelector(userSelector)
    return {
        isAuth: !!user?.auth,
        role: user.roles?.length > 0 ? user.roles[0] : null
    }
};

export default useAuth;

import WasteSection from "./WasteSection";
import {WasteSlice} from "../../../../interfaces/WasteSlice";
import React, {useState} from "react";
import Button from "../../../ui/Button/Button";
import HStack from "../../../ui/HStack";
import {useTranslation} from "react-i18next";
import SaltSection from "./SaltSection";
import {getWasteCount} from "../../../../utils";
import ProcessRipeningChamberModel from "../../../../models/addTabs/ProcessRipeningChamberModel";
import {useSocketEmit} from "../../../../hooks/useSocketEmit";
import {useModal} from "../../../../contexts/ModalContext";
import {useProcessingItems} from "../../../../contexts/ProcessingItemsContext";
import Text from "../../../ui/Text";
import {theme} from "../../../../theme";
import VStack from "../../../ui/VStack";
import AddNoteSection from "./AddNoteSection";
import TableContainer from "../../tab/TableContainer";

declare type AddSaltModalProps = {
    itemId: string
    chainCode: string
    row: any
    modifiersData: any
}

const AddSaltModal = ({itemId, chainCode, row, modifiersData}: AddSaltModalProps) => {
    const [waste, setWaste] = useState<WasteSlice[]>([])
    const [note, setNote] = useState<string>("")
    const [modifiers, setModifiers] = useState([])
    const {t} = useTranslation()
    const {processStep} = useSocketEmit()
    const {closeModal} = useModal()
    const {refreshProcessingItems} = useProcessingItems()

    const handleSubmit = () => {
        if (modifiers.length <= 0) {
            alert(t(`processing.ripeningChamber.modifiers.salt.error`))
            return
        }
        if (window.confirm(t(`processing.ripeningChamber.modifiers.salt.confirmation`, {waste: getWasteCount(waste)}) as string)) {
            const options = ProcessRipeningChamberModel({
                chainCode, itemId, modifiers: modifiers, waste: waste, note: note
            })
            console.log('options', itemId, options)
            processStep(options).catch(err => {
                // alert(t(`processing.ripeningChamber.error.${err.result.error.code}`))
            }).finally(() => {
                refreshProcessingItems()
                closeModal()
            })
        }
    }

    return (
        <TableContainer>
            <VStack space={theme.spacing["spacing-04"]} style={{paddingTop: theme.spacing["spacing-04"]}}>
                <Text>{t(`processing.ripeningChamber.modifiers.salt.modal`)}</Text>
                <WasteSection chainCode={chainCode} setWaste={setWaste} data={row.rackInfo}/>
                <SaltSection chainCode={chainCode} setModifiers={setModifiers} data={modifiersData}/>
                <AddNoteSection setNote={setNote} text={t(`processing.ripeningChamber.notes.addNote`)}/>
                <HStack style={{justifyContent: 'center', alignItems: 'center'}}>
                    <Button
                        text={`${t('commons.register')} ${t(`commons.modifiers.salt`)}`}
                        onClick={handleSubmit}/>
                </HStack>
            </VStack>
        </TableContainer>
    )
}

export default AddSaltModal

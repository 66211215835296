import React from 'react';
import {theme} from "../../../theme";
import Text from "../../ui/Text";
import {config} from "../../../config";
import {useTranslation} from "react-i18next";

const RackEmptyFilter = ({onFilter}) => {
    const options = [config.filters.rack.EMPTY, config.filters.rack.FULL]
    const {t} = useTranslation()
    return (
        <div style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            columnGap: theme.spacing["spacing-04"]
        }}>
            <Text variant={'default_text'}>{t("processing.rack.emptyFilter")}</Text>
            <select style={{
                borderRadius: theme.spacing["spacing-02"],
                padding: theme.spacing["spacing-02"], ...theme.components.Text.variants.default_text,
            }}
                    onChange={onFilter}
            >
                <option defaultValue={''} value={''}/>
                {options.map((option, i) => {
                    return (
                        <option key={i} defaultValue={'Seleziona...'}
                                value={option}>{t(`processing.rack.${option}`)}</option>
                    )
                })}
            </select>
        </div>
    )
};

export default RackEmptyFilter;

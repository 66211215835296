import React, {useState} from 'react';
import TableInput from "../../../ui/TableInput";
import {useTranslation} from "react-i18next";
import {updateRipeningChamberWaste} from "../../../../utils";

const WasteInput = ({setWaste, rackId, maxAmount}) => {
    const [value, setValue] = useState('')
    const {t} = useTranslation()

    const handleChange = e => {
        const newWaste = e.target.value > maxAmount ? maxAmount : parseInt(e.target.value)
        setValue(newWaste.toString())
        setWaste(waste => {
            return updateRipeningChamberWaste(waste, newWaste, rackId)
        })
    }

    return (
        <TableInput placeholder={t('commons.wastePlaceholder')} value={value} type={'number'}
                    onChange={handleChange}/>
    );
};

export default WasteInput;

import {createContext, useContext, useState} from "react";

export const ModalContext = createContext();

export const useModal = () => useContext(ModalContext)

export const ModalProvider = ({children}) => {
    const [modal, setModal] = useState({
        open: false,
        data: null,
        history: []
    });

    const updateLoadAmount = (newAmount) => {
        setModal(modal => {
            const amount = modal.data.title.replace(/• [0-9]*\//, `• ${newAmount}/`)
            return {...modal, data: {...modal.data, title: amount}}
        })
    }

    const openModal = (data) => {
        setModal(modal => {
                return ({
                    open: true,
                    data: data,
                    history: modal.history.length > 0 ? [...modal.history, modal.data] : modal.data ? [modal.data] : [],
                })
            }
        )
    }

    const closeModal = () => {
        modal.data?.closeCallback && modal.data?.closeCallback()
        const hasHistory = modal?.history?.length > 0
        if (hasHistory) {
            setModal(modal => {
                const historyLength = hasHistory && modal.history?.length
                const newData = modal.history[historyLength - 1]
                let newHistory = null
                if (hasHistory) {
                    newHistory = modal.history.slice(0, historyLength - 1)
                }

                return ({
                    open: true,
                    data: newData,
                    history: newHistory
                })
            })
        } else {
            setModal(() => ({open: false, data: null, history: []}))
        }
    }

    const resetModal = () => {
        setModal(() => ({open: false, data: null, history: []}))
    }

    return (
        <ModalContext.Provider value={{modal, setModal, closeModal, openModal, resetModal, updateLoadAmount}}>
            {children}
        </ModalContext.Provider>
    )
};

import React from 'react';
import {theme} from "../../../theme";
import {useModifiers} from "../../../contexts/ModifiersFormContext";

const ModifiersInput = ({data}) => {
    const {setAmount} = useModifiers()

    return <input placeholder={data.available !== '' ? `${data.available}lt rimanenti` : 'Litri...'} type={'number'}
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  onChange={e => setAmount(data.index, e.target.value)}
                  style={{
                      height: 10,
                      outline: 'none',
                      border: `1px solid ${theme.colors.lightGrey}`,
                      borderRadius: 10,
                      paddingLeft: theme.spacing["spacing-04"],
                      paddingRight: theme.spacing["spacing-04"],
                      paddingTop: theme.spacing["spacing-05"],
                      paddingBottom: theme.spacing["spacing-05"],
                      ...theme.components.Text.variants.h5,
                  }}/>
};

export default ModifiersInput;

import React from 'react';
import {config} from "../../config";

const ProcessPolyvalentModel = (row, itemId) => {
    return {
        step: config.processingSteps.STEP_02,
        source: {
            itemId: row.id,
        },
        destination: {
            itemId: itemId,
            loadAmount: row?.loadAmount || 0,
            waste: row?.waste || 0,
        }
    }
};

export default ProcessPolyvalentModel;

import React from 'react';
import useWindowSize from "../../../hooks/useWindowSize";

const TableContainer = ({children}) => {
    const windowSize = useWindowSize()

    return (
        <div style={{
            width: '100%',
            overflowY: 'scroll',
            height: windowSize.height * .8 - 130,
        }}>
            {children}
        </div>
    );
};

export default TableContainer;

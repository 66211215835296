import React, {useState} from 'react';
import {useGetUsers} from "../../../hooks/useGetUsers";
import {DisputeModel} from "../../../models/DisputeModel";
import MessageList from "../dispute/MessageList";
import DisputeDetailProposal from "../../layout/dispute/DisputeDetailProposal";
import {useHistory} from "../../../contexts/HistoryProvider";

const DisputeDetails = ({referenceId}) => {


    const {users} = useGetUsers()
    const {history} = useHistory()

    const [rowData] = useState(history.find(item => item.id === referenceId))
    const dispute = DisputeModel(rowData, users)

    if (!dispute) return

    return (
        <>
            <DisputeDetailProposal dispute={dispute} rowData={rowData}/>
            <MessageList dispute={dispute}/>
        </>
    )
};

export default DisputeDetails;

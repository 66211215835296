export const UserModel = (data) => {
    const dataModel = {
        auth: {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
        },
        email: data.user.email,
        id: data.user.id,
        name: data.user.name,
        roles: [data.user.role],
        appId: data.user.appId,
        avatar: data.user.avatar,
        appleId: data.user.appleId,
        googleId: data.user.googleId,
        isExternalAuth: data.user.isExternalAuth,
        backendUrl: data.user.userData.backendUrl
    }

    return dataModel
}

import {useRef} from "react";
import {QRCodeCanvas} from "qrcode.react";
import {useSelector} from 'react-redux';
import {userSelector} from '../../../store/user/userSlice';
import Button from "../../ui/Button/Button";
import {theme} from "../../../theme";
import Text from "../../ui/Text";
import {useTranslation} from "react-i18next";
import VStack from "../../ui/VStack";
import jsPDF from "jspdf";
import useWindowSize from "../../../hooks/useWindowSize";

const QRCode = ({chainCode}) => {
    const {t} = useTranslation()
    const qrRef = useRef();
    const windowSize = useWindowSize()
    const user = useSelector(userSelector)


    const downloadQRCode = (e) => {
        e.preventDefault();
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        const pdf = new jsPDF('portrait');
        pdf.text('chainCode', 10, 10);
        pdf.addImage(image, 'png', 20, 20, 100, 100);
        pdf.save(`${chainCode}.pdf`);
    };

    const qrcode = () => {
            const backendUrl = user.backendUrl.substring(0, user.backendUrl.lastIndexOf('/'));
            console.log('backendUrl', process.env.REACT_APP_BACKEND_URL)

            // let link = ('' + config.qrCodeLinkTemplate).replace('%%backendUrl%%', backendUrl).replace('%%chainCode%%', chainCode);
            // const url = new URL(link);
            // link = url.origin + '?' + btoa(url.search.substring(1))
            const link = process.env.REACT_APP_BACKEND_URL + btoa(`apiUrl=${backendUrl}/chaincode/${chainCode}`)
            console.log('pro', process.env.REACT_APP_BACKEND_URL)
            console.log('apiurl', btoa(`apiUrl=${backendUrl}/chaincode/${chainCode}`))
            console.log('url', link)
            return <QRCodeCanvas
                id="qrCode"
                value={link}
                size={300}
                level={"H"}
            />
        }

    ;

    return (
        <VStack
            style={{
                paddingTop: theme.spacing["spacing-10"],
                alignItems: 'center',
                overflowY: 'scroll',
                height: windowSize.height * .8 - 180,
            }}
            space={theme.spacing["spacing-10"]}>
            <Text variant={'h4'}>{t('processing.packaging.packagingFull', {chainCode: chainCode})}</Text>
            <div ref={qrRef}>{qrcode()}</div>
            <Button type="submit" onClick={downloadQRCode} text={t('processing.packaging.downloadQRCode')}/>
        </VStack>
    );
};

export default QRCode;

import styled from "styled-components";

export const ProfileIconContainer = styled.div`

`;

export const ProfileIconContent = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
`;


export const TruckModel = (data) => {

    return {
        id: data.id,
        plateId: data.plateId,
        status: data.status,
        description: data.description,
        createdAt: data.createdAt,
        bookedBy: data.bookedBy,
        bookedAt: data.bookedAt,
        tanks: data.tanks,
        carrier: data.carrier,
    }
}




import ProcessStepButton from "../../components/molecules/buttons/ProcessStepButton";
import {config} from "../../config";
import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import ProcessSeasoningRackModel from "../../models/addTabs/ProcessSeasoningRackModel";
import {Trans} from "react-i18next";

export const addLoadsToSeasoningRackColumns = ({
                                                   resetForm,
                                                   handleSubmit,
                                                   onValueChange,
                                                   itemId,
                                                   capacity,
                                                   containerId
                                               }) => [
    {
        name: <Trans i18nKey={'commons.id'}/>,
        cell: row => {
            return row.chainCodes[0]
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Rack Fisico',
        selector: () => containerId,
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={'commons.forms'}/>,
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={'commons.transferred'}/>,
        cell: row => {
            return <ProcessStepInput placeholderKey={'commons.forms'} data={row} updatevalue={onValueChange}
                                     type={config.input.LOAD_AMOUNT}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={'commons.loss'}/>,
        cell: row => {
            return <ProcessStepInput placeholderKey={'commons.loss'} data={row} updatevalue={onValueChange}
                                     type={config.input.WASTE}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        cell: row => {
            const options = ProcessSeasoningRackModel(row, itemId)
            const loadAmount = options.destination.loadAmount
            const total = loadAmount + options.destination.waste
            const isValid = row.amount >= total && capacity >= loadAmount && loadAmount > 0

            const onSubmit = () => {
                resetForm(row.id)
                handleSubmit()
            }
            return <ProcessStepButton disabled={!isValid} options={options} isValid={isValid}
                                      onSubmit={onSubmit}/>
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: true,
        reorder: true,
    },
];

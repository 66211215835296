import React, {useEffect, useState} from 'react';
import HStack from "../../../ui/HStack";
import ModalInput from "../../../ui/ModalInput";
import DataTable from "../../DataTable";
import {polyvalentLoadsExtraModifiersColumns} from "../../../../data/tables/polyvalentLoadsExtraModifiersColumns";
import ModifiersLoadModel from "../../../../models/ModifiersLoadModel";

const PolyvalentLoadsModifier = ({blends, loads, dbModifiers}) => {
    const [modifiers, setModifiers] = useState(null)

    useEffect(() => {
        if (blends?.length > 0) {
            const found = blends.find(blend => blend.params.modifiers)
            if (!found) return

            const modifiers = found.params.modifiers
            if (modifiers.length > 0)
                setModifiers(ModifiersLoadModel(modifiers, dbModifiers))
        }
    }, [loads])

    return (
        <>
            {modifiers &&
                <>
                    <HStack>
                        <ModalInput placeholder={'°'} title={'Temperatura Polivalente'} type={'number'}
                                    id={'temperature'} name={'temperature'}
                                    value={`${modifiers.temperature}`} readOnly
                        />
                        <ModalInput id={'time'} name={'time'} placeholder={'minuti...'}
                                    title={'Tempo (min)'} type={'number'} value={`${modifiers.time / 60}`}
                                    readOnly
                        />
                    </HStack>
                    {modifiers.extraModifiers &&
                        <DataTable
                            highlightOnHover={false}
                            columns={polyvalentLoadsExtraModifiersColumns}
                            data={modifiers.extraModifiers}
                        />}
                </>
            }
        </>
    )
};

export default PolyvalentLoadsModifier;

import {useNavigate} from "react-router-dom"
import Text from "../components/ui/Text";
import Button from "../components/ui/Button/Button";
import VStack from "../components/ui/VStack";
import {useDispatch} from "react-redux";
import {logout} from "../store/user/userSlice";
import {config} from "../config";

const Unauthorized = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const goBack = () => navigate(-1);

    const handleLogout = () => {
        dispatch(logout())
        navigate(config.routes.LOGIN)
    }
    return (

        <VStack space={20} style={{alignItems: 'center'}}>
            <Text variant={'h0'}>Unauthorized</Text>
            <Text variant={'h5'}>You do not have access to the requested page.</Text>
            <VStack>
                <Button onClick={goBack} text={'Go Back'}/>
                <Text variant={'default_text'}>or</Text>
                <Button onClick={handleLogout} text={'Logout'}/>
            </VStack>
        </VStack>

    )
}

export default Unauthorized

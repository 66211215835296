import moment from "moment";
import ProductColumnField from "../../components/molecules/columnFields/ProductColumnField";
import TankColumnField from "../../components/molecules/columnFields/TankColumnField";

export const polyvalentLoadsColumns = [
    {
        name: 'Cisterna di provenienza',
        cell: row => <TankColumnField tankId={row.sourceItemId}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Tipo Latte',
        cell: row => <ProductColumnField productId={row.params.productId}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Orario di trasferimento',
        cell: row => moment(row.createdAt).format('DD/MM/YY HH:mm'),
        sortable: true,
        reorder: true,
    },
    {
        name: 'Quantità trasferita',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Latte perso',
        selector: row => row.waste,
        sortable: true,
        reorder: true,
    },
];

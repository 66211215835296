import VStack from "../../ui/VStack";
import {theme} from "../../../theme";
import HStack from "../../ui/HStack";
import Text from "../../ui/Text";
import Box from "../../ui/Box/Box";
import moment from "moment";

const Message = ({message}) => {
    return (
        <VStack style={{paddingTop: theme.spacing["spacing-06"], paddingBottom: theme.spacing["spacing-09"]}}>
            <HStack style={{paddingBottom: theme.spacing["spacing-04"]}}>
                <Text variant={'h6'}
                      color={message.isProcessor ? theme.colors.success : theme.colors.primary}>{message.isProcessor ? 'TU' : 'PASTORE'}</Text>
                <Box style={{justifyContent: 'flex-end'}}>
                    <Text variant={'h6'}
                          color={theme.colors.midGrey}>{moment(message.createdAt).format('DD MMMM YYYY, h:mm')}</Text>
                </Box>
            </HStack>
            <Text variant={'default_text'}>{message.message}</Text>
        </VStack>
    )
}

export default Message

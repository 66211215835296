import React from 'react';
import DataTable from "../DataTable";
import TableContainer from "../tab/TableContainer";

const LoadsTab = ({extraData, loads, expandableRows, expandableRowsComponent}) => {

    return (
        <TableContainer>
            <DataTable
                highlightOnHover={false}
                columns={extraData.columns}
                data={loads}
                expandableRows={expandableRows}
                expandableRowsComponent={expandableRowsComponent}
            />
        </TableContainer>
    )
}

export default LoadsTab;

import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    language: 'en',
}

export const appPreferencesSlice = createSlice({
    name: 'appPreferences',
    initialState,
    reducers: {
        clearState: () => {
            return initialState
        },
        setAppLanguage: (state, action) => {
            state.language = action.payload;
        },
    },
})

export const {clearState, setAppLanguage} = appPreferencesSlice.actions

export const appPreferencesSelector = (state) => state.appPreferences

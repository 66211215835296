import {config} from "../../config";

const ProcessRipeningChamberModel = ({chainCode, itemId, modifiers, waste, note}) => {
    let model = {
        step: config.processingSteps.STEP_06,
        source: {
            chainCode: chainCode,
        },
        destination: {
            itemId: itemId,
            modifiers: modifiers || [],
            waste: waste || [],
            note: note
        },
    }
    if (model.destination.waste.length <= 0)
        delete model.destination.waste
    if (model.destination.note === "")
        delete model.destination.note
    return model
};

export default ProcessRipeningChamberModel;

import styled from "styled-components";
import {BoxProps} from "./types";

export const StyledBox = styled.div<BoxProps>`
  display: flex;
  flex: 1;
  ${props => ({
    ...props.style
  })};
`;

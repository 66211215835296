import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import { theme } from '../../../theme';
import Button from '../../ui/Button';
import Text from '../../ui/Text';

import Details from './Details';

const List = ({users, onEdit, onDisable}) => {
    const {t} = useTranslation();

    return (
      <div style={styles.root}>
          <div style={styles.header}>
              <Text variant={'h4'} style={{textAlign:'left', flex:4}}>{t('routes.users.list.nameLabel')}</Text>
              <Text variant={'h4'} style={{textAlign:'left', flex:2}} >{t('routes.users.list.roleLabel')}</Text>
              <Text variant={'h4'} style={{textAlign:'left', flex:3}} >{t('routes.users.list.actionsLabel')}</Text>
          </div>
          {users && users.map(u=><Details key={u.id} user={u} onEdit={onEdit} onDisable={onDisable}/>)}
      </div>
    );
}

const styles = {
    root: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width:'100%',
        paddingTop: theme.spacing['spacing-07'],
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width:'100%',
        borderBottom:'1px solid ' +theme.colors.lightGrey,
        padding:theme.spacing['spacing-04'],
        marginBottom: theme.spacing['spacing-02'],
        textAlign:'left',
    }
}

export default List;

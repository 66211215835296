import React from 'react';
import Box from "./Box/Box";
import {theme} from "../../theme";

const Hr = () => {
    return (
        <Box style={{backgroundColor: theme.colors.lightGrey, height: 1}}/>
    )
};

export default Hr;

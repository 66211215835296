import React, {useEffect} from 'react';
import HStack from "../../ui/HStack";
import {theme} from "../../../theme";
import ModalInput from "../../ui/ModalInput";
import Box from "../../ui/Box/Box";
import Button from "../../ui/Button/Button";
import {useSocketEmit} from "../../../hooks/useSocketEmit";
import {useModal} from "../../../contexts/ModalContext";
import {useHistory} from "../../../contexts/HistoryProvider";
import {useFormik} from 'formik';
import {CreateDisputeSchema} from "../../../validators/CreateDisputeSchema";
import CloseDefinitively from "./CloseDefinitively";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";

const CreateDispute = ({id, quantity}) => {
    const {createDispute} = useSocketEmit()
    const {closeModal, openModal} = useModal()
    const {updateHistoryElement} = useHistory()
    const {t} = useTranslation()

    const handleCreateDispute = (body) => {
        if (window.confirm(t('dispute.confirmations.createNewRequest'))) {
            createDispute(body).then(res => {
                updateHistoryElement(res)
                closeModal()
            }).catch(console.error)
        }
    }

    const handleCloseDefinitively = () => {
        openModal({
            title: t('dispute.createDispute.closeDefinitively'),
            child: <CloseDefinitively blendId={id}/>
        })
    }

    const {values, handleChange, handleSubmit, errors, isValid, isSubmitting} = useFormik({
        initialValues: {
            blendId: id,
            amount: '',
            message: ''
        },
        validationSchema: CreateDisputeSchema,
        validateOnChange: CreateDisputeSchema,
        onSubmit: (values) => {
            console.log('vvvv', values)
            if (values.amount === '') delete values.amount
            handleCreateDispute(values)
        },
    });


    useEffect(() => {
        console.log('errors', errors)
    }, [errors])

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <HStack style={{paddingTop: theme.spacing["spacing-06"]}}>
                    <ModalInput placeholder={t('dispute.createDispute.lt')}
                                title={t('dispute.createDispute.quantityInput')} readOnly={true}
                                value={quantity}/>
                    <ModalInput id={'amount'} name={'amount'} placeholder={t('dispute.createDispute.lt')}
                                title={'Nuova quantità'} type={'number'}
                                onChange={handleChange}
                                value={values.amount}
                                error={errors.amount}
                    />
                </HStack>
                <Box style={{paddingTop: theme.spacing['spacing-05']}}>
                    <textarea id={'message'} name={'message'} onChange={handleChange}
                              value={values.message}
                              placeholder={'Spiega il motivo della segnalazione...'}
                              style={{
                                  ...theme.components.Text.variants.default_text,
                                  padding: theme.spacing["spacing-05"],
                                  width: '100%',
                                  outline: 'none',
                                  borderRadius: 10,
                                  height: 284,
                                  resize: 'none',
                                  border: errors.message ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.lightGrey}`
                              }}/>
                </Box>
                <div style={{
                    paddingTop: theme.spacing["spacing-09"],
                    justifyContent: 'center',
                    flexDirection: 'row',
                    display: 'flex'
                }}>
                    <div style={{paddingRight: theme.spacing["spacing-04"]}}>
                        <Button type={'button'} text={'Chiudi definitivamente'}
                                background={theme.colors.error}
                                onClick={handleCloseDefinitively}
                        />
                    </div>
                    <Button type={'button'} text={'Crea segnalazione'}
                            disabled={!isEmpty(errors)}
                            onClick={handleSubmit}
                    />
                </div>
            </form>
        </div>
    );
};

export default CreateDispute;

import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    ripeningChamberModifiersSelector,
    updateStateLoads
} from "../store/hotChamberModifiers.js/ripeningChamberModifiersSlice";

const useRipeningChamberInit = () => {
    const formData = useSelector(ripeningChamberModifiersSelector)
    const dispatch = useDispatch()

    const initRipeningChamberLoads = (loads) => {
        if (formData.length <= 0 && loads?.length > 0) {
            dispatch(updateStateLoads(loads))
        }
    }

    return {initRipeningChamberLoads}
};

export default useRipeningChamberInit;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { useModal } from '../../../contexts/ModalContext';
import { useModifiers } from '../../../contexts/ModifiersProvider';
import { FormProvider } from '../../../hooks/useForm';
import { theme } from '../../../theme';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input';
import Text from '../../ui/Text';
import { nameValidator, lotIdValidator, amountValidator } from '../../../validators/Modifier';

const ModifierForm = ({ item }) => {
  const { t } = useTranslation();
  const [isNew, setIsNew] = useState(!item);
  const [unit, setUnit] = useState(null);
  const [loading, setLoading] = useState(false);
  const {closeModal} = useModal()
  const {add, modifiers} = useModifiers()

  const {setValue, watch, register, handleSubmit,  formState: {errors}} = useForm({
    mode:'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      name: item?.name || '',
      type: item?.type || config.modifierType.CONSUMABLE,
      status: item?.status || config.productStatus.AVAILABLE,
      unit: item?.unit || '-',
      step: item?.step || config.processingSteps.STEP_02,
      lotId: item?.lotId || null,
      amount: item?.amount || '',
      category: item?.category || config.modifierCategories.ENZYMES,
    }
  });

  const watchCategory = watch("category");

  const onSubmit = data => {
    data.type = isConsumable ?
      config.modifierType.CONSUMABLE :
      config.modifierType.PERMANENT;

    data.step = getStep;
    data.unit = unit || config.modifierUnits['-'];
    if(!hasLotId){
      delete data.lotId;
    }

    add(data).catch(console.error).finally(() => {
       closeModal()
    })
  };



  useEffect(() => {
    setIsNew(!item);
    return () => {
      setIsNew(false);
    };
  }, []);

  const getStep = useMemo(()=>{
    return [config.modifierCategories.ENZYMES, config.modifierCategories.RENNET, config.modifierCategories.TEMPERATURE, config.modifierCategories.TIME].includes(watchCategory) ?
      [config.processingSteps.STEP_02] :
      config.modifierCategories.PACKAGING === watchCategory ? [config.processingSteps.STEP_07] : [config.processingSteps.STEP_06];
  }, [watchCategory]);

  const isConsumable = useMemo(()=>{
    return [config.modifierCategories.ENZYMES, config.modifierCategories.SALT, config.modifierCategories.RENNET].includes(watchCategory)
  }, [watchCategory]);

  const hasLotId = useMemo(()=>{
    return isConsumable || watchCategory === config.modifierCategories.PACKAGING
  }, [watchCategory]);

  const hasAmount = useMemo(()=>{
    return ![config.modifierCategories.WASHING, config.modifierCategories.PACKAGING].includes(watchCategory)
  }, [watchCategory]);

  const showElement = useCallback((check)=>{
    return check ? 'block' : 'none';
  }, [watchCategory])

  useEffect(()=>{
    switch (watchCategory) {
      case config.modifierCategories.ENZYMES:
      case config.modifierCategories.RENNET:{
        setUnit(config.modifierUnits.lt);
        break;
      }
      case config.modifierCategories.SALT: {
        setUnit(config.modifierUnits.gr);
        break;
      }
      case config.modifierCategories.TEMPERATURE: {
        setUnit(config.modifierUnits.C);
        break;
      }
      case config.modifierCategories.TIME: {
        setUnit(config.modifierUnits.sec);
        break;
      }
      default:
        setUnit(null);
        setValue('amount', 1);
    }
  }, [watchCategory]);

  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('modifiers.common.name')}</Text>
            <Input style={ errors?.name ? styles.erroredInput: {}} placeholder={t('modifiers.common.name')} type={'text'} {...register('name', nameValidator)} />
            {errors?.name && <Text style={styles.error} variant={'p1'}>{t(errors.name.message)}</Text>}
          </div>
          <Input type={'hidden'} {...register('type')}/>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('modifiers.common.category')}</Text>
            <select style={styles.select} {...register('category')}>
              {Object.values(config.modifierCategories).map((option, i) => {
                const label = 'modifiers.common.categories.' + option;
                return (
                  <option key={i} defaultValue={t(label)} value={option}>{t(label)}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div style={styles.formContainer}>
          <div style={{...styles.inputContainer, display: showElement(hasAmount)}}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('modifiers.common.amount', {unit: t('modifiers.common.units.'+unit)})}</Text>
            <Input style={ errors?.amount ? styles.erroredInput: {}} placeholder={t('modifiers.common.amountPlaceholder')} type={'number'} {...register('amount', amountValidator)} />
            {errors?.amount && <Text style={styles.error} variant={'p1'}>{t(errors.amount.message)}</Text>}
          </div>
          {hasLotId &&
          <div style={{ ...styles.inputContainer, display: showElement(hasLotId) }}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('modifiers.common.lotId')}</Text>
            <Input style={errors?.lotId ? styles.erroredInput : {}} placeholder={t('modifiers.common.lotId')}
                   type={'text'} {...register('lotId', lotIdValidator)} />
            {errors?.lotId && <Text style={styles.error} variant={'p1'}>{t(errors.lotId.message)}</Text>}
          </div>
          }
        </div>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('modifiers.common.availability')}</Text>
            <select style={styles.select} {...register('status')}>
              {[config.modifierStatus.AVAILABLE, config.modifierStatus.UNAVAILABLE].map((option, i) => {
                const label = 'modifiers.common.' + option;
                return (
                  <option key={i} defaultValue={t(label)} value={option}>{t(label)}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div style={styles.buttonContainer}>
          {!isNew && <Input value={item.id} type={'hidden'} {...register('id')}/>}
          <Button text={t('products.common.confirm')} type={'submit'} disabled={loading}/>
        </div>
      </form>
    </FormProvider>
  );
}

const styles = {
  columnHeader: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['spacing-04'],
  },
  newItem: { cursor: 'pointer' },
  select: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.colors.lightGrey,
    borderRadius: 10,
    paddingLeft: theme.spacing["spacing-05"],
    paddingRight: theme.spacing["spacing-05"],
    paddingTop: theme.spacing["spacing-05"],
    paddingBottom: theme.spacing["spacing-05"],
    ...theme.components.Text.variants.h5,
  },
  label: {
    marginBottom: theme.spacing['spacing-04']
  },
  textArea: {
    ...theme.components.Text.variants.default_text,
    padding: theme.spacing["spacing-05"],
    outline: 'none',
    borderRadius: 10,
    resize: 'none',
    borderColor: theme.colors.lightGrey,
    width:'90%',
  },
  formContainer: {
    display: 'flex',
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    marginTop: theme.spacing['spacing-08'],

  },
  inputContainer: {
    margin: theme.spacing['spacing-04']
  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-08']
  },
  error: {
    color: theme.colors.error
  },
  erroredInput: {
    color: theme.colors.error,
    borderColor: theme.colors.error
  }
};

export default ModifierForm;

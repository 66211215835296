import moment from 'moment';
import React from 'react';
import Button from '../../components/ui/Button/Button';
import {useTranslation} from "react-i18next";
import { config } from '../../config';

const TranslateKey = ({translateKey}) => {
    const {t: pippo} = useTranslation()
    return (
        <>{pippo(translateKey)}</>
    )
}

export function truckColumns (edit, editStatus) {
    return [
    {
        name: <TranslateKey translateKey="trucks.common.plateId"/>,
        selector: row => row.plateId,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="trucks.common.bookedAt"/>,
        selector: row => row.bookedBy && row.bookedAt ? moment(row.bookedAt).format('DD MMMM YYYY, h:mm') : '-',
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="trucks.common.bookedBy"/>,
        selector: row => row.bookedBy ? row.carrier?.name : '-',
        sortable: true,
        reorder: true,
    },
    {
        selector: row => <Button  disabled={row.status === config.truckStatus.UNAVAILABLE} text={<TranslateKey translateKey="trucks.editButton"/>} onClick={()=>edit(row)}/>,
        style: {
            justifyContent:'right',
        }
    },
    {
        selector: row => <Button  text={<TranslateKey translateKey={`trucks.${row.status=== config.truckStatus.AVAILABLE ? 'disableButton' : 'enableButton'}`}/>} onClick={()=>editStatus(row)}/>,
        style: {
            justifyContent:'right',
        }
    },

]};

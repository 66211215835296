import {createSlice} from '@reduxjs/toolkit';

const initialState = []

export const seasoningWasteFormSlice = createSlice({
    name: 'seasoningWaste',
    initialState: initialState,
    reducers: {
        setSeasoningWaste: (state, action) => {
            return action.payload
        },
        resetState: () => {
            return initialState
        }
    },
});

export const {setSeasoningWaste, resetState} = seasoningWasteFormSlice.actions;

export const seasoningWasteSelector = state => state.seasoningWaste;

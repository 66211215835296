import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import Button from '../../ui/Button/Button';
import Text from '../../ui/Text';


const DisplayTanks = ({tanks, remove}) => {
    const { t } = useTranslation();

    return ( tanks.length === 0 ? <></> :
      <div style={styles.tanks}>
          <div style={styles.tankHeaderContainer}>
              <Text variant={'h5'} alignText={'left'}>{t('trucks.common.tankId')}</Text>
              <Text variant={'h5'} alignText={'left'}>{t('trucks.common.tankCapacity')}</Text>
              <Text variant={'h5'} alignText={'left'}></Text>
          </div>
          <div style={styles.tanksContainer}>
              {tanks.map(tank=>
                  <div style={styles.root} key={tank.id}>
                      <Text  style={{width:130}} variant={'h6'} alignText={'left'}>{tank.id}</Text>
                      <Text style={{width:150}} variant={'h6'} alignText={'left'}>{tank.capacity}</Text>
                      <Button height={25} text={t('trucks.common.removeTank')} onClick={()=>remove(tank.id)}/>
                  </div>
              ) }
          </div>
      </div>



    );
};

const styles = {
    root: {
        display:'flex',
        flexDirection: 'row',
        width:450,
        justifyContent:'space-between',
        marginBottom: theme.spacing['spacing-02'],
        alignItems:'center'
    },
    tanks:{
        display: 'flex',
        margin: theme.spacing['spacing-04'],
        flexDirection: 'column',
    },
    tankHeaderContainer: {
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 400,
        marginBottom: theme.spacing['spacing-03'],
    },
    tanksContainer: {
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

    },

}
export default DisplayTanks;

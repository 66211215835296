import React, {useEffect, useState} from 'react';
import {useSocketEmit} from "../../../hooks/useSocketEmit";
import {useProcessingItems} from "../../../contexts/ProcessingItemsContext";
import {useTranslation} from "react-i18next";
import {useModal} from "../../../contexts/ModalContext";
import Button from "../../ui/Button/Button";
import useSelectedSeasoner from "../../../hooks/useSelectedSeasoner";
import ProcessStartSeasoningModel from "../../../models/addTabs/ProcessStartSeasoningModel";
import {validateStartSeasoning} from "../../../validators/processStepValidators";
import {resetState, seasoningWasteSelector} from "../../../store/seasoning/seasoningWaste";
import {useDispatch, useSelector} from "react-redux";

const StartSeasoningButton = ({chainCode, itemId, formData}) => {
    const {processStep} = useSocketEmit()
    const {refreshProcessingItems} = useProcessingItems()
    const {t} = useTranslation()
    const {closeModal} = useModal()
    const {seasonerForm} = useSelectedSeasoner()
    const dispatch = useDispatch()

    const [disabled, setDisabled] = useState(true)
    const [options, setOptions] = useState(null)

    const waste = useSelector(seasoningWasteSelector)

    useEffect(() => {
        setOptions(ProcessStartSeasoningModel({chainCode, itemId, seasonerForm, formData, waste}))
    }, [seasonerForm, formData])

    useEffect(() => {
        if (options) {
            setDisabled(validateStartSeasoning(options))
        }
    }, [options])


    const handleClick = () => {
        processStep(options).catch(console.error).finally(() => {
            refreshProcessingItems()
            dispatch(resetState())
            closeModal()
        })
    }
    return <Button text={t('processing.seasoning.start')} onClick={handleClick} disabled={disabled}/>
};

export default StartSeasoningButton;


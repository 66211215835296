import styled from "styled-components";
import {theme} from "../../../theme";

const CARD_WIDTH = '343px'

export const CardContainer = styled.div`
  width: ${CARD_WIDTH};
  border-radius: 10px;
  background-color: ${theme.colors.lighterGrey};
  padding: ${theme.spacing["spacing-05"]}px;
`;

export const CardContent = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-top: ${theme.spacing["spacing-06"]}px;
`;

export const CardAvailability = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-end;
  column-gap: ${theme.spacing["spacing-02"]}px;
`;

import React, {useEffect, useState} from 'react';
import {useSocketEmit} from "./useSocketEmit";

const useProcessingItems = (step, model) => {
    const {getProcessItemByStep} = useSocketEmit()
    const [processItem, setProcessItem] = useState()
    const [loading, setLoading] = useState(true)

    const refreshProcessingItems = () => {
        getProcessItemByStep(step).then(res => {
            const updatedData = res.map(item => {
                if (model) {
                    return model(item)
                } else return item
            })
            setProcessItem(updatedData)
        }).catch(console.error).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        refreshProcessingItems()
    }, [])

    return {
        processItem,
        loading,
        refreshProcessingItems
    }
};

export default useProcessingItems;

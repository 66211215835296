import React, {useState} from 'react';

const IconButton = (props) => {
    const {src} = props
    const [clicked, setClicked] = useState(false)

    return (
        <div style={{
            display: 'flex',
            width: 38,
            height: 38,
            borderRadius: 55,
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            opacity: clicked ? 0.5 : 1,
            cursor: 'pointer'
        }}
             onMouseDown={() => setClicked(!clicked)}
             onMouseUp={() => setClicked(!clicked)}
             {...props}>
            <img src={src} style={{width: 18, height: 18}} alt={'icon-button'}/>
        </div>
    );
};

export default IconButton;

import React from 'react';
import {FormProvider} from "../../../../hooks/useForm";
import useProcessingItem from "../../../../hooks/useProcessingItem";
import PressModel from "../../../../models/processingItems/PressModel";
import AddTab from "../AddTab";
import {pressColumns} from "../../../../data/tables/pressColumns";
import {config} from "../../../../config";
import {useModal} from "../../../../contexts/ModalContext";
import ProcessingItemIsFull from "../polyvalent/ProcessingItemIsFull";
import {useTranslation} from "react-i18next";

const Press = ({id}) => {
    const {processItem, loading} = useProcessingItem(id, PressModel)
    const {closeModal} = useModal()
    const {t} = useTranslation()

    const handleSubmit = () => {
        closeModal()
    }

    return (
        <FormProvider>
            {processItem?.isLoaded && <ProcessingItemIsFull text={t('processing.press.pressFull')}/>}
            {!loading && !processItem?.isLoaded && <AddTab columns={pressColumns}
                                                           refreshProcessingItems={handleSubmit}
                                                           itemId={processItem?.id}
                                                           loads={processItem?.sourceItems}
                                                           blends={processItem.blends}
                                                           step={config.processingSteps.STEP_03}/>}
        </FormProvider>
    )
};

export default Press;

export const emailValidator = {
    required: true,
    max: 80,
    min: 8,
    maxLength: 80
}

export const passwordValidator = {
    required: true,
    max: 80,
    min: 8,
    maxLength: 80
}

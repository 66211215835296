import React from 'react';
import {theme} from "../../../theme";
import Box from "../../ui/Box/Box";
import {useMediaQuery} from "react-responsive";

const HistoryContainer = ({children}) => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

    const paddingLeftResponsive = isTabletOrMobile ? theme.spacing["spacing-04"] : theme.spacing["spacing-10"]
    const paddingRightResponsive = isTabletOrMobile ? theme.spacing["spacing-04"] : theme.spacing["spacing-10"]

    return (
        <Box style={{
            flexDirection: 'column',
            paddingTop: theme.spacing["spacing-07"],
            paddingLeft: paddingLeftResponsive,
            paddingRight: paddingRightResponsive,
        }}>{children}
        </Box>
    );
};

export default HistoryContainer;

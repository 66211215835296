import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import { ProductsProvider } from '../contexts/ProductsProvider';
import { TrucksProvider } from '../contexts/TrucksProvider';
import {userSelector} from "../store/user/userSlice";
import SocketProvider, {SocketContext} from "../contexts/SocketProvider";
import {useContext} from "react";
import {config} from "../config";
import Loading from "../routes/Loading";
import ProvidersWrapper from "../ProvidersWrapper";
import {TableFormProvider} from "../contexts/TableFormContext";
import {ProcessingItemsProvider} from "../contexts/ProcessingItemsContext";
import {HistoryProvider} from "../contexts/HistoryProvider";
import {ActiveTabProvider} from "../contexts/ActiveTabContext";
import TankLoadsProvider from "../contexts/TankLoadsProvider";
import {ModalProvider} from "../contexts/ModalContext";
import {ModifiersFormProvider} from "../contexts/ModifiersFormContext";
import {ModifiersProvider} from "../contexts/ModifiersProvider";

const RequireAuth = ({allowedRoles}) => {
    const location = useLocation();
    const user = useSelector(userSelector)
    const {socketReady} = useContext(SocketContext)

    const isAuthenticated = user?.auth
    const userHasRequiredRole = user?.roles?.find(role => allowedRoles?.includes(role))

    // if (!isAuthenticated)
    //     return <Navigate to={config.routes.LOGIN} state={{from: location}} replace/>

    // if (!userHasRequiredRole) {
    //     // return <Outlet/>
    //     return <Navigate to={config.routes.CREATE_USERS} state={{from: location}} replace/>
    // }
    ////

    return (
        userHasRequiredRole
            ? <SocketProvider>
                {
                    socketReady ? <Loading/>
                        : <ProvidersWrapper
                            providers={[<ModalProvider/>,
                                <TableFormProvider/>,
                                <ProcessingItemsProvider/>,
                                <ActiveTabProvider/>,
                                <HistoryProvider/>,
                                <ProductsProvider/>,
                                <ModifiersFormProvider/>,
                                <ModifiersProvider/>,
                                <TrucksProvider/>,
                                <TankLoadsProvider/>]}>
                            <Outlet/>
                        </ProvidersWrapper>
                }
            </SocketProvider>
            : isAuthenticated
                ? <Navigate to={config.routes.UNAUTHORIZED} state={{from: location}} replace/>
                : <Navigate to={config.routes.LOGIN} state={{from: location}} replace/>
    );

    //
    // return (
    //     <SocketProvider>
    //         {
    //             socketReady ? <Loading/>
    //                 : <ProvidersWrapper
    //                     providers={[<ModalProvider/>,
    //                         <TableFormProvider/>,
    //                         <ProcessingItemsProvider/>,
    //                         <ActiveTabProvider/>,
    //                         <HistoryProvider/>,
    //                         <ModifiersProvider/>,
    //                         <TankLoadsProvider/>]}>
    //                     <Outlet/>
    //                 </ProvidersWrapper>
    //         }
    //     </SocketProvider>
    // );
}

export default RequireAuth;

import {config} from "../config";

export const CloseStep02Model = (data, values, formValues, productId) => {
    const temperature = data.modifiers.find(item => item.category === config.modifierCategories.TEMPERATURE)
    const time = data.modifiers.find(item => item.category === config.modifierCategories.TIME)

    const modifiers = formValues.filter(item => {
        if (item.type && item.lotId && item.amount && item.available) {
            delete item.index
            delete item.lotId
            delete item.type
            delete item.available
            return item
        }
    })

    const timeModifier = {id: time.id, amount: values.time * 60}
    const temperatureModifier = {id: temperature.id, amount: values.temperature}

    return {
        step: config.processingSteps.STEP_02,
        destination: {
            itemId: data.id,
            modifiers: [timeModifier, temperatureModifier, ...modifiers],
            productId: productId
        }
    }

}

import moment from "moment";
import SeasonerCell from "../../components/molecules/table/cells/SeasonerCell";
import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import {config} from "../../config";
import TerminateSeasoningModel from "../../models/TerminateSeasoningModel";
import {validateTerminateSeasoning} from "../../validators/processStepValidators";
import TerminateSeasoningButton from "../../components/molecules/buttons/TerminateSeasoningButton";


export const agingColumns = ({onValueChange, handleSubmit}) => [
    {
        name: 'Identificativo',
        selector: row => {
            return row.chainCodes[0]
        },
        sortable: true,
        reorder: true,
        width: '160px'
    },
    {
        name: 'Inizio stagionatura',
        selector: row => moment(row.createdAt).format('DD/MM/YY HH:mm'),
        sortable: true,
        reorder: true,
        width: '180px'
    },
    {
        name: 'Tempo passato',
        selector: row => {
            const a = moment()
            const b = row.createdAt
            const diffInSeconds = a.diff(b, 'seconds')
            return moment.duration(diffInSeconds, 'seconds').humanize()
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Peso (Kg)',
        selector: row => `${row.params?.weight} Kg`,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Stagionatore',
        selector: row => <SeasonerCell seasonerId={row.params?.seasonerId}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme perse',
        selector: row => <ProcessStepInput type={config.input.WASTE} placeholder={'Forme'} data={row}
                                           updatevalue={onValueChange}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Peso perso (Kg)',
        selector: row => <ProcessStepInput type={config.input.WEIGHT_LOSS} placeholder={'Peso (Kg)'} data={row}
                                           updatevalue={onValueChange}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Termina Stagionatura',
        cell: row => {
            const options = TerminateSeasoningModel(row)
            console.log('opt', options)
            const isValid = validateTerminateSeasoning({options, row})
            return <TerminateSeasoningButton options={options} text={'Termina stagionatura'}
                                             isValid={isValid} disabled={!isValid} onSubmit={handleSubmit}/>
        },
        sortable: true,
        reorder: true,
        width: '250px'
    },
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { config } from '../../../config';
import { useModal } from '../../../contexts/ModalContext';
import { useProcessingItems } from '../../../contexts/ProcessingItemsContext';
import ProcessingItemModel from '../../../models/ProcessingItemModel';
import { userSelector } from '../../../store/user/userSlice';
import { theme } from '../../../theme';
import Box from '../../ui/Box/Box';
import Text from '../../ui/Text';

const ProcessorColumn = ({ step, children }) => {
  const user = useSelector(userSelector);
  const { t } = useTranslation();
  const { openModal } = useModal();
  const title = t(`processing.steps.${step}`);
  const { refreshProcessingItems } = useProcessingItems();

  const refreshTab = () => {
    console.log('refresh');
    refreshProcessingItems();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div>
        <Box style={styles.columnHeader}>
          <div>
            <Text variant={'h3'}>{title}</Text>
          </div>
          {user.roles.includes(config.loginRoles.STORAGE_MANAGER) &&
            <div>
              <Text variant="h6" color={theme.colors.primary}
                    onClick={() => openModal(ProcessingItemModel(step, null, refreshTab))}
                    style={styles.newItem}>{t('processing.common.addNew')}</Text>
            </div>
          }
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {children}
        </div>
      </div>
      <div style={{
        width: 1,
        backgroundColor: theme.colors.lightGrey,
        marginRight: theme.spacing['spacing-06'],
        marginLeft: theme.spacing['spacing-06'],
      }}/>
    </div>
  );
};

const styles = {
  columnHeader: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['spacing-04'],
  },
  newItem: {
    cursor: 'pointer',
    marginLeft: theme.spacing['spacing-04'],
  },
};
export default ProcessorColumn;

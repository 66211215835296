import {useSocketEmit} from "./useSocketEmit";
import {useEffect, useState} from "react";
import _ from "lodash";

declare type Modifier = {
    id: string
    category: string
}

declare type Modifiers = { [category: string]: Modifier[] }

const useModifiers = (step: string) => {
    const {getProcessItemByStep} = useSocketEmit()
    const [modifiers, setModifiers] = useState<Modifiers>()
    const [categories, setCategories] = useState<string[]>()
    const [loading, setLoading] = useState(true)

    const refreshProcessingItems = () => {
        getProcessItemByStep(step).then(res => {
            const modifiersList = _.groupBy(res[0].modifiers, modifier => modifier.category) as Modifiers
            console.log('modifiersList', modifiersList)
            // setModifiers(res[0].modifiers)
            setCategories(_.uniq(_.map(res[0].modifiers, 'category')))
            setModifiers(modifiersList)
        }).catch(console.error).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        refreshProcessingItems()
    }, [])

    return {
        modifiers,
        categories,
        loading,
    }
}

export default useModifiers

import React from 'react';
import HStack from "../../../ui/HStack";
import Text from "../../../ui/Text";
import {theme} from "../../../../theme";
import TankLoadsDetailButton from "../../buttons/TankLoadsDetailButton";

const LoadAmountColumn = ({row}) => {
    return (
        <HStack>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text variant={'default_text'} color={theme.colors.darkGrey}>{row.amount}</Text>
            </div>
            <TankLoadsDetailButton deliveryId={row.params?.deliveryId} tankId={row.params?.tankId}
                                   plateId={row.params?.plateId}/>
        </HStack>
    )
}

export default LoadAmountColumn;

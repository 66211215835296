import React from 'react';
import PolyvalentAdd from "./PolyvalentAdd";
import useProcessingItem from "../../../../hooks/useProcessingItem";
import PolyvalentModel from "../../../../models/PolyvalentModel";

const Polyvalent = ({id}) => {
    const {processItem, loading, refreshProcessingItems} = useProcessingItem(id, PolyvalentModel)

    return (
        <>
            {(processItem && !loading) &&
                <PolyvalentAdd processItem={processItem} refreshProcessingItems={refreshProcessingItems}
                               blends={processItem.blends} dbModifiers={processItem.modifiers}/>
            }
        </>
    );
};

export default Polyvalent;

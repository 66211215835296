import React from 'react';
import {config} from "../config";

const TerminateSeasoningModel = (data) => {
    if (!data) return
    console.log('daata', data)
    const closeData = {
        step: config.processingSteps.STEP_09,
        source: {
            chainCode: data.chainCodes[0],
        },
        destination: {
            itemId: data.itemId,
            waste: data.waste || 0,
            weightLoss: data.weightLoss || 0,
        },
    }

    return closeData
}

export default TerminateSeasoningModel;

import React from 'react';
import {theme} from "../../theme";

const TableInput = (props) => {
    return (
        <input {...props}
               min={0}
               style={{
                   width: '80%',
                   height: 10,
                   borderRadius: 10,
                   outline: 'none',
                   border: `1px solid ${theme.colors.lightGrey}`,
                   paddingLeft: theme.spacing["spacing-04"],
                   paddingRight: theme.spacing["spacing-04"],
                   paddingTop: theme.spacing["spacing-05"],
                   paddingBottom: theme.spacing["spacing-05"],
                   ...theme.components.Text.variants.h5,
                   ...props.style
               }}>
            {props.children}
        </input>
    );
};

export default TableInput;

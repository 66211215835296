import React from 'react';
import {theme} from "../../../theme";

const TabHeader = ({children, hasPadding}) => {

    return (
        <div style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginLeft: hasPadding && theme.spacing["spacing-10"],
            marginRight: hasPadding && theme.spacing["spacing-10"],
            borderBottom: `1px ${theme.colors.lightGrey} solid`,
        }}>
            {children}
        </div>
    );
};

export default TabHeader;

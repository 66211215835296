import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Create from '../components/layout/user/Create';
import Edit from '../components/layout/user/Edit';
import List from '../components/layout/user/List';
import Button from '../components/ui/Button';
import Modal from 'react-modal';
import Text from '../components/ui/Text';
import { config } from '../config';
import { useEffectOnce } from '../hooks/useEffectOnce';
import { logout } from '../store/user/userSlice';
import { theme } from '../theme';
import {useDispatch} from 'react-redux';
import {useSocketEmit} from "../hooks/useSocketEmit";


const Users = () => {
    const [showCreate, setShowCreate] = useState(false)
    const [registered, setRegistered] = useState(false)
    const [userToDisable, setUserToDisable] = useState(false)
    const [registerError, setRegisterError] = useState(false)
    const [disableError, setDisableError] = useState(false)
    const [users, setUsers] = useState(null)
    const [userToEdit, setUserToEdit] = useState(null)
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {getEditableUsers, disableUser} = useSocketEmit();

    useEffectOnce(()=>{
      Modal.setAppElement('#root');
      getEditableUsers().then((users)=>{
        setUsers(users);
      });

    },[]);

    const closeEdit = (res) => {
      setRegistered(res);
      setUserToEdit(null);
    }

    const toggleCreate = () => {
      setShowCreate(prev=>!prev);
      closeEdit()
    }

    const closeCreate = ()=> {
      setShowCreate(false);
    }

    const onEdit = (user) => {
      closeCreate();
      setUserToEdit(user);
      window.scrollTo(0, 0)
    }

    const openDisableModal = (userId) => {
      closeCreate();
      closeEdit();
      setUserToDisable(userId);
    }

    const onDisable = () => {
      disableUser(userToDisable).then(()=>{
        const user = users.find(u=>u.id === userToDisable);
        user.blockchainStatus = config.blockchainSyncStatuses.TO_BE_DISABLED;
        setUserToDisable(false);
      }).catch(error=>setDisableError(error));
    }

    const onEdited = (userData) => {
      const user = users.find(u=>u.id === userData.userId)
      user.extraData.location=userData.location;
      user.extraData.products=userData.products
      user.name=userData.name;
      user.blockchainStatus = config.blockchainSyncStatuses.TO_BE_UPDATED
      closeEdit()
    }

    const onCreated = (result) => {
      setRegistered(result);
      setShowCreate(false);
    }

  const closeRegisterModal = () => {
    setRegistered(null);
    setRegisterError(null);
    getEditableUsers().then((users)=>{
      setUsers(users);
    });
  }

    return (
        <div style={styles.root}>

          <div style={styles.header}>
            <Text variant='h3' color={theme.colors.black}>{t('routes.users.title')}</Text>
            <Text variant='default_text' color={theme.colors.primary} onClick={() => dispatch(logout())}
                  style={{cursor: 'pointer'}}>{t('routes.profile.logout')}</Text>
          </div>
          <div style={styles.addUserButton}>
            <Button variant={'h0'} stylex={{width:50, height:50, paddingBottom: theme.spacing['spacing-04'], paddingTop: theme.spacing['spacing-02']}}  text={showCreate? '-' : '+'} onClick={toggleCreate} />
          </div>
            {showCreate && <Create onCreated={onCreated} onError={(error)=>setRegisterError(error)}/>}
            {userToEdit && <Edit user={userToEdit} onEdited={onEdited} onError={(error)=>setRegisterError(error)} onClose={()=>closeEdit()}/>}
            {users && <List users={users} onEdit={onEdit} onDisable={openDisableModal}/>}
            <Modal isOpen={!!registered || !!registerError} style={styles.modal}>
              <div style={styles.modalBody}>
                {registerError && <Text variant={'h4'} color={theme.colors.warning} style={styles.modalText}>{registerError}</Text>}
                {registered &&
                  <Text variant='default_text' color={theme.colors.success} style={styles.modalText}>
                    {t('routes.users.registeredMessageResult', {name:registered.name, email:registered.email})}
                  </Text>
                }
                <Button
                  onClick={closeRegisterModal}
                  variant={'h5'}
                  text={t('routes.users.closeModal')}/>
              </div>
            </Modal>
            <Modal isOpen={!!userToDisable || !!disableError} style={styles.modal}>
              <div style={styles.modalBody}>
                {disableError && <Text variant={'h4'} color={theme.colors.warning} style={styles.modalText}>{disableError}</Text>}
                {userToDisable &&
                <Text variant='default_text' color={theme.colors.success} style={styles.modalText}>
                  {t('routes.users.disableMessage')}
                </Text>
                }
                <Button
                  onClick={disableError? ()=>setDisableError(false): onDisable}
                  variant={'h5'}
                  text={disableError? t('routes.users.closeModal') : t('routes.users.disableModalButton')}/>
              </div>
            </Modal>

        </div>

    );
}

const styles = {
  root:{
    padding: theme.spacing['spacing-07'],
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    width:600,
    margin:'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing['spacing-07'],
    marginTop: theme.spacing['spacing-07']
  },
  addUserButton:{
    display:'flex',
    justifyContent:'flex-end',
    width:'100%',
  },
  modal:{
    overlay: {
      backgroundColor: 'rgb(142,142,147, 0.9)',
    },
    content: {
      display:'flex',
      flexDirection:'column',
      margin:'auto',
      padding: theme.spacing['spacing-07'],
      textAlign:'center',
      backgroundColor: theme.colors.white,
      borderWidth:0,
      justifyContent:'center',
      alignItems:'center',
      width:250,
    }
  },
  modalBody: {
    textAlign: 'center',
  },
  modalText: {
    marginBottom: theme.spacing['spacing-07']
  }
}

export default Users;

import {getCallbackByStep, getComponentByStep, getTitleByStep} from "../utils";

const ProcessStepCardModel = (step, data, handleCloseModal) => {
    return {
        title: getTitleByStep(step, data),
        child: getComponentByStep(step, data),
        closeCallback: handleCloseModal || getCallbackByStep(step)
    }
};

export default ProcessStepCardModel;

import {isLoaded} from "../../utils";
import {config} from "../../config";

const PolyvalentModel = (data) => {
    const getProductIdFromLoads = (array) => {
        const firstItem = array?.length > 0 && array[0]?.productId
        if (firstItem) {
            return firstItem
        } else {
            for (let i = 0; i < array.length; i++) {
                if (array[i].productId) {
                    return array[i].productId
                }
            }
        }
    }

    return {
        id: data.id,
        productId: getProductIdFromLoads(data.loads),
        polyvalentAmount: data.amount, // to be removed
        name: data.name,
        loadAmount: data.loadAmount || null,
        waste: data.waste || null,
        hasLoads: data.loads.length > 0,
        chainCode: data.chainCode,
        isClosed: data.processStatus === config.processStatus.CLOSED,
        loaded: isLoaded(data.loads), //todo implement this properly
    }
};

export default PolyvalentModel;

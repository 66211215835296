import {createSlice} from '@reduxjs/toolkit';

const initialState = {users: null}

export const usersSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        setUsers: (state, action) => {
            // console.log('users', action.payload)
            state.users = action.payload
        },
    },
});

export const {setUsers} =
    usersSlice.actions;

export const usersSelector = state => state.users;

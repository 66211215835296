import {config} from "../../config";

export const ProcessHotChamberModel = (row, itemId) => {
    return {
        step: config.processingSteps.STEP_04,
        source: {
            itemId: row.id,
        },
        destination: {
            itemId: itemId,
            waste: row?.waste || 0,
        },
    }
};

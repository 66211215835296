import React, {useEffect, useState} from 'react';
import {useModal} from "../../../../contexts/ModalContext";
import {useTranslation} from "react-i18next";
import ListAction from "../../../ui/ListAction";
import AddWasteModal from "./AddWasteModal";
import Text from "../../../ui/Text";
import {useSelector} from "react-redux";
import {seasoningWasteSelector} from "../../../../store/seasoning/seasoningWaste";
import {getTotalWaste} from "../../../../utils";

const AddWasteCell = ({chainCodeItem}) => {
    const {openModal} = useModal()
    const {t} = useTranslation()
    const waste = useSelector(seasoningWasteSelector)

    const [totalWaste, setTotalWaste] = useState(getTotalWaste(waste))

    useEffect(() => {
        console.log("waste updated", waste)
        setTotalWaste(getTotalWaste(waste))
    }, [waste])

    const handleOpenWasteModal = () => {
        openModal({
            title: t('processing.ripeningChamber.waste.title'),
            child: <AddWasteModal chainCodeItem={chainCodeItem}/>,
        })
    }


    return <div
        style={{
            flexDirection: 'row',
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
        <Text variant={'default_text'}>{totalWaste}</Text>
        <ListAction text={t('processing.ripeningChamber.waste.details')} onClick={handleOpenWasteModal}/>
    </div>

};

export default AddWasteCell;

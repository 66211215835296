// noinspection ES6UnusedImports

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import * as resources from './resources/index'
import LanguageDetector from "i18next-browser-languagedetector";

export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        defaultNS: "common",
        fallbackLng: "it",
    }).catch(console.error);

// i18n.use(initReactI18next).init({
//     resources: {
//         ...Object.entries(resources).reduce(
//             (acc, [key, value]) => ({
//                 ...acc,
//                 [key]: {
//                     translation: value,
//                 },
//             }),
//             {},
//         ),
//     },
//     fallbackLng: 'it',
// });

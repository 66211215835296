import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { useModal } from '../../../contexts/ModalContext';
import { FormProvider } from '../../../hooks/useForm';
import { theme } from '../../../theme';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input';
import Text from '../../ui/Text';
import { inputValidator } from '../../../validators/Analytics';
import { useHistory } from '../../../contexts/HistoryProvider';

const AnalyticsReportForm = ({ item }) => {
  const { t } = useTranslation();
  const [isNew, setIsNew] = useState(!item);
  const [loading, setLoading] = useState(false);
  const {closeModal} = useModal()
  const {addAnalyticsReport} = useHistory()

  const {register, handleSubmit,  formState: {errors}} = useForm({
    mode:'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      bacterialLoad: item?.report?.bacterialLoad || '',
      protein: item?.report?.protein || '',
      fat: item?.report?.fat || '',
      evaluation: item?.report?.evaluation || 'POSITIVE',
    }
  });

  const onSubmit = inputData => {

    const data = {
      id: inputData.id,
      report: inputData,
    };
    delete data.report.id;

    addAnalyticsReport(data).catch(console.error).finally(() => {
       closeModal()
    })
  };



  useEffect(() => {
    setIsNew(!item);
    return () => {
      setIsNew(false);
    };
  }, []);


  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('analytics.common.bacterialLoad.label')}</Text>
            <Input style={ errors?.bacterialLoad ? styles.erroredInput: {}} placeholder={t('analytics.common.bacterialLoad.placeholder')} type={'text'} {...register('bacterialLoad', inputValidator)} />
            {errors?.bacterialLoad && <Text style={styles.error} variant={'p1'}>{t(errors.bacterialLoad.message)}</Text>}
          </div>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('analytics.common.protein.label')}</Text>
            <Input style={ errors?.protein ? styles.erroredInput: {}} placeholder={t('analytics.common.protein.placeholder')} type={'text'} {...register('protein', inputValidator)} />
            {errors?.protein && <Text style={styles.error} variant={'p1'}>{t(errors.protein.message)}</Text>}
          </div>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('analytics.common.fat.label')}</Text>
            <Input style={ errors?.fat ? styles.erroredInput: {}} placeholder={t('analytics.common.fat.placeholder')} type={'text'} {...register('fat', inputValidator)} />
            {errors?.fat && <Text style={styles.error} variant={'p1'}>{t(errors.fat.message)}</Text>}
          </div>
        </div>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('analytics.common.evaluation.label')}</Text>
            <select style={styles.select} {...register('evaluation')}>
              {config.analyticsReportEvaluation.map((option, i) => {
                const label = 'analytics.common.evaluation.values.' + option;
                return (
                  <option key={i} defaultValue={t(label)} value={option}>{t(label)}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div style={styles.buttonContainer}>
          <Input value={item.id} type={'hidden'} {...register('id')}/>
          <Button text={t('products.common.confirm')} type={'submit'} disabled={loading}/>
        </div>
      </form>
    </FormProvider>
  );
}

const styles = {
  columnHeader: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['spacing-04'],
  },
  newItem: { cursor: 'pointer' },
  select: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.colors.lightGrey,
    borderRadius: 10,
    paddingLeft: theme.spacing["spacing-05"],
    paddingRight: theme.spacing["spacing-05"],
    paddingTop: theme.spacing["spacing-05"],
    paddingBottom: theme.spacing["spacing-05"],
    ...theme.components.Text.variants.h5,
  },
  label: {
    marginBottom: theme.spacing['spacing-04']
  },
  textArea: {
    ...theme.components.Text.variants.default_text,
    padding: theme.spacing["spacing-05"],
    outline: 'none',
    borderRadius: 10,
    resize: 'none',
    borderColor: theme.colors.lightGrey,
    width:'90%',
  },
  formContainer: {
    display: 'flex',
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    marginTop: theme.spacing['spacing-08'],

  },
  inputContainer: {
    margin: theme.spacing['spacing-04']
  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-08']
  },
  error: {
    color: theme.colors.error
  },
  erroredInput: {
    color: theme.colors.error,
    borderColor: theme.colors.error
  }
};

export default AnalyticsReportForm;

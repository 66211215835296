import {createSlice} from '@reduxjs/toolkit';

const initialState = []

export const ripeningChamberModifiersSlice = createSlice({
    name: 'ripeningChamberModifiers',
    initialState: initialState,
    reducers: {
        updateStateLoads: (state, action) => {
            console.log(' action.payload', action.payload)
            return action.payload
        },
        resetState: () => {
            return initialState
        }
    },
});

export const {updateStateLoads, resetState} = ripeningChamberModifiersSlice.actions;

export const ripeningChamberModifiersSelector = state => state.ripeningChamberModifiers;

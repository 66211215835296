import React from 'react';
import {theme} from "../../theme";

const Text = (props) => {
    const {color, variant} = props
    return (
        <div
            style={{color: color || theme.colors.darkGrey, ...theme.components.Text.variants[variant], ...props.style}}
            onClick={props.onClick}>
            {props.children}
        </div>
    );
};

export default Text;

import {PublicClient as api} from '../gateways';
import {config} from '../config';
import {createAsyncThunk} from '@reduxjs/toolkit';
import uuid from 'react-uuid'

export default createAsyncThunk(
    'auth/login',
    async (data, {rejectWithValue}) => {
        try {
            let response;
            data.deviceId = uuid();
            response = await api.post(config.endpoints.LOGIN, {
                email: data.email,
                password: data.password,
                deviceId: data.deviceId,
            });
            console.log('loginr es', response.data)
            return response.data;
        } catch (err) {
            return rejectWithValue({
                status: err.response.status,
                message: err.response.message,
            });
        }
    },
);

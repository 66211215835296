import {useSelector} from "react-redux";
import {useMemo} from "react";

export const useGetUsers = () => {
    const {users} = useSelector(state => state.users)

    const getUserById = (id) => {
        return users.find(user => user.id === id)
    }

    const values = useMemo(
        () => ({
            getUserById,
            users,
        }),
        [getUserById]
    );

    return values;

    // return {users, getUserById}
}

import React from 'react';
import Button from '../../components/ui/Button/Button';
import {useTranslation} from "react-i18next";

const TranslateKey = ({translateKey}) => {
    const {t: pippo} = useTranslation()
    return (
        <>{pippo(translateKey)}</>
    )
}

export function productColumns (edit) { return [
    {
        name: <TranslateKey translateKey="products.common.name"/>,
        selector: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="products.common.description"/>,
        selector: row => row.description,
        sortable: true,
        reorder: true,
        hide: 'sm'
    },
    {
        name: <TranslateKey translateKey="products.common.status"/>,
        selector: row => <TranslateKey translateKey={"products.common."+row.status}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="products.common.code"/>,
        selector: row => row.code,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="products.common.type"/>,
        selector: row => <TranslateKey translateKey={"products.common."+row.type}/>,
        sortable: true,
        reorder: true,
    },
    {
        selector: row => <Button  text={<TranslateKey translateKey="products.editButton"/>} onClick={()=>edit(row)}/>,
        style: {
            justifyContent:'right',
        }
    },

]};

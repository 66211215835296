export const polyvalentLoadsExtraModifiersColumns = [
    {
        name: 'Tipo',
        selector: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Lotto',
        selector: row => row.lotId,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Quantità',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
];

import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {appPreferencesSlice} from './app/appPreferencesSlice'
import {userSlice} from "./user/userSlice";
import {usersSlice} from "./users/usersSlice";
import {ripeningChamberModifiersSlice} from "./hotChamberModifiers.js/ripeningChamberModifiersSlice";
import {productsSlice} from "./products/productsSlice";
import {processItemsSlice} from "./processItems/processItemsSlice";
import {seasonerFormSlice} from "./seasonerForm/seasonerFormSlice";
import {processableProductsSlice} from "./processableProducts/processableProductsSlice";
import {seasoningWasteFormSlice} from "./seasoning/seasoningWaste";

const reducers = combineReducers({
    appPreferences: appPreferencesSlice.reducer,
    user: userSlice.reducer,
    users: usersSlice.reducer,
    products: productsSlice.reducer,
    processItems: processItemsSlice.reducer,
    ripeningChamberModifiers: ripeningChamberModifiersSlice.reducer,
    seasonerForm: seasonerFormSlice.reducer,
    seasoningWaste: seasoningWasteFormSlice.reducer,
    processableProducts: processableProductsSlice.reducer,
})

const persistConfig = {
    key: 'root',
    version: 1,
    storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })

        // if (__DEV__ && !process.env.JEST_WORKER_ID) {
        // const createDebugger = require('redux-flipper').default
        // middlewares.push(createDebugger())
        // }

        return middlewares
    }
})

const persistor = persistStore(store)
//persistor.purge(); // per azzerare lo status

export {store, persistor}

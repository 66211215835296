import {config} from "../../config";
import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import ProcessStepButton from "../../components/molecules/buttons/ProcessStepButton";
import ProcessTankModel from "../../models/addTabs/ProcessTankModel";
import {validateTankAdd} from "../../validators/processStepValidators";
import ProductColumnField from "../../components/molecules/columnFields/ProductColumnField";

export const addMilkToTankColumns = ({onValueChange, itemId, handleSubmit}) => [
    {
        name: 'Veicolo',
        selector: row => `${row.plateId} · ${row.tankId}`,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Tipo Latte',
        cell: row => <ProductColumnField productId={row.productId}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Carico residuo',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Trasferito',
        cell: row => {
            return <ProcessStepInput placeholder={'Litri'} data={row}
                                     updatevalue={onValueChange} type={config.input.LOAD_AMOUNT}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Perso',
        cell: row => {
            return <ProcessStepInput placeholder={'Litri'} data={row}
                                     updatevalue={onValueChange} type={config.input.WASTE}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Discrepanza',
        cell: row => {
            return <ProcessStepInput placeholder={'Litri'} data={row}
                                     updatevalue={onValueChange} type={config.input.DISCREPANCY}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        cell: row => {
            const options = ProcessTankModel(row, itemId)
            const isValid = validateTankAdd({options, row})
            return <ProcessStepButton options={options} disabled={!isValid}
                                      onSubmit={handleSubmit} text={'Aggiungi'}
                                      isValid={isValid}/>
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: true,
        reorder: true,
    },
];

import {config} from "../../config";

const ProcessStartSeasoningModel = ({chainCode, itemId, seasonerForm, currentItem, formData, waste}) => {
    return {
        step: config.processingSteps.STEP_09,
        source: {
            chainCode: chainCode,
        },
        destination: {
            itemId: itemId,
            seasonerId: (seasonerForm.find(item => item.chainCode === chainCode))?.seasonerId,
            waste: waste || [],
            weight: formData.weight
        },
    }
};

export default ProcessStartSeasoningModel;

import SeasoningWasteInput from "../../components/molecules/inputs/SeasoningWasteInput";

export const addSeasoningWasteColumns = (chainCode, handleAddSeasoningWaste) => [
    {
        name: 'Rack',
        cell: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Quantità presente',
        cell: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Perse',
        selector: row => <SeasoningWasteInput rackId={row.rackId} chainCode={chainCode}
                                              handleAddSeasoningWaste={handleAddSeasoningWaste}
                                              maxAmount={row.amount}/>
        ,
        sortable: true, reorder: true,
    },
];

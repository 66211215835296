import ProcessStepButton from "../../components/molecules/buttons/ProcessStepButton";
import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import {ProcessHotChamberModel} from "../../models/addTabs/ProcessHotChamberModel";
import {config} from "../../config";
import {validateHotChamberAdd} from "../../validators/processStepValidators";

export const hotChamberAddColumns = ({onValueChange, itemId, handleSubmit}) => [
    {
        name: 'Identificativo',
        cell: row => row.blends[0]?.chainCodes[0],
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Perso',
        cell: row => {
            return <ProcessStepInput placeholder={'Forme'} data={row}
                                     updatevalue={onValueChange} type={config.input.WASTE}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        cell: row => {
            const options = ProcessHotChamberModel(row, itemId)
            const isValid = validateHotChamberAdd({options, row})

            return <ProcessStepButton options={options}
                                      onSubmit={handleSubmit} text={'Trasferisci'}
                                      isValid={isValid} disabled={!isValid}/>
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: true,
        reorder: true,
        width: '150px',
    },
];

import moment from "moment";
import {getFormattedElapsedTime} from "../../utils";

export const hotChamberLoadsColumns = [
    {
        name: 'Identificativo',
        selector: row => row.chainCodes[0],
        sortable: true,
        reorder: true,
    },
    {
        name: 'Data e Ora',
        selector: row => moment(row.createdAt).format('DD/MM/YY HH:mm'),
        sortable: true,
        reorder: true,
    },
    {
        name: 'In camera calda da...',
        selector: row => {
            return getFormattedElapsedTime(row.createdAt)
        }
    },
    {
        name: 'Forme Trasferite',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Perse',
        selector: row => row.waste,
        sortable: true,
        reorder: true,
    },
];

import _ from 'lodash'

const PressLoadModel = (data) => {
    return {...data, loadAmount: '', waste: ''}
}

const PressLoadsModel = (data) => {
    const rackLoads = _.map(data, PressLoadModel)
    return rackLoads
};

export default PressLoadsModel;

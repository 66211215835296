import * as React from "react";
import {useEffect, useState} from "react";
import {theme} from "../../theme";
import {useModifiers} from "../../contexts/ModifiersFormContext";
// @ts-ignore
import {LOT, MODIFIERS} from "../../config";


const Dropdown = ({dropdownType, index}) => {
    const {
        // @ts-ignore
        formValues,
        // @ts-ignore
        getAvailableModifiersType,
        // @ts-ignore
        getAvailableLotIdsByType,
        // @ts-ignore
        updateModifiers,
    } = useModifiers()

    const [options, setOptions] = useState([])

    useEffect(() => {
        if (dropdownType === MODIFIERS) {
            setOptions(getAvailableModifiersType())
        } else if (dropdownType === LOT) {
            setOptions(getAvailableLotIdsByType(formValues[index].type))
        }
    }, [dropdownType])

    useEffect(() => {
        /**
         * This useEffect should trigger only if the type is selected.
         * Since selecting a lotId updates the {hotChamberModifiers.js} object triggering this
         * useEffect the second check in the if (a lotId should not be set)
         * protects [options] from being recalculated
         */
        if (dropdownType === LOT && !formValues[index].lotId) {
            setOptions(getAvailableLotIdsByType(formValues[index].type))
        }

    }, [formValues])

    const handleChange = (e) => {
        updateModifiers(index, {type: dropdownType, value: e.target.value, dataType: formValues[index].type})
    }

    const getSelectValue = () => {
        if (dropdownType === MODIFIERS) {
            return formValues[index]?.type
        } else if (dropdownType === LOT) {
            return formValues[index]?.lotId
        } else {
            return ''
        }
    }

    return (
        <select style={{
            border: `1px solid ${theme.colors.lightGrey}`,
            width: '100%',
            height: 40,
            borderRadius: 10,
            ...theme.components.Text.variants.default_text,
        }}
                onChange={handleChange}
                value={getSelectValue()}>
            <option defaultValue={''} value={''}>{''}</option>
            {options?.map((option, i) => {
                return (
                    <option key={i} defaultValue={'Seleziona...'} value={option}>{option}</option>
                )
            })}
        </select>
    )
}

export default Dropdown

import {createContext, useCallback, useContext, useState} from "react";
import { useEffectOnce } from '../hooks/useEffectOnce';
import {useSocketEmit} from "../hooks/useSocketEmit";

export const ModifiersContext = createContext();

export const useModifiers = () => useContext(ModifiersContext)

export const ModifiersProvider = ({children}) => {
    const [modifiers, setModifiers] = useState([]);
    const {getModifiers, addModifier} = useSocketEmit()

    useEffectOnce(()=>{
        getModifiers().then((data)=>{
            setModifiers(data)
        }).catch(error=>console.log(error))
    },[])

    const add = async (data) => {
        return new Promise((resolve, reject) => {
            addModifier(data).catch((error => {
                console.error(error)
                reject(error);
            })).then((result) => {
                if(data.id){
                    const prod = modifiers.map(p=>{
                        if(p.id===data.id){
                            return data;
                        } else {
                            return p;
                        }
                    })
                    setModifiers(prod);
                } else {
                    setModifiers([...modifiers, result])
                }
                resolve();
            })
        })
    }

    return (
        <ModifiersContext.Provider
            value={{modifiers, setModifiers, add}}>
            {children}
        </ModifiersContext.Provider>
    )
};

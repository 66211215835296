import React from 'react';
import {theme} from "../../theme";
import LibDataTable from 'react-data-table-component';
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";

const DataTable = (props) => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})
    const headCells = isTabletOrMobile ? {...theme.components.Text.variants.p1} : {...theme.components.Text.variants.h6}
    const cells = isTabletOrMobile ? {...theme.components.Text.variants.p2} : {...theme.components.Text.variants.default_text}

    const {t} = useTranslation()

    return (
        <LibDataTable
            {...props}
            noDataComponent={t('table.noDataComponentText')}
            pointerOnHover
            customStyles={{
                header: {
                    style: {
                        ...theme.components.Text.variants.h3,
                        color: theme.colors.darkGrey,
                    }
                },
                headCells: {
                    style: {
                        ...headCells,
                        color: theme.colors.midGrey
                    },
                },
                cells: {
                    style: {
                        ...cells,
                        color: theme.colors.darkGrey,
                    }
                }
            }}
        />
    );
};

export default DataTable;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { useModal } from '../../../contexts/ModalContext';
import { FormProvider } from '../../../hooks/useForm';
import { useSocketEmit } from '../../../hooks/useSocketEmit';
import { theme } from '../../../theme';
import { capacityValidator, descriptionValidator, nameValidator } from '../../../validators/ProcessingItem';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input';
import Text from '../../ui/Text';

const ProcessingItemForm = ({ item, step }) => {
  const { t } = useTranslation();
  const [isNew, setIsNew] = useState(!item);
  const title = t(`processing.steps.${step}`);
  const [loading, setLoading] = useState(false);
  const { addProcessingItem } = useSocketEmit();
  const { closeModal } = useModal();

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      name: item?.name || '',
      capacity: item?.capacity || 0,
      code: item?.code?.id || '',
      description: item?.description || '',
      processStatus: item?.processStatus || config.processStatus.AVAILABLE,
    },
  });
  const [status, setStatus] = useState(item?.status || null);

  const onSubmit = data => {
    console.log(item, data);
    data.code = { id: data.code };
    if (step === config.processingSteps.STEP_08) {
      data.extraData = { containerId: data.physicalRackId };
      delete data.physicalRackId;
    }

    if (data.description === '') {
      delete data.description;
    }

    addProcessingItem({ items: [data] }).catch(console.error).finally(() => {
      closeModal();
    });
  };

  useEffect(() => {
    setIsNew(!item);
    return () => {
      setIsNew(false);
    };
  }, []);

  return (
    <FormProvider>
      {isNew && <Text variant="h1" color={theme.colors.primary}
                      style={styles.newItem}>{t('processing.common.addNew')} {title}</Text>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('processing.common.name')}</Text>
            <Input style={errors?.name ? styles.erroredInput : {}} placeholder={t('processing.common.name')}
                   type={'text'} {...register('name', nameValidator(step))} />
            {errors?.name && <Text style={styles.error} variant={'p1'}>{t(errors.name.message)}</Text>}
          </div>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('processing.common.code')}</Text>
            <Input style={errors?.code ? styles.erroredInput : {}} placeholder={t('processing.common.code')}
                   type={'text'} {...register('code', nameValidator(step))}/>
            {errors?.code && <Text style={styles.error} variant={'p2'}>{t(errors.code.message)}</Text>}
          </div>
          {step === config.processingSteps.STEP_08 && <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('processing.common.physicalRackId')}</Text>
            <Input style={errors?.code ? styles.erroredInput : {}} placeholder={t('processing.common.physicalRackId')}
                   type={'text'} {...register('physicalRackId', nameValidator(step))}/>
            {errors?.physicalRackId &&
              <Text style={styles.error} variant={'p2'}>{t(errors.physicalRackId.message)}</Text>}
          </div>}

          {![config.processingSteps.STEP_09, config.processingSteps.STEP_07, config.processingSteps.STEP_06, config.processingSteps.STEP_04].includes(step) &&
            <div style={styles.inputContainer}>
              <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('processing.common.capacity')}</Text>
              <Input style={errors?.code ? styles.erroredInput : {}} placeholder={t('processing.common.capacity')}
                     type={'text'} {...register('capacity', capacityValidator(step))}/>
              {errors?.capacity && <Text style={styles.error} variant={'p2'}>{t(errors.capacity.message)}</Text>}
            </div>}
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('processing.common.availability')}</Text>
            <select style={styles.select} {...register('processStatus')}>
              {[config.processStatus.AVAILABLE, config.processStatus.UNAVAILABLE].map((option, i) => {
                const label = 'processing.common.' + option;
                return (
                  <option key={i} defaultValue={t(label)} value={option}>{t(label)}</option>
                );
              })}
            </select>
          </div>
          <Input value={step} type={'hidden'} {...register('step')}/>
          {!isNew && <Input value={item.id} type={'hidden'} {...register('id')}/>}
        </div>
        <div style={styles.inputContainer}>
          <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('processing.common.description')}</Text>
          <textarea  {...register('description', descriptionValidator(step))}
                     placeholder={t('processing.common.description')} style={styles.textArea}/>
        </div>
        <div style={styles.buttonContainer}>
          <Button text={t('processing.common.confirm')} type={'submit'} disabled={loading}/>
        </div>
      </form>
    </FormProvider>
  );
};

const styles = {
  columnHeader: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['spacing-04'],
  },
  newItem: { cursor: 'pointer' },
  select: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.colors.lightGrey,
    borderRadius: 10,
    paddingLeft: theme.spacing['spacing-05'],
    paddingRight: theme.spacing['spacing-05'],
    paddingTop: theme.spacing['spacing-05'],
    paddingBottom: theme.spacing['spacing-05'],
    ...theme.components.Text.variants.h5,
  },
  label: {
    marginBottom: theme.spacing['spacing-04'],
  },
  textArea: {
    ...theme.components.Text.variants.default_text,
    padding: theme.spacing['spacing-05'],
    outline: 'none',
    borderRadius: 10,
    resize: 'none',
    borderColor: theme.colors.lightGrey,
    width: '90%',
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing['spacing-08'],

  },
  inputContainer: {
    margin: theme.spacing['spacing-04'],
  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-08'],
  },
  error: {
    color: theme.colors.error,
  },
  erroredInput: {
    color: theme.colors.error,
    borderColor: theme.colors.error,
  },
};

export default ProcessingItemForm;

import HStack from "../../components/ui/HStack";
import moment from "moment";
import {getCTAFromStatus} from "../../utils";
import TankListAction from "../../components/molecules/TankListAction";
import StatusIcon from "../../components/ui/StatusIcon";

export const tankBlendHistoryColumns = [
    {
        name: 'Data e ora',
        selector: row => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss') || "N/A",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Ritirato Presso',
        selector: row => row.origin.name,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Quantità',
        selector: row => {
            console.log('itgzs', row)
            return row.params?.quantity
        },
        sortable: true,
        reorder: true,
    },
    {
        selector: row => row.status,
        cell: row => {
            const cta = getCTAFromStatus(row.status)
            console.log('status', row.status, 'cta', cta)
            return (
                <HStack>
                    <TankListAction cta={cta} index={row.index} id={row.id} quantity={row.params.quantity}
                                    target="_blank" rel="noopener noreferrer"/>
                    <StatusIcon status={row.status}/>
                </HStack>)
        },
        sortable: true,
        reorder: true,
    },
];

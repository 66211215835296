import React, {useEffect, useMemo} from 'react';
import {useForm} from "../../../../hooks/useForm";
import TableContainer from "../../tab/TableContainer";
import useProcessingItem from "../../../../hooks/useProcessingItem";
import {rackLoadsColumns} from "../../../../data/tables/rackLoadsColumns";
import DataTable from "../../DataTable";
import {addLoadsToRackColumns} from "../../../../data/tables/addLoadsToRackColumns";
import PressLoadsModel from "../../../../models/loadTabs/PressLoadsModel";
import {useModal} from "../../../../contexts/ModalContext";

const RackModel = (data) => {
    return {...data.item, sourceItems: data.sourceItems}
}

const Rack = ({id}) => {
    const {processItem, loading} = useProcessingItem(id, RackModel)
    const {formData, setFormData, onValueChange, resetForm} = useForm([])
    const {closeModal} = useModal()

    useEffect(() => {
        if (!loading && processItem) {
            setFormData(PressLoadsModel(processItem.sourceItems[0]?.blends))
        }
    }, [loading])

    const hasLoads = useMemo(() => processItem?.blends?.length > 0, [formData, processItem?.blends])
    const tableData = useMemo(() => (hasLoads ? processItem?.blends : formData), [formData, processItem?.blends])

    const handleSubmit = () => {
        closeModal()
    }

    return (
        <TableContainer>
            <DataTable
                highlightOnHover={false}
                columns={hasLoads ? rackLoadsColumns(processItem?.blends) : addLoadsToRackColumns({
                    resetForm,
                    onValueChange,
                    handleSubmit,
                    itemId: processItem?.id, capacity: processItem?.capacity
                })}
                data={tableData}
            />
        </TableContainer>
    )
};

export default Rack;

import {getCallbackByStep, getComponentByStep} from "../utils";

const ProcessStepRackCardModel = (step, data) => {
    return {
        title: 'Rack',
        child: getComponentByStep(step, data.items),
        closeCallback: getCallbackByStep(step)
    }
};

export default ProcessStepRackCardModel;

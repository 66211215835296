import React from 'react';
import DateRangePickerLib from '@wojtekmaj/react-daterange-picker';
import "./DateRangePicker.css";
import "./Calendar.css";

const DateRangePicker = (props) => {
    return (
        <DateRangePickerLib {...props} clearIcon={null}/>
    );
};

export default DateRangePicker;

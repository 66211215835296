import {config} from "../config";

const ModifiersLoadModel = (data, dbModifiers) => {
    const temperatureId = (dbModifiers.find(item => item.name === config.polyvalent.modifiers.TEMPERATURE))?.id
    const timeId = (dbModifiers.find(item => item.name === config.polyvalent.modifiers.TIME))?.id

    const temperature = data.find(item => item.id === temperatureId)?.amount || ''
    const time = data.find(item => item.id === timeId)?.amount || ''

    const extraModifiers = data.filter(item => item.id !== temperatureId && item.id !== timeId)

    return {time: time, temperature: temperature, extraModifiers: extraModifiers}
}

export default ModifiersLoadModel

import * as React from "react";
import {useEffect} from "react";
import './App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import {config} from "./config";
import {routes} from "./routes";
import './translations/i18n'
import Layout from "./components/layout/Layout";
import RequireAuth from "./components/RequireAuth";
import {BrowserView, MobileView} from 'react-device-detect';
import MobilePlaceholder from "./components/layout/MobilePlaceholder";
import IgnoreRoute from "./components/IgnoreRoute";
import useAuth from "./hooks/useAuth";
import {getNavigateRouteFromRole} from "./utils";

function App() {
    const {isAuth, role} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuth) navigate(config.routes.LOGIN, {replace: true})
        if (role) {
            navigate(getNavigateRouteFromRole(role))
        }
    }, [role])

    return (
        <>
            <BrowserView>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        {/*public routes*/}
                        <Route element={<IgnoreRoute/>}>
                            <Route path={config.routes.LOGIN} element={routes.login}/>
                            <Route path={config.routes.RECOVER_PASSWORD} element={routes.recovery}/>
                        </Route>
                        {/*protected routes*/}
                        <Route element={<RequireAuth allowedRoles={[config.loginRoles.ISSUER]}/>}>
                            <Route path={config.routes.CREATE_USERS} element={routes.createUsers}/>
                        </Route>
                        <Route element={<RequireAuth
                            allowedRoles={[config.loginRoles.EXTERNAL_SEASONER, config.loginRoles.INTERNAL_SEASONER]}/>}>
                            <Route path={config.routes.AGING} element={routes.aging}/>
                        </Route>
                        <Route element={<RequireAuth
                            allowedRoles={[config.loginRoles.PROCESSOR, config.loginRoles.STORAGE_MANAGER]}/>}>
                            <Route path={config.routes.HOME} element={routes.home}/>
                            <Route path={config.routes.PROFILE} element={routes.profile}/>
                        </Route>

                        <Route path={config.routes.UNAUTHORIZED} element={routes.unauthorized}/>
                        {/*catch all routes*/}
                        <Route path={config.routes.MISSING} element={routes.missing}/>
                    </Route>
                </Routes>
            </BrowserView>
            <MobileView>
                <MobilePlaceholder/>
            </MobileView>
        </>
    );
}

export default App;

import React from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../ui/Button/Button";
import {useSocketEmit} from "../../../hooks/useSocketEmit";

const TerminateSeasoningButton = ({options, onSubmit, disabled, isValid = true}) => {
    const {t} = useTranslation()
    const {terminateSeasoning} = useSocketEmit()

    const handleTerminateSeasoning = () => {
        if (isValid) {
            terminateSeasoning(options).then(r => {
                onSubmit()
            }).catch(console.error)
        }
    }

    return <Button text={t('processing.aging.terminate')} onClick={handleTerminateSeasoning} disabled={disabled}/>
}

export default TerminateSeasoningButton;

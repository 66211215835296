import React, {useState} from 'react';
import { useModal } from '../../../contexts/ModalContext';
import { modifierColumns } from '../../../data/tables/modifierColumns';
import DataTable from "../../molecules/DataTable";
import {useTranslation} from "react-i18next";
import ModifierForm from '../../molecules/forms/ModifierForm';
import Button from '../../ui/Button/Button';


const ModifiersTable = ({tableData}) => {
    const {t} = useTranslation()
    const {openModal} = useModal()

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);


    const addModifier = (data) => {
        openModal({
            title: data.id ? t('modifiers.editModal.title') : t('modifiers.addModal.title'),
            child: <ModifierForm item={data.id? data : null}/>,
        })
    }

    return (
        <DataTable
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            title={<div style={styles.titleRoot}>
                <span>{t('modifiers.title')}</span>
                <Button text={t('modifiers.addButton')} onClick={addModifier}/>
            </div>}
            columns={modifierColumns(addModifier)}
            data={tableData}
        />
    );
};

const styles = {
    titleRoot:{
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
}
export default ModifiersTable;

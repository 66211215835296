import React from 'react';
import {useTranslation} from "react-i18next";
import {useModal} from "../../contexts/ModalContext";
import {config} from "../../config";
import CreateDispute from "./modals/CreateDispute";
import DisputeDetails from "./modals/DisputeDetails";
import {useHistory} from "../../contexts/HistoryProvider";
import {useModifiers} from "../../contexts/ModifiersFormContext";
import ListAction from "../ui/ListAction";

const TankListAction = ({cta, index, id, quantity}) => {
    const {t} = useTranslation()
    const {openModal} = useModal()
    const {refreshHistory} = useHistory()
    const {removeRow} = useModifiers()

    const text = cta && t(`dispute.disputeCTAs.${cta}`)

    const handleClick = () => {
        if (cta === config.listActionCTAs.DELETE) {
            removeRow(index)
        }

        if (cta === config.listActionCTAs.DISPUTE_DETAILS) {
            openModal({
                title: t('dispute.disputeCTAs.disputeDetails'),
                child: <DisputeDetails referenceId={id}/>,
            })
        }
        if (cta === config.listActionCTAs.CREATE_DISPUTE) {
            openModal({
                title: t('dispute.disputeCTAs.createDispute'),
                child: <CreateDispute quantity={quantity} id={id}/>,
                closeCallback: refreshHistory
            })
        }
    }


    return (
        <ListAction text={text} onClick={handleClick}/>
    );
};

export default TankListAction;

import React from 'react';
import ModifiersForm from "./ModifiersForm";
import {FormProvider} from "../../../../hooks/useForm";
import {config} from "../../../../config";
import AddTab from "../AddTab";
import {addMilkToPolyvalentColumns} from "../../../../data/tables/addMilkToPolyvalentColumns";
import {useTranslation} from "react-i18next";
import TableContainer from "../../tab/TableContainer";
import Text from "../../../ui/Text";
import {theme} from "../../../../theme";
import PolyvalentLoadsModifier from "./PolyvalentLoadsModifier";
import DataTable from "../../DataTable";
import {polyvalentLoadsColumns} from "../../../../data/tables/polyvalentLoadsColumns";

const PolyvalentAdd = ({processItem, refreshProcessingItems, blends, dbModifiers}) => {
    const {t} = useTranslation()
    const isPolyvalentLoaded = processItem.processStatus === config.processStatus.CLOSED
    
    return (
        <FormProvider>
            <TableContainer>
                {blends.length > 0 &&
                    <>
                        <PolyvalentLoadsModifier blends={blends} loads={blends} dbModifiers={dbModifiers}/>
                        <DataTable
                            title={t('processing.polyvalent.loads.milkTableTitle')}
                            highlightOnHover={false}
                            columns={polyvalentLoadsColumns}
                            data={blends.filter(item => !(item.params.modifiers))}
                        />
                        <div style={{paddingBottom: theme.spacing["spacing-05"]}}/>
                    </>}
                {!isPolyvalentLoaded &&
                    <>
                        <Text variant={'h3'} style={{fontWeight: 400, padding: theme.spacing["spacing-05"]}}>Carica da
                            una
                            cisterna</Text>
                        <AddTab loads={processItem.sourceItems} columns={addMilkToPolyvalentColumns}
                                itemId={processItem.id}
                                step={config.processingSteps.STEP_02} refreshProcessingItems={refreshProcessingItems}
                                capacity={processItem.capacity} ignoreTableContainer={true}/>
                        <ModifiersForm processItem={processItem}/>
                    </>
                }
            </TableContainer>
        </FormProvider>
    );
};

export default PolyvalentAdd;

import React from 'react';
import {useTranslation} from 'react-i18next';
import {config} from '../../../config';
import {useProcessingItems} from '../../../contexts/ProcessingItemsContext';
import {useSocketEmit} from '../../../hooks/useSocketEmit';
import {
    resetState as ripeningChamberResetState,
} from '../../../store/hotChamberModifiers.js/ripeningChamberModifiersSlice';
import {resetState as seasonerIdResetState} from '../../../store/seasonerForm/seasonerFormSlice';
import ProcessStepCardModel from '../../../models/ProcessStepCardModel';
import {userSelector} from '../../../store/user/userSlice';
import {theme} from '../../../theme';
import Card from '../../ui/Card/Card';
import {useDispatch, useSelector} from 'react-redux';
import {useModal} from '../../../contexts/ModalContext';
import {useModifiers} from '../../../contexts/ModifiersFormContext';
import {resetState} from "../../../store/seasoning/seasoningWaste";

/**
 * General card for a processingItem. It isolates the behavior of the modal.
 * The component defines card's handleClick and the override to modal's close callback
 * @param processingItem
 * @param capacity
 * @param unit
 * @returns {JSX.Element}
 * @constructor
 */
const ProcessingItemCard = ({processingItem, useStatus, unit}) => {
    const dispatch = useDispatch();
    const {openModal} = useModal();
    const {resetModifiersFormValues} = useModifiers();
    const user = useSelector(userSelector);
    const {t} = useTranslation();
    const {refreshProcessingItems} = useProcessingItems();
    const {deleteProcessingItem} = useSocketEmit();

    return (
        <>{
            processingItem.items?.map((data, i) => {
                const handleCloseModal = () => {
                    resetModifiersFormValues();
                    dispatch(ripeningChamberResetState());
                    dispatch(seasonerIdResetState());
                    dispatch(resetState())
                };
                const refreshTab = () => {
                    refreshProcessingItems();
                };
                const handleClick = () => {
                    if (user.roles.includes(config.loginRoles.PROCESSOR)) {
                        openModal(ProcessStepCardModel(processingItem.step, data, handleCloseModal));
                    }
                    if (user.roles.includes(config.loginRoles.STORAGE_MANAGER)) {
                        const status = data.processStatus === config.processStatus.AVAILABLE ? config.processStatus.UNAVAILABLE : config.processStatus.AVAILABLE
                        deleteProcessingItem({itemId: data.id, status: status}).catch(console.error)
                            .finally(() => {
                                refreshTab();
                            });
                    }
                };

                return (
                    <div key={i} style={{paddingBottom: theme.spacing['spacing-05']}}>
                        <Card name={data.name} capacity={data.capacity} amount={data.amount || '0'}
                              unit={unit || 'Litri'} status={data.processStatus}
                              useStatus={useStatus}
                              handleClick={handleClick}
                              buttonLabel={user.roles.includes(config.loginRoles.STORAGE_MANAGER)
                                  ? t('components.card.disable')
                                  : t('components.card.open')}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default ProcessingItemCard;

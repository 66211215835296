import React, {useState} from 'react';
import { Controller } from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Switch from 'react-switch';
import { theme } from '../../../theme';
import Button from '../../ui/Button';
import Text from '../../ui/Text';


const Product = ({control, productIds, product, buildProductIds }) => {
    const {t} = useTranslation();

    return (
      <div style={styles.root}>
          <Text variant={'h4'}>{product.name}</Text>
          <Controller
            name={product.name}
            control={control}
            rules={{validate: v => {console.log('ccc', productIds.length > 0);return productIds.length > 0 || 'aggiungi almeno un prodotto'}}}
            render={({field}) => <Switch
              onChange={(e) => buildProductIds(product.id)}
              checked={!!productIds.includes(product.id)}
              onColor={theme.colors.primary}
              offColor={theme.colors.midGrey}
              uncheckedIcon={false}
              checkedIcon={false}
            />}
          />
      </div>
    );
};

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: theme.spacing['spacing-05']
    },
}

export default Product;

import React from 'react';
import {theme} from "../../theme";
import Box from "./Box/Box";
import VStack from "./VStack";
import Text from "./Text";

const ModalInput = ({title, error, ...props}) => {
    return (
        <VStack>
            <Text variant={'h6'} color={theme.colors.midGrey} style={{
                paddingTop: theme.spacing["spacing-03"],
                paddingLeft: theme.spacing['spacing-03'],
                paddingRight: theme.spacing['spacing-03'],
                paddingBottom: theme.spacing["spacing-03"]
            }}>{title}</Text>
            <Box style={{
                borderBottom: error ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.lightGrey}`,
                paddingTop: theme.spacing["spacing-05"],
                paddingBottom: theme.spacing["spacing-05"],
                paddingLeft: theme.spacing["spacing-03"],
                paddingRight: theme.spacing["spacing-03"],
                bg: '#ff33ff'
            }}>
                <input style={{
                    ...theme.components.Text.variants.default_text,
                    // width: '100%',
                    border: 'none',
                    outline: 'none',
                }} {...props}/>
            </Box>
        </VStack>
    );
};

export default ModalInput;

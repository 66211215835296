import React, {useEffect, useState} from 'react';
import DataTable from "../../DataTable";
import useWindowSize from "../../../../hooks/useWindowSize";
import {tankBlendHistoryColumns} from "../../../../data/tables/tankBlendHistoryColumns";
import {useSocketEmit} from "../../../../hooks/useSocketEmit";

const TankBlendHistory = ({deliveryId, tankId, plateId}) => {
    const windowSize = useWindowSize()
    const [modalContentHeight, setModalContentHeight] = useState(windowSize.height * .8 - 150)

    const {getHistoryByDeliveryId} = useSocketEmit()

    const [data, setData] = useState()

    useEffect(() => {
        getHistoryByDeliveryId(deliveryId, tankId, plateId).then(res => {
            setData(res?.blends)
        }).catch(console.error)
    }, [])

    return (
        <div style={{width: '100%', overflowY: 'scroll', height: modalContentHeight}}>
            <DataTable
                highlightOnHover={false}
                columns={tankBlendHistoryColumns}
                data={data}
            />
        </div>
    );
};

export default TankBlendHistory;

import React from 'react';
import Text from "../../ui/Text";
import {theme} from "../../../theme";

const Title = ({title}) => {
    return (
        <>{title && <Text color={theme.colors.primary} variant={'h1'}
                          style={{
                              marginBottom: theme.spacing["spacing-04"],
                              paddingRight: theme.spacing["spacing-08"]
                          }}>{title}</Text>}
        </>
    );
};

export default Title;

import * as React from 'react';
import {BoxProps} from "./types";
import {StyledBox} from './styles'

export const Box = (props: BoxProps) => {
    return (
        <StyledBox style={props.style}>
            {props.children}
        </StyledBox>
    );
};

export default Box;

import React from 'react';

const Center = (props) => {
    return (
        <div style={{display: 'flex', flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center'}}>
            {props.children}
        </div>
    );
};

export default Center;

import React from 'react';
import Center from "../ui/Center";
import Text from "../ui/Text";
import VStack from "../ui/VStack";
import logo from '../../assets/logo_unblended.svg'

const MobilePlaceholder = () => {
    return (
        <div style={{height: '100vh'}}>
            <Center>
                <VStack>
                    <img src={logo} style={{width: '100%', height: 50, paddingBottom: 20}}/>
                    <Text variant={'h4'} style={{textAlign: 'center', flex: 1}}>Il sito è fruibile solo da Browser
                        Desktop</Text>
                </VStack>
            </Center>
        </div>
    );
};

export default MobilePlaceholder;

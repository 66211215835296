import React from 'react';
import Text from "../components/ui/Text";
import Button from "../components/ui/Button/Button";
import {useLocation, useNavigate} from "react-router-dom";
import VStack from "../components/ui/VStack";
import AuthLayout from "../components/layout/AuthLayout";
import {theme} from "../theme";
import Input from "../components/ui/Input";
import {emailValidator} from "../validators/login";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import TankListAction from "../components/molecules/TankListAction";
import Center from "../components/ui/Center";

const Recovery = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname ? -1 : "/";
    const goBack = () => navigate(from, {replace: true});
    const {t} = useTranslation()
    const {register, handleSubmit} = useForm();

    const onSubmit = data => {
        // dispatch(Login(data)).then(unwrapResult).then(res => {
        //     console.log('Login successful', res)
        //     navigate(config.routes.HOME, {replace: true})
        // }).catch((rejectedValueOrSerializedError, i) => {
        //     console.log('rej', rejectedValueOrSerializedError)
        //     if (rejectedValueOrSerializedError.status === 404) {
        //         console.log('404 error')
        //     } else if (rejectedValueOrSerializedError.status === 401) {
        //         alert('Autenticazione fallita')
        //     }
        // }).finally(() => {
        //     setLoading(false)
        // });
        console.log("data", data)
    }

    return (

        <AuthLayout>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: 300}}>
                <VStack space={theme.spacing["spacing-05"]}>
                    <Text variant={'h3'}>Recupero Password</Text>
                    <Input placeholder={t('routes.login.hotChamberModifiers.js.placeholders.email')}
                           type={'email'} {...register("email", emailValidator)}/>
                    <Button text={t('routes.login.hotChamberModifiers.js.placeholders.recover')} type={'submit'}/>
                    <Center>
                        <TankListAction
                            onClick={goBack} text={t('routes.login.hotChamberModifiers.js.placeholders.back')}/>
                    </Center>
                </VStack>
            </form>
        </AuthLayout>
    );
};

export default Recovery;

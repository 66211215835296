import TableInput from "../../ui/TableInput";
import Button from "../../ui/Button/Button";
import {theme} from "../../../theme";

const TableFilter = ({filterText, onFilter, onClear, inputPlaceholder, clearButtonText}) => (
    <>
        <TableInput
            id="search"
            type="text"
            placeholder={inputPlaceholder}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
            style={{width: 'auto', marginRight: theme.spacing["spacing-03"]}}
        />
        {onClear && <Button text={clearButtonText} onClick={onClear}/>}
    </>
);

export default TableFilter

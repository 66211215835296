import React, {useState} from 'react';
import {theme} from "../../theme";

const Button = (props) => {
  const {text, disabled, stylex} = props
  const [clicked, setClicked] = useState(false)
  const variant = props.variant ? {...theme.components.Text.variants[props.variant]} : {...theme.components.Text.variants.h5};
  const style = {
      backgroundColor: stylex?.background ? stylex?.background : theme.colors.primary,
      borderRadius: 55,
      color: theme.colors.white,
      paddingLeft: theme.spacing["spacing-04"],
      paddingRight: theme.spacing["spacing-04"],
      paddingTop: stylex?.paddingTop? stylex?.paddingTop : theme.spacing["spacing-03"],
      paddingBottom: stylex?.paddingBottom? stylex?.paddingBottom : theme.spacing["spacing-03"],
      margin:'auto',
      ...variant,
      height: stylex?.height || 38,
      minWidth: stylex?.width|| 100,
      width: stylex?.width || 100,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      display: 'flex',
      borderColor: 'transparent',
      cursor: 'pointer',
      opacity: (clicked || disabled) ? 0.5 : 1,
    };

  if(stylex?.marginRight){
    style.marginRight = stylex.marginRight
  }

  if(stylex?.marginLeft){
    style.marginLeft = stylex.marginLeft
  }

  return (
    <div>
      <button
        onMouseDown={() => setClicked(!clicked)}
        onMouseUp={() => setClicked(!clicked)}
        style={style}
        {...props}>
        {text}
      </button>
    </div>
  );
};

export default Button;

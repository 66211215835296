import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import {config} from "../../config";
import AddWasteCell from "../../components/molecules/modals/ripeningChamber/AddWasteCell";
import StartSeasoningButton from "../../components/molecules/buttons/StartSeasoningButton";
import SeasonerSelector from "../../components/molecules/buttons/SeasonerSelector";

export const seasoningAddColumns = ({resetForm, onValueChange, itemId}) => [
    {
        name: 'Identificativo',
        cell: row => row.chainCodes[0],
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Perse',
        cell: row => {
            return <AddWasteCell chainCodeItem={row}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Peso (Kg)',
        cell: row => {
            return <ProcessStepInput placeholder={'Peso (Kg)'} data={row}
                                     updatevalue={onValueChange} type={config.input.WEIGHT}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Stagionatore',
        cell: row => {
            return <SeasonerSelector data={row}/>
        },
        sortable: true,
        reorder: true
    },
    {

        cell: row => {
            return <StartSeasoningButton chainCode={row.chainCodes[0]} itemId={itemId} formData={row}/>
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: true,
        reorder: true,
        width: '200px'
    },
];

import {createContext, useCallback, useContext, useState} from "react";
import {useSocketEmit} from "../hooks/useSocketEmit";

export const HistoryContext = createContext();

export const useHistory = () => useContext(HistoryContext)

export const HistoryProvider = ({children}) => {
    const [history, setHistory] = useState(null); // TODO typecheck this
    const {getHistory, addReport} = useSocketEmit()

    const refreshPaginationHistory = useCallback((options) => {
        getHistory(options).then(res => {
            if (res.blends?.length > 0) {
                setHistory(res.blends)
            }
        }).catch(console.error)
    }, [])

    const refreshHistory = useCallback((options) => {
        console.log('triggering refreshHistory', options)
        getHistory(options).then(res => {
            console.log('blendHistory', new Date(), res)
            setHistory(res.blends)
        }).catch(console.error)
    }, [])

    const updateHistoryElement = (newHistoryElement) => {
        const updatedHistory = history.map(historyElement => historyElement.id !== newHistoryElement.id ? historyElement : newHistoryElement)
        console.log('updatedHistory', updatedHistory)
        setHistory(updatedHistory)
    }

    const addAnalyticsReport = (data) => {
        return new Promise((resolve, reject) => {
            addReport({blendId: data.id, report: data.report}).catch((error => {
                console.error(error)
                reject(error);
            })).then((result) => {
                updateHistoryElement(result)
                resolve();
            })
        })
    }


    return (
        <HistoryContext.Provider
            value={{history, setHistory, refreshHistory, refreshPaginationHistory, updateHistoryElement, addAnalyticsReport}}>
            {children}
        </HistoryContext.Provider>
    )
};

import React from 'react';
import {useModal} from "../../../../contexts/ModalContext";
import {useTranslation} from "react-i18next";
import Button from "../../../ui/Button/Button";
import useProcessingItems from "../../../../hooks/useProcessingItems";
import {config} from "../../../../config";
import {filterRacksByChainCode} from "../../../../utils";
import ChainCodeRackList from "./ChainCodeRackList";


const RipeningChamberDetailButton = ({data}) => {
    const {openModal} = useModal()
    const {t} = useTranslation()
    const {processItem: racks} = useProcessingItems(config.processingSteps.STEP_05)

    const handleClick = () => {
        const filteredRacks = filterRacksByChainCode(racks, data.chainCode)
        openModal({
            title: t('processing.ripeningChamber.details', {chainCode: data.chainCode}),
            child: <ChainCodeRackList racks={filteredRacks}/>,
        })
    }

    return (
        <Button text={t('commons.details')} onClick={handleClick}/>
    )
};

export default RipeningChamberDetailButton;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {products: []}

export const productsSlice = createSlice({
    name: 'products',
    initialState: initialState,
    reducers: {
        setProducts: (state, action) => {
            // console.log('users', action.payload)
            state.products = action.payload
        },
    },
});

export const {setProducts} =
    productsSlice.actions;

export const productsSelector = state => state.products;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {getCTAFromStatus} from "../../utils";
import StatusIcon from "../../components/ui/StatusIcon";
import TankListAction from "../../components/molecules/TankListAction";
import moment from "moment";

const TranslateKey = ({translateKey}) => {
    const {t: pippo} = useTranslation()
    return (
      <>{pippo(translateKey)}</>
    )
}
export const historyColumns = [
    {
        name: <TranslateKey translateKey="history.fields.date"/> ,
        selector: row => moment(row.createdAt).format('DD/MM/YY HH:mm:ss'),
        sortable: true,
        reorder: true,
        hide: 'md'
    },
    {
        name: <TranslateKey translateKey="history.fields.origin"/> ,
        selector: row => row.originName,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="history.fields.truck"/> ,
        selector: row => row.plateId,
        sortable: true,
        reorder: true,
        hide: 'sm'
    },
    {
        name: <TranslateKey translateKey="history.fields.tank"/> ,
        selector: row => row.tankId,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="history.fields.amount"/> ,
        selector: row => row.quantity,
        sortable: true,
        reorder: true,
    },
    {
        selector: row => row.status,
        cell: row => {
            const cta = getCTAFromStatus(row.status, row.dispute)
            return (<TankListAction cta={cta} index={row.index} quantity={row.params.quantity} id={row.id}/>)
        },
        sortable: true,
        reorder: true,
        grow: 2,
    },
    {
        selector: row => row.status,
        cell: row => (<StatusIcon status={row.status} dispute={row.dispute}/>),
        sortable: true,
        reorder: true,
        hide: 'sm'
    },
];

import React, {useState} from 'react';
import TextArea from "../../../ui/TextArea";
import Text from "../../../ui/Text";
import VStack from "../../../ui/VStack";
import {theme} from "../../../../theme";
import {useTranslation} from "react-i18next";

const AddNoteSection = ({setNote, text}) => {
    const {t} = useTranslation()
    const [value, setValue] = useState('')

    const handleChange = (e) => {
        const updatedValue = e.target.value
        setValue(updatedValue)
        setNote(updatedValue)
    }

    return (
        <VStack space={theme.spacing["spacing-03"]}>
            <Text>{text}</Text>
            <TextArea id={'reason'} name={'reason'} handleChange={handleChange}
                      value={value} errors={''} placeholder={t('processing.ripeningChamber.notes.placeholder')}/>
        </VStack>
    );
};

export default AddNoteSection;

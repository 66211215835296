import React from 'react'
import TableContainer from "../../tab/TableContainer";
import DataTable from "../../DataTable";
import {chainCodeRackListColumns} from "../../../../data/tables/chainCodeRackListColumns";


const ChainCodeRackList = ({racks}) => {

    return (
        <TableContainer>
            <DataTable
                highlightOnHover={false}
                columns={chainCodeRackListColumns}
                data={racks}
            />
        </TableContainer>
    )
}

export default ChainCodeRackList

import {config} from "../../config";
import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import ProcessStepButton from "../../components/molecules/buttons/ProcessStepButton";
import ProcessPackagingModel from "../../models/addTabs/ProcessPackagingModel";
import PackagingModifiersSelector from "../../components/molecules/selects/PackagingModifiersSelector";
import {validatePackagingAdd} from "../../validators/processStepValidators";


export const packagingAddColumns = ({resetForm, onValueChange, itemId, handleSubmit}) => [
    {
        name: 'Identificativo',
        cell: row => row.chainCode,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Perse',
        cell: row => {
            return <ProcessStepInput data={row} type={config.input.WASTE} placeholder={'Quantità...'}
                                     updatevalue={onValueChange}
                                     disabled={false}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Confezione',
        cell: row => {
            return <PackagingModifiersSelector data={row} type={config.input.MODIFIERS} placeholder={'Quantità...'}
                                               updatevalue={onValueChange}/>
        },
        sortable: true,
        reorder: true,
    },
    {

        cell: row => {
            const options = ProcessPackagingModel(row, itemId)
            const isValid = validatePackagingAdd({options})

            const onSubmit = () => {
                handleSubmit()
            }
            
            return <ProcessStepButton options={options}
                                      text={'Confeziona'}
                                      disabled={!isValid}
                                      isValid={isValid}
                                      onSubmit={onSubmit}/>
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: true,
        reorder: true,
        width: '200px',
    },
];

import LoginForm from "../components/molecules/forms/LoginForm";
import AuthLayout from "../components/layout/AuthLayout";

const Login = () => {
    return (
        <AuthLayout>
            <LoginForm/>
        </AuthLayout>
    )
}

export default Login;

import React, {useEffect, useState} from 'react';
import {formatHistory} from "../../utils";
import {useHistory} from "../../contexts/HistoryProvider";
import HistoryContainer from "./history/HistoryContainer";
import HistoryTable from './history/HistoryTable';


const QualityAnalysis = ({user}) => {
    if(user.backendUrl.indexOf('sandbox') === -1 ){
        return <div style={{
            paddingTop: 32,
            paddingLeft: 64,
            paddingRight: 64,
        }}>
            <iframe style={{backgroundColor: '#F1F5F4', border: 'none',borderRadius: 2,boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',width: '93vw',height: '100vh'}}  src="https://charts.mongodb.com/charts-project-0-ezbkl/embed/dashboards?id=a79bdf40-31f8-47a5-be39-423ed38810bb&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=fixed&scalingHeight=scale"></iframe>
        </div>
    } else {
        return <div style={{
            paddingTop: 32,
            paddingLeft: 64,
            paddingRight: 64,
        }}>
            <iframe style={{backgroundColor: '#F1F5F4', border: 'none',borderRadius: 2,boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',width: '93vw',height: '100vh'}}  src="https://charts.mongodb.com/charts-project-0-ezbkl/embed/dashboards?id=b00b1ce6-41da-47d6-958c-ea94963acd5c&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=fixed&scalingHeight=scale"></iframe>
        </div>
    }
};

export default QualityAnalysis;

import Dropdown from "../../components/ui/Dropdown";
import ModifiersInput from "../../components/molecules/inputs/ModifiersInput";
import RemoveModifierListAction from "../../components/molecules/modals/polyvalent/modifiers/RemoveModifierListAction";

export const polyvalentModifiersColumns = [
    {
        name: 'Tipo',
        selector: row => <Dropdown dropdownType={'modifiers'} index={row.index}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Lotto',
        selector: row => <Dropdown dropdownType={'lotId'} index={row.index}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Quantità',
        cell: row => <ModifiersInput data={row}/>,
        sortable: true,
        reorder: true,
    },
    {
        selector: row => row.status,
        cell: row => <RemoveModifierListAction target="_blank" rel="noopener noreferrer" index={row.index}/>,
        sortable: true,
        reorder: true,
    },
];

import React from 'react';
import Text from "../components/ui/Text";
import Center from "../components/ui/Center";

const Missing = () => {
    return (
        <Center>
            <Text variant={'h0'}>404 Pagina non trovata</Text>
        </Center>
    );
};

export default Missing;

import {config} from "../../config";

const ProcessSeasoningRackModel = (data, itemId) => {
    return ({
        step: config.processingSteps.STEP_08,
        source: {
            chainCode: data.chainCodes[0],
        },
        destination: {
            itemId: itemId,
            loadAmount: data?.loadAmount || 0,
            waste: data?.waste || 0,
        },
    })
}

export default ProcessSeasoningRackModel

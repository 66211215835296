import React from 'react';
import ListAction from "../../../ui/ListAction";
import {useTranslation} from "react-i18next";
import jsPDF from "jspdf";
import moment from "moment";
import autoTable from "jspdf-autotable";
import {config} from "../../../../config";

const DownloadRackSheetCell = ({row}) => {
    const {t} = useTranslation()

    const handleClick = (e) => {
        const modifiers = row.modifiers
        const doc = new jsPDF('portrait');
        const columns = [{header: 'Data e ora trattamento', dataKey: 'appliedAt'}, {
            header: 'Categoria',
            dataKey: 'category'
        }, {header: 'Identificativo Lotto', dataKey: 'lotId'}, {header: 'Quantità', dataKey: 'amount'}]
        const data = modifiers.map(modifier => ({
            appliedAt: moment(row.appliedAt).format("YYYY-MM-DD HH:mm:ss"),
            category: t(`commons.modifiers.${modifier.category}`),
            lotId: modifier.category === config.modifiers.categories.SALT ? modifier.lotId : '-',
            amount: modifier.category === config.modifiers.categories.SALT ? `${modifier.amount}g` : '-',
        }))

        doc.setFontSize(12)
        doc.text(`Lista trattamenti`, 15, 20)
        doc.text(`Forme totali: ${row.amount}`, 60, 20)
        doc.text(`Forme perse: ${row.waste}`, 110, 20)
        autoTable(doc, {html: '#my-table'})
        autoTable(doc, {
            columns: columns,
            body: data,
        })
        doc.save(`foglio_rack_lotto_${row.chainCode}.pdf`);
    }

    return (
        <ListAction onClick={handleClick} text={'Scarica PDF'} style={''}/>
    );
};

export default DownloadRackSheetCell;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { useModal } from '../../../contexts/ModalContext';
import { useProducts } from '../../../contexts/ProductsProvider';
import { FormProvider } from '../../../hooks/useForm';
import { theme } from '../../../theme';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input';
import Text from '../../ui/Text';
import { nameValidator, descriptionValidator, codeValidator} from '../../../validators/Product';

const ProductForm = ({ item }) => {
  const { t } = useTranslation();
  const [isNew, setIsNew] = useState(!item);
  const [loading, setLoading] = useState(false);
  const {closeModal} = useModal()
  const {add, products} = useProducts()

  const { register, handleSubmit,  formState: {errors}} = useForm({
    mode:'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      name: item?.name || '',
      type: item?.type || config.productType.DESTINATION,
      code: item?.code || '',
      description: item?.description || '',
      status: item?.status || config.productStatus.AVAILABLE,
    }
  });


  const onSubmit = data => {
    add(data).catch(console.error).finally(() => {
       closeModal()
    })
  };

  useEffect(() => {
    setIsNew(!item);
    return () => {
      setIsNew(false);
    };
  }, []);

  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('products.common.name')}</Text>
            <Input style={ errors?.name ? styles.erroredInput: {}} placeholder={t('products.common.name')} type={'text'} {...register('name', nameValidator)} />
            {errors?.name && <Text style={styles.error} variant={'p1'}>{t(errors.name.message)}</Text>}
          </div>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('products.common.code')}</Text>
            <Input style={ errors?.code ? styles.erroredInput: {}} placeholder={t('products.common.code')} type={'text'} {...register('code', codeValidator(products))}/>
            {errors?.code && <Text style={styles.error} variant={'p2'}>{t(errors.code.message)}</Text>}
          </div>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('products.common.type')}</Text>
            <select style={styles.select} {...register('type')}>
              {[config.productType.SOURCE, config.productType.DESTINATION].map((option, i) => {
                const label = 'products.common.' + option;
                return (
                  <option key={i} defaultValue={t(label)} value={option}>{t(label)}</option>
                );
              })}
            </select>
          </div>
          <div style={{...styles.inputContainer, display:isNew?'none':'block'}}>
           <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('products.common.availability')}</Text>
           <select style={styles.select} {...register('status')}>
             {[config.productStatus.AVAILABLE, config.productStatus.UNAVAILABLE].map((option, i) => {
               const label = 'products.common.' + option;
               return (
                 <option key={i} defaultValue={t(label)} value={option}>{t(label)}</option>
               );
             })}
           </select>
          </div>
        </div>
        <div style={styles.inputContainer}>
          <Text  style={styles.label} variant={'h5'} alignText={'left'}>{t('products.common.description')}</Text>
          <textarea  {...register('description', descriptionValidator)} placeholder={t('products.common.description')}  style={styles.textArea}/>
        </div>
        <div style={styles.buttonContainer}>
          {!isNew && <Input value={item.id} type={'hidden'} {...register('id')}/>}
          <Button text={t('products.common.confirm')} type={'submit'} disabled={loading}/>
        </div>
      </form>
    </FormProvider>
  );
}

const styles = {
  columnHeader: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['spacing-04'],
  },
  newItem: { cursor: 'pointer' },
  select: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.colors.lightGrey,
    borderRadius: 10,
    paddingLeft: theme.spacing["spacing-05"],
    paddingRight: theme.spacing["spacing-05"],
    paddingTop: theme.spacing["spacing-05"],
    paddingBottom: theme.spacing["spacing-05"],
    ...theme.components.Text.variants.h5,
  },
  label: {
    marginBottom: theme.spacing['spacing-04']
  },
  textArea: {
    ...theme.components.Text.variants.default_text,
    padding: theme.spacing["spacing-05"],
    outline: 'none',
    borderRadius: 10,
    resize: 'none',
    borderColor: theme.colors.lightGrey,
    width:'90%',
  },
  formContainer: {
    display: 'flex',
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    marginTop: theme.spacing['spacing-08'],

  },
  inputContainer: {
    margin: theme.spacing['spacing-04']
  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-08']
  },
  error: {
    color: theme.colors.error
  },
  erroredInput: {
    color: theme.colors.error,
    borderColor: theme.colors.error
  }
};

export default ProductForm;

import React, {useState} from 'react';
import { useModal } from '../../../contexts/ModalContext';
import { useTrucks } from '../../../contexts/TrucksProvider';
import { truckColumns } from '../../../data/tables/truckColumns';
import DataTable from "../../molecules/DataTable";
import {useTranslation} from "react-i18next";
import TruckForm from '../../molecules/forms/TruckForm';
import Button from '../../ui/Button/Button';
import { config } from '../../../config';

const TrucksTable = ({tableData}) => {
    const {t} = useTranslation()
    const {openModal} = useModal()

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const {enable, disable} = useTrucks()

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);


    const addTruck = (data) => {
        openModal({
            title: data.plateId ? t('trucks.editModal.title') : t('trucks.addModal.title'),
            child: <TruckForm item={data.plateId? data : null}/>,
        })
    }

    const editStatus = (data) => {
        if(![config.truckStatus.AVAILABLE, config.truckStatus.UNAVAILABLE].includes(data.status)){
            return;
        }
        if(data.status === config.truckStatus.AVAILABLE) {
            disable(data.plateId);
        } else {
            enable(data.plateId);
        }
    }

    return (
        <DataTable
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            title={<div style={styles.titleRoot}>
                <span>{t('trucks.title')}</span>
                <Button text={t('trucks.addButton')} onClick={addTruck}/>
            </div>}
            columns={truckColumns(addTruck, editStatus)}
            data={tableData}
        />
    );
};

const styles = {
    titleRoot:{
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
}
export default TrucksTable;

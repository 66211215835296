import useSelectedSeasoner from "../../../../hooks/useSelectedSeasoner";
import {useProcessingItems} from "../../../../contexts/ProcessingItemsContext";
import {useSocketEmit} from "../../../../hooks/useSocketEmit";
import {useModal} from "../../../../contexts/ModalContext";
import {useTranslation} from "react-i18next";
import ChangeSeasonerModel from "../../../../models/ChangeSeasonerModel";
import Text from "../../../ui/Text";
import SeasonerSelector from "../../buttons/SeasonerSelector";
import TableInput from "../../../ui/TableInput";
import Button from "../../../ui/Button/Button";
import {validateChangeSeasoner} from "../../../../validators/processStepValidators";
import {useEffect, useState} from "react";

const ChangeSeasonerModal = ({data}) => {
    const {seasonerForm} = useSelectedSeasoner()
    const {refreshProcessingItems} = useProcessingItems()
    const {processStep} = useSocketEmit()

    const {closeModal} = useModal()
    const {t} = useTranslation()

    const [weight, setWeight] = useState(data.params?.weight)
    const [waste, setWaste] = useState()

    const [options, setOptions] = useState(null)

    useEffect(() => {
        const opt = ChangeSeasonerModel({
            seasonerForm,
            chainCode: data.chainCodes[0],
            itemId: data.itemId,
            weight,
            waste
        })
        setOptions(opt)
    }, [weight, waste, seasonerForm])

    const handleClick = () => {
        console.log('data;', data)
        console.log('options', options)
        processStep(options).catch(console.error).finally(() => {
            refreshProcessingItems()
            closeModal()
        })
    }

    return (

        <div style={{
            paddingTop: 20,
            minHeight: 300,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
        }}>
            <Text variant={'h3'}>Seleziona stagionatore</Text>
            <SeasonerSelector data={data} filterExternal={true}/>
            <Text variant={'h3'}>Forme perse</Text>
            <TableInput placeholder={'Forme perse...'}
                        onChange={e => setWaste([{itemId: data.sourceItemIds[0], amount: parseInt(e.target?.value)}])}/>
            <Text variant={'h3'}>Peso (Kg)</Text>
            <TableInput placeholder={'Peso (Kg)...'} onChange={e => setWeight(e.target?.value)}
                        value={weight}/>
            <Button text={t('processing.seasoning.changeSeasonerCTA')} onClick={handleClick}
                    disabled={validateChangeSeasoner(options)}/>
        </div>

    )
}

export default ChangeSeasonerModal

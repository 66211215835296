import ProcessingItemForm from '../components/molecules/forms/ProcessingItemFrom';
import { getCallbackByStep, getProcessingItemFormByStep } from '../utils';

const ProcessingItemModel = (step, data, handleCloseModal) => {
    return {
        title: data?.name,
        child: <ProcessingItemForm item={data} step={step}/>,
        closeCallback: handleCloseModal || getCallbackByStep(step)
    }
};

export default ProcessingItemModel;

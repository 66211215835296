import Text from '../components/ui/Text';

export const nameValidator = {
  required: true,
  minLength: { value: 3, message: 'products.common.errors.minLength' },
  maxLength: { value: 20, message: 'products.common.errors.maxLength' },
};
export const descriptionValidator = {
  required: false,
  max: 80,
  min: 3,
  maxLength: 80,
};

export function codeValidator(products) {
  return {
    required: true,
    minLength: { value: 3, message: 'products.common.errors.minLength' },
    maxLength: { value: 15, message: 'products.common.errors.maxLength' },
    validate: value => {return !products.find(p=>p.code===value) ? true : 'products.common.errors.codeUsed'}
  };
}




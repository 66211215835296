import OpenSeasoningRackDetailButton from "../../components/molecules/buttons/OpenSeasoningRackDetailButton";

export const seasoningRacksTabColumns = [
    {
        name: 'Rack Fisico',
        selector: row => {
            return row.extraData.containerId

        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Porzione Rack',
        selector: row => {
            return row.name
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Identificativo',
        selector: row => {
            return row.blends[0]?.chainCodes[0]
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Capacità',
        selector: row => row.capacity,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme',
        selector: row => {
            return row.amount
        },
        sortable: true,
        reorder: true,
    },
    {
        cell: row => {
            return <OpenSeasoningRackDetailButton data={row}/>
        },
    }
]

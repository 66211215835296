import React from 'react';
import DataTable from "../../DataTable";
import {useDispatch} from "react-redux";
import {setSeasoningWaste} from "../../../../store/seasoning/seasoningWaste";
import {addSeasoningWasteColumns} from "../../../../data/tables/addSeasoningWasteColumns";

const AddWasteModal = ({chainCodeItem}) => {
    const dispatch = useDispatch()

    const handleAddSeasoningWaste = (wasteObj) => {
        dispatch(setSeasoningWaste(wasteObj))
    }

    return (
        <DataTable
            highlightOnHover={false}
            columns={addSeasoningWasteColumns(chainCodeItem.chainCodes[0], handleAddSeasoningWaste)}
            data={chainCodeItem.rackInfo}
        />
    );
};

export default AddWasteModal;

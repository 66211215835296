export const chainCodeRackListColumns = [
    {
        name: 'Rack',
        selector: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Identificativo',
        selector: row => {
            return row.blends[0]?.chainCodes[0]
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Peso (Kg)',
        selector: row => {
            const weight = row.blends[0]?.params?.weight
            return weight ? weight + ' Kg' : ''

        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme',
        selector: row => {
            return row.amount
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Capacità',
        selector: row => row.capacity,
        sortable: true,
        reorder: true,
    },
];

import React from 'react';
import {config} from "../../../config";
import RackCard from "../cards/RackCard";
import ProcessingItemCard from "../cards/ProcessingItemCard";
import SeasoningRackCard from "../cards/SeasoningRackCard";

/**
 * this factory is necessary to isolate the rack card from the rest of the cards
 * its behavior it's different from any other processing items since we're grouping
 * it for racks and not showing single cards
 * @param processingItem
 * @returns {JSX.Element}
 * @constructor
 */
const ProcessorCardFactory = ({processingItem}) => {
    if (processingItem.step === config.processingSteps.STEP_05)
        return <RackCard processingItem={processingItem}/>

    if (processingItem.step === config.processingSteps.STEP_03)
        return <ProcessingItemCard processingItem={processingItem} useStatus={true}/>

    if (processingItem.step === config.processingSteps.STEP_04 || processingItem.step === config.processingSteps.STEP_06 || processingItem.step === config.processingSteps.STEP_07)
        return <ProcessingItemCard processingItem={processingItem} useStatus={true}/>

    if (processingItem.step === config.processingSteps.STEP_08) {
        return <SeasoningRackCard processingItem={processingItem}/>
    }
    if (processingItem.step === config.processingSteps.STEP_09) {
        return <ProcessingItemCard processingItem={processingItem} useStatus={true}/>
    }

    return <ProcessingItemCard processingItem={processingItem}/>;
};

export default ProcessorCardFactory;

import React from 'react';
import ProfileIcon from "../../ui/ProfileIcon/ProfileIcon";
import {useNavigate} from "react-router-dom";
import {config} from "../../../config";

const Profile = ({hasProfile}) => {
    const navigate = useNavigate()

    const handleProfileClick = () => {
        navigate(config.routes.PROFILE)
    }

    return (
        <>
            {hasProfile &&
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <ProfileIcon onClick={handleProfileClick}/>
                </div>}
        </>
    );
};

export default Profile;

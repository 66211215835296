import {config} from "../config";

export const DisputeModel = (data, users) => {
    if (!data.dispute?.messages) return
    const disputeMessages = data.dispute?.messages?.flatMap(message => {
        const found = users.find(user => user.id === message.senderId)
        if (!found) return []
        return {...message, ...found, isProcessor: found.role === config.roles.PROCESSOR}
    })

    const getProposalData = () => {
        const found = disputeMessages.findLast((message) => message?.amount !== null)
        if (found) {
            return {suggestedAmount: found.amount, role: found.role}
        }

        return null
    }

    const disputeModel = {
        messages: disputeMessages,
        proposal: getProposalData(),
        originalAmount: data.dispute?.originalAmount || 0,
        amount: data.params.quantity,
        id: data.id
    }

    return disputeModel
}

import React from 'react';
import DataTable from "../../DataTable";
import TableContainer from "../../tab/TableContainer";
import useProcessingItems from "../../../../hooks/useProcessingItems";
import {config} from "../../../../config";
import {seasoningRacksTabColumns} from "../../../../data/tables/seasoningRacksTabColumns";

const SeasoningRacks = () => {
    const {processItem: seasoningRacks, loading} = useProcessingItems(config.processingSteps.STEP_08)

    return (
        <TableContainer>
            <DataTable
                subHeader={true}
                // subHeaderComponent={subHeaderComponentMemo}
                highlightOnHover={false}
                columns={seasoningRacksTabColumns}
                data={seasoningRacks}
            />
        </TableContainer>
    );
};

export default SeasoningRacks;

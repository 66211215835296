import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import { productsSelector } from '../../../store/products/productsSlice';
import Button from '../../ui/Button';
import Input from '../../ui/Input';
import {Controller, useForm} from 'react-hook-form';
import Text from '../../ui/Text';
import {theme} from '../../../theme';
import {config} from '../../../config'
import {InfinitySpin} from 'react-loader-spinner'
import {ErrorMessage} from '@hookform/error-message';
import {userSelector} from '../../../store/user/userSlice';
import Product from './Product';

const Create = ({onCreated, onError}) => {
    const {register, control, watch, handleSubmit, formState: {errors}} = useForm({});
    const {t} = useTranslation();
    const [role, setRole] = useState('origin');
    const allProducts = useSelector(productsSelector).products;
    const [productIds, setProductIds] = useState(allProducts.map(p=>p.id));
    const [processing, setProcessing] = useState(false);
    const [result, setResult] = useState(null);
    const watchName = watch('name')
    const watchEmail = watch('email')
    const watchLon = watch('lon')
    const watchLat = watch('lat')
    const watchFarmArea = watch('farmArea')
    const watchHeadNumber = watch('headNumber')
    const user = useSelector(userSelector)


    const onClick = async () => {
        setProcessing(true);
        setResult(null);
        try {
            const jwt = user.auth.accessToken
            const responseID = await fetch(process.env.REACT_APP_API_URL + "user",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt,
                        'app-id': process.env.REACT_APP_APP_ID
                    },
                    body: JSON.stringify({
                        "email": watchEmail,
                        "password": 'cdlhf9c8' + Math.random() * 100000,
                        "role": role,
                        "name": watchName,
                        "userData": {backendUrl: user.backendUrl}
                    })
                }
            )

            if (!responseID.ok) {
                throw new Error(t('routes.users.errors.generic') + responseID.statusText);
            }

            if([config.roles.EXTERNAL_SEASONER, config.roles.INTERNAL_SEASONER].includes(role)){
                setProcessing(false)
                setResult({name:watchName, email:watchEmail})
                onCreated({name:watchName, email:watchEmail});
                return;
            }

            const bodyID = await responseID.json();
            console.log('responseID - ', bodyID)

            const extraData = {location: {lat: parseFloat(watchLat), lon: parseFloat(watchLon)}};
            if (role === 'origin') {
                extraData.products = productIds;
                extraData.farmArea = watchFarmArea;
                extraData.headNumber = watchHeadNumber;
            }


            const beUrl = user.backendUrl.substring(0, user.backendUrl.lastIndexOf('/')) + "/user/register"
            const responseBE = await fetch(beUrl,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt,
                        'app-id': process.env.REACT_APP_APP_ID
                    },
                    body: JSON.stringify({
                        "email": watchEmail,
                        "role": role,
                        "name": watchName,
                        "id": bodyID.user.id,
                        "extraData": extraData,
                    })
                }
            )


            if (!responseBE.ok) {
                throw new Error(t('routes.users.errors.generic'));
            }
            console.log('responseBE - ', await responseBE.json())
            setResult({name:watchName, email:watchEmail})
            onCreated({name:watchName, email:watchEmail});
        } catch (error) {
            onError(error.message)
        }

        setProcessing(false)

    }

    const handleChangeRole = e => {
        setRole(e.target.value)
        setResult(null)
    }

    const buildProductIds = id => {
        let prods = [...productIds];
        if (prods.includes(id)) {
            prods = prods.filter(p => p !== id);
        } else {
            prods.push(id)
        }

        setProductIds(prods);
    }

    const getErrorType = () => {
        if (errors.name) {
            return 'name';
        }
        if (errors.email) {
            return 'email';
        }
        if (errors.lat) {
            return 'lat';
        }
        if (errors.lon) {
            return 'lon';
        }
        if (errors.headNumber) {
            return 'headNumber';
        }
        if (errors.farmArea) {
            return 'farmArea';
        }
        const productNames = allProducts.map(p=>p.name);
        const error = Object.keys(errors).find(e => productNames.includes(e));
        if (error) {
            return error;
        }
        return null;
    };

    return (
      <div style={{
          textAlign: 'center',
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
      }}>
            <Text variant={'h2'}>{t('routes.users.createTitle')}</Text>
            <form style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={styles.fieldContainer}>
                    <Input style={styles.name}
                           disabled={processing}
                           type="text"
                           onKeyDown={()=>setResult(null)}
                           placeholder={t('routes.users.namePlaceholder')}
                           {...register("name", {
                               required: t('routes.users.errors.nameEmpty'),
                               minLength: {value: 3, message: t('routes.users.errors.nameTooShort')},
                           })}
                    />
                    <Input style={styles.email}
                           disabled={processing}
                           type="email"
                           onKeyDown={()=>setResult(null)}
                           placeholder={t('routes.users.emailPlaceholder')}
                           {...register("email", {
                               required: t('routes.users.errors.emailEmpty'),
                               pattern: {
                                   value: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
                                   message: t('routes.users.errors.invalidEmail')
                               },
                           })
                           }
                    />
                </div>
                {role===config.roles.ORIGIN && <>
                    <div style={styles.fieldContainer}>
                        <Input
                          style={styles.leftField}
                          disabled={processing}
                           type="text"
                           onKeyDown={()=>setResult(null)}
                           placeholder={t('routes.users.latitudePlaceholder')}
                           {...register("lat", {
                               required: t('routes.users.errors.latitudeEmpty'),
                               pattern: {
                                   value: /^\d+\.\d+$/,
                                   message: t('routes.users.errors.invalidLatitude')
                               },
                           })}
                        />
                        <Input disabled={processing}
                           type="text"
                           onKeyDown={()=>setResult(null)}
                           placeholder={t('routes.users.longitudePlaceholder')}
                           {...register("lon", {
                               required: t('routes.users.errors.longitudeEmpty'),
                               pattern: {
                                   value: /^\d+\.\d+$/,
                                   message: t('routes.users.errors.invalidLongitude')
                               },
                           })}
                        />
                    </div>
                    <div style={styles.fieldContainer}>
                        <Input
                          style={styles.leftField}
                          disabled={processing}
                          type="text"
                          onKeyDown={()=>setResult(null)}
                          placeholder={t('routes.users.farmAreaPlaceholder')}
                          {...register("farmArea", {
                              required: t('routes.users.errors.farmAreaEmpty'),
                              pattern: {
                                  value: /^\d+\.?\d*$/,
                                  message: t('routes.users.errors.invalidFarmArea')
                              },
                          })}
                        />
                        <Input disabled={processing}
                               type="number"
                               onKeyDown={()=>setResult(null)}
                               placeholder={t('routes.users.headNumber')}
                               {...register("headNumber", {
                                   required: t('routes.users.errors.headNumberEmpty'),
                                   pattern: {
                                       value: /^\d+$/,
                                       message: t('routes.users.errors.invalidHeadNumber')
                                   },
                               })}
                        />
                    </div>
                </>}
                <select disabled={processing} style={styles.role}
                        onChange={handleChangeRole}
                        value={role}
                >
                    <option key={1} value={config.roles.ORIGIN}>{config.roles.ORIGIN}</option>
                    <option key={2} value={config.roles.CARRIER}>{config.roles.CARRIER}</option>
                    <option key={3} value={config.roles.EXTERNAL_SEASONER}>{config.roles.EXTERNAL_SEASONER}</option>
                    <option key={4} value={config.roles.INTERNAL_SEASONER}>{config.roles.INTERNAL_SEASONER}</option>
                </select>
                {role === 'origin' &&
                    <div style={styles.productList}>
                        {allProducts.map(p=><Product
                          key={p.id}
                          product={p}
                          control={control}
                          buildProductIds={buildProductIds}
                          productIds={productIds}/>)}
                    </div>
                }

                <ErrorMessage
                    errors={errors}
                    name={getErrorType()}
                    render={({message}) => <Text variant={'p1'} color={theme.colors.warning}
                                                 style={{marginRight: theme.spacing['spacing-03'], marginBottom:theme.spacing['spacing-03'] }}>{message}</Text>}
                />


                <Button disabled={processing} stylex={{width:311, height:46, paddingBottom: theme.spacing['spacing-02'], paddingTop: theme.spacing['spacing-02']}} type={'submit'}
                        onClick={handleSubmit(d => onClick(d))} text={t('routes.users.registerButton')}/>
            </form>


            {processing &&
                <InfinitySpin
                    width='200'
                    color="#4fa94d"
                />}
            {result && <Text variant='default_text' color={theme.colors.success}
                             >{t('routes.users.resultMessage', {name:result.name, email:result.email})}</Text>}

        </div>
    );
}

const styles = {
    name: {
        marginRight: theme.spacing['spacing-05'],
    },
    leftField: {
        marginRight: theme.spacing['spacing-05'],
    },
    fieldContainer: {
        height: theme.spacing['spacing-09'],
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing['spacing-05'],
    },
    role: {
        borderWidth: 1,
        border: 'solid',
        borderColor: theme.colors.lightGrey,
        borderRadius: 10,
        ...theme.components.Text.variants.default_text,
        width: '100%',
        display: 'flex',
        outline: 'none',
        color: theme.colors.black,
        backgroundColor: theme.colors.white,
        marginBottom: theme.spacing['spacing-05'],
        paddingLeft: theme.spacing["spacing-05"],
        paddingRight: theme.spacing["spacing-05"],
        height: theme.spacing['spacing-09'],
    },
    productList: {
        width: '100%'
    },
    product: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: theme.spacing['spacing-05']
    }
}

export default Create;

import React, { useCallback, useMemo, useState } from 'react';
import {useTranslation} from 'react-i18next';
import { config } from '../../../config';
import { theme } from '../../../theme';
import Button from '../../ui/Button';
import Text from '../../ui/Text';
import { InfinitySpin, LineWave } from 'react-loader-spinner';

const Details = ({user, onEdit, onDisable}) => {
    const {t} = useTranslation();

    const disabled = useMemo(()=>{
        return user.status !== config.userStatus.ACTIVE || user.blockchainStatus !== config.blockchainSyncStatuses.SYNCHRONIZED
    }, [user.status, user.blockchainStatus])
    return (
      <>
          {user &&
          <div style={{...styles.root, ...{opacity: disabled ? 0.6 : 1}}}>
              {user.blockchainStatus !== config.blockchainSyncStatuses.SYNCHRONIZED && <div style={{flex:4, display: 'flex',
                  alignItems: 'center',
                  textAlign:'left',
                  flexDirection: 'row',
              }}>
                  <LineWave
                    height="50"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="line-wave"
                    wrapperStyle={{paddingTop:0,marginLeft:-10, paddingBottom:15}}
                    visible={true}
                  />
                  <Text variant={'h4'} style={{textAlign:'left'}}>{user.name}</Text>
              </div>}
              {user.blockchainStatus === config.blockchainSyncStatuses.SYNCHRONIZED && <Text variant={'h4'} style={{textAlign:'left', flex:4}}>{user.name}</Text>}
              <Text variant={'h4'} style={{textAlign:'left', flex:2}} >{user.role}</Text>
              <div  style={{display:'flex', textAlign:'right', flex:3, flexDirection:'row'}}>
                  {user.status !== config.userStatus.DISABLED && <>
                      <Button disabled={disabled} variant={'h5'} stylex={{marginRight:theme.spacing['spacing-02']}}  text={t('routes.users.details.editButton')} onClick={()=>onEdit(user)} />
                      <Button disabled={disabled} variant={'h5'} stylex={{}}  text={t('routes.users.details.disableButton')} onClick={()=>onDisable(user.id)} />
                  </>}
              </div>
          </div>
          }
      </>
    );
};

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width:'100%',
        borderBottom:'1px solid ' +theme.colors.lightGrey,
        padding:theme.spacing['spacing-04'],
        marginBottom: theme.spacing['spacing-02'],

    }
}

export default Details;

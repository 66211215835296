export const inputValidator = {
  required: true,

  pattern:{
    value: /^\d+(\.\d+)?$/,
    message: 'analytics.common.errors.enterNumber',
  },
};




import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import TableInput from "../../ui/TableInput";
import {useSelector} from "react-redux";
import {seasoningWasteSelector} from "../../../store/seasoning/seasoningWaste";
import {updateRipeningChamberWaste} from "../../../utils";

const SeasoningWasteInput = ({maxAmount, rackId, handleAddSeasoningWaste}) => {
    const {t} = useTranslation()
    const waste = useSelector(seasoningWasteSelector)
    const [value, setValue] = useState('')

    useEffect(() => {
        const value = waste.find(item => item.itemId === rackId)
        if (value) {
            setValue(value.amount)
        }
    }, [waste])

    const handleChange = e => {
        const newWaste = e.target.value > maxAmount ? maxAmount : parseInt(e.target.value)
        setValue(newWaste.toString())
        const updatedWaste = updateRipeningChamberWaste(waste, newWaste, rackId)
        handleAddSeasoningWaste(updatedWaste)
    }

    return (
        <TableInput placeholder={t('commons.wastePlaceholder')} value={value} type={'number'}
                    onChange={handleChange}/>
    );
};

export default SeasoningWasteInput;

import {createContext, useContext, useState} from "react";

export const ActiveTabContext = createContext(0);

export const useActiveTab = () => useContext(ActiveTabContext)

export const ActiveTabProvider = ({children}) => {
    const [activeTab, setActiveTab] = useState();

    return (
        <ActiveTabContext.Provider value={{activeTab, setActiveTab}}>
            {children}
        </ActiveTabContext.Provider>
    )
};

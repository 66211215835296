import React from 'react';
import {ProfileIconProps} from "./types";
import {ProfileIconContainer, ProfileIconContent} from "./styles";
import profileIcon from '../../../assets/icons/ic-profile.svg'

const ProfileIcon = ({onClick}: ProfileIconProps) => {
    return (
        <ProfileIconContainer onClick={onClick}>
            <ProfileIconContent src={profileIcon} alt={'profile-icon'}/>
        </ProfileIconContainer>
    );
};

export default ProfileIcon;

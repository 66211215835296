import React from 'react';
import {useModal} from "../../../contexts/ModalContext";
import {useTranslation} from "react-i18next";
import {config} from "../../../config";
import SeasoningRack from "../modals/seasoningRack/SeasoningRack";
import Button from "../../ui/Button";
import {FormProvider} from "../../../hooks/useForm";

const OpenSeasoningRackDetailButton = ({data}) => {
    const {openModal} = useModal()
    const {t} = useTranslation()

    const handleClick = () => {
        openModal({
            title: `${data.name} • ${data.capacity} Forme`,
            child: <FormProvider><SeasoningRack id={data.id}/></FormProvider>,
        })
    }

    return (
        <Button text={t('processing.rack.buttons.openRackDetailButton')} onClick={handleClick}
                disabled={data.processStatus === config.processStatus.CLOSED}/>
    )
};

export default OpenSeasoningRackDetailButton;

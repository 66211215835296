import React from 'react';
import {useSocketEmit} from "../../../hooks/useSocketEmit";
import Button from "../../ui/Button/Button";
import {useTranslation} from "react-i18next";
import {useModal} from "../../../contexts/ModalContext";
import {useProcessingItems} from "../../../contexts/ProcessingItemsContext";

const ProcessStepButton = ({options, disabled, text, isValid = true, onSubmit}) => {
    const {processStep} = useSocketEmit()
    const {t} = useTranslation()
    const {updateLoadAmount} = useModal()
    const {refreshProcessingItems} = useProcessingItems()

    const handleClick = () => {
        if (isValid) {
            console.log(options)
            processStep(options).then((res) => {
                console.log('close', res)
                updateLoadAmount(res.item.amount)
                refreshProcessingItems()
                onSubmit()
            }).catch((e) => {
                alert(t('errors.api.generic'))
                console.log('error', e)
            })
        } else {
            alert(t('errors.api.maxAmount'))
        }

    }

    return (
        <Button onClick={handleClick} type={'submit'} text={text ?? t('components.processStepButton.add')}
                disabled={disabled}/>
    );
};

export default ProcessStepButton;

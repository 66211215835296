import {createContext, useEffect, useRef, useState} from "react";
import socketIOClient from 'socket.io-client';
import {useDispatch, useSelector} from "react-redux";
import {logout, userSelector} from "../store/user/userSlice";
import moment from "moment";
import {setUsers} from "../store/users/usersSlice";
import {setProducts} from "../store/products/productsSlice";
import {setProcessItems} from "../store/processItems/processItemsSlice";
import {config} from "../config";
import {setProcessableProducts} from "../store/processableProducts/processableProductsSlice";

export const SocketContext = createContext({socket: null});

const SocketProvider = ({children}) => {
    const user = useSelector(userSelector)
    const accessToken = user.auth?.accessToken
    const dispatch = useDispatch()
    const [socketReady, setSocketReady] = useState(false)

    const client = socketIOClient(user.backendUrl, {
        transports: ['websocket'],
        query: {
            accessToken: accessToken,
            version: 1, // optional chat major version supported by clients;
            platform: 'web', // optional string defining client platform;
            appVersion: 1, // optional client app major version;
            lang: 'it' // optional default on server IT
        }
    })

    const socket = useRef(client)

    // useEffect(() => {
    //     console.log('socket', socket?.current)
    //     if (!socket?.current?.connected) {
    //         setSocketReady(false)
    //         // dispatch(logout())
    //     }
    // }, [socket])

    useEffect(() => {
        if (!accessToken) {
            socket.current.removeAllListeners();
            socket.current.close();
        }
    }, [accessToken])

    useEffect(() => {
        socket.current.on(config.events.CONNECTED, (res) => {
            console.log('[connected]', res)
            if (res.users)
                dispatch(setUsers(res.users))
            if (res.products?.source)
                dispatch(setProducts(res.products.source))
            if (res.products?.destination)
                dispatch(setProcessableProducts(res.products.destination))
            if (res.processingItems)
                dispatch(setProcessItems(res.processingItems))
            setSocketReady(true)
            console.log(moment().format('MMMM Do YYYY, h:mm:ss a'), 'SocketIO: Connected and authenticated');
        });

        socket.current.on(config.events.CLOSED, () => {
            console.log('disconnected')
            setSocketReady(false)
        })

        socket.current.on(config.events.ERROR, (msg) => {
            console.error('SocketIO: Error', msg);
        });

        socket.current.on(config.events.CONNECT_ERROR, (msg) => {
            console.error('SocketIO: Connect Error', msg);
            dispatch(logout())
        });
    }, [dispatch]);

    return (
        <SocketContext.Provider
            value={{socket: socket.current, socketReady: socketReady}}>{children}</SocketContext.Provider>
    );
};

export default SocketProvider;


import { config } from '../config';

export function nameValidator(step) {
  return{
    required: true,
      minLength:{value: 3, message: 'processing.common.errors.minLength'},
      maxLength: {value: 20, message:'processing.common.errors.maxLength'},
  }
};

export function descriptionValidator(step){
  const notRequiredOnStep = []
  return {
    required: notRequiredOnStep.includes(step),
    max: 80,
    min: 3,
    maxLength: 80,
  };
}

export function capacityValidator(step) {
  const notRequiredOnStep = [config.processingSteps.STEP_04, config.processingSteps.STEP_06, config.processingSteps.STEP_07, config.processingSteps.STEP_09]
  return {
    required: !notRequiredOnStep.includes(step),
    valueAsNumber: true,
  };
}

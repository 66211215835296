import React from 'react';
import Text from "../components/ui/Text";
import Center from "../components/ui/Center";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import VStack from "../components/ui/VStack";
import {logout} from "../store/user/userSlice";
import {theme} from "../theme";

const Profile = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <Center>
            <VStack style={{alignItems: 'center'}} space={theme.spacing["spacing-08"]}>
                <Text variant={'h0'}>Profile page</Text>
                <div onClick={() => navigate(-1)}>
                    <Text variant='default_text' color={theme.colors.primary}
                          style={{cursor: 'pointer'}}>{'Indietro'}</Text>
                </div>
                <div onClick={() => dispatch(logout())}>
                    <Text variant='default_text' color={theme.colors.primary}
                          style={{cursor: 'pointer'}}>{t('routes.profile.logout')}</Text>
                </div>
            </VStack>
        </Center>
    );
};

export default Profile;

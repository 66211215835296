import React, {useState} from 'react';
import { useModal } from '../../../contexts/ModalContext';
import {productColumns} from "../../../data/tables/productColumns";
import DataTable from "../../molecules/DataTable";
import {useTranslation} from "react-i18next";
import ProductForm from '../../molecules/forms/ProductForm';
import Button from '../../ui/Button/Button';


const ProductsTable = ({tableData}) => {
    const {t} = useTranslation()
    const {openModal} = useModal()

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);


    const addProduct = (data) => {
        openModal({
            title: data.id ? t('products.editModal.title') : t('products.addModal.title'),
            child: <ProductForm item={data.id? data : null}/>,
        })
    }

    return (
        <DataTable
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            title={<div style={styles.titleRoot}>
                <span>{t('products.title')}</span>
                <Button text={t('products.addButton')} onClick={addProduct}/>
            </div>}
            columns={productColumns(addProduct)}
            data={tableData}
        />
    );
};

const styles = {
    titleRoot:{
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
}
export default ProductsTable;

import React from 'react';
import {addWasteColumns} from "../../../../data/tables/addWasteColumns";
import DataTable from "../../DataTable";
import {WasteSlice} from "../../../../interfaces/WasteSlice";

declare type WasteSectionProps = {
    chainCode: string
    setWaste: React.Dispatch<React.SetStateAction<WasteSlice[]>>
    data: WasteSlice[]
}

const WasteSection = ({chainCode, setWaste, data}: WasteSectionProps) => {
    return (
        <DataTable
            highlightOnHover={false}
            columns={addWasteColumns(chainCode, setWaste)}
            data={data}
        />
    );
};

export default WasteSection;

import React from 'react';
import Button from '../../components/ui/Button/Button';
import {useTranslation} from "react-i18next";
import { config } from '../../config';

const TranslateKey = ({translateKey}) => {
    const {t: pippo} = useTranslation()
    return (
        <>{pippo(translateKey)}</>
    )
}

export function modifierColumns (edit) { return [
    {
        name: <TranslateKey translateKey="modifiers.common.name"/>,
        selector: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="modifiers.common.amountPlaceholder"/>,
        selector: row => row.amount===1 && row.type === config.modifierType.PERMANENT ? '-' : row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="modifiers.common.lotId"/>,
        selector: row => row.lotId || '-',
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="modifiers.common.status"/>,
        selector: row => <TranslateKey translateKey={"modifiers.common."+row.status}/>,
        sortable: true,
        reorder: true,
    },
    {
        selector: row => <Button  text={<TranslateKey translateKey="modifiers.editButton"/>} onClick={()=>edit(row)}/>,
        style: {
            justifyContent:'right',
        }
    },

]};

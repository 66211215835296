import {createContext, useCallback, useContext, useState} from "react";
import { config } from '../config';
import { useEffectOnce } from '../hooks/useEffectOnce';
import {useSocketEmit} from "../hooks/useSocketEmit";

export const TrucksContext = createContext();

export const useTrucks = () => useContext(TrucksContext)

export const TrucksProvider = ({children}) => {
    const [trucks, setTrucks] = useState([]);
    const {getTrucks, addTruck, setTruckStatus} = useSocketEmit()

    useEffectOnce(()=>{
        getTrucks().then((data)=>{
            console.log(data)
            setTrucks(data)
        }).catch(error=>console.log(error))
    },[])

    const handleStatus = async (plateId, status) => {

        return new Promise((resolve, reject) => {
            setTruckStatus({plateId, status}).catch((error => {
                console.error(error)
                reject(error);
            })).then((result) => {
                const newTrucks = trucks.map(t=>{
                    if(t.plateId===plateId){
                        t.status = status
                    }
                    return t;
                })
                setTrucks(newTrucks);
                resolve();
            })
        })
    }

    const enable = async (plateId) => {
        return await handleStatus(plateId, config.truckStatus.AVAILABLE)
    }

    const disable = async (plateId) => {
        return await handleStatus(plateId, config.truckStatus.UNAVAILABLE)
    }

    const add = async (data) => {
        return new Promise((resolve, reject) => {
            addTruck(data).catch((error => {
                console.error(error)
                reject(error);
            })).then((result) => {
                const existing = trucks.find(t=>t.id === data.id);

                if(existing){
                    const prod = trucks.map(p=>{
                        if(p.id===data.id){
                            return {...p, description: data.description, tanks: data.tanks} ;
                        } else {
                            return p;
                        }
                    })
                    setTrucks(prod);
                } else {
                    setTrucks([...trucks, result])
                }
                resolve();
            })
        })
    }

    return (
        <TrucksContext.Provider
            value={{trucks: trucks, setTrucks: setTrucks, add, enable, disable}}>
            {children}
        </TrucksContext.Provider>
    )
};

import React, {useEffect, useState} from 'react';
import { useTrucks } from '../../contexts/TrucksProvider';
import { formatTruck } from '../../utils';
import ModifiersContainer from './modifiers/ModifiersContainer';
import ModifiersTable from './modifiers/ModifiersTable';
import TrucksContainer from './trucks/TrucksContainer';
import TrucksTable from './trucks/TrucksTable';


const Trucks = () => {
    const {trucks} = useTrucks();
    const [tableData, setTableData] = useState([])


    useEffect(() => {
        if (trucks) {
            setTableData(formatTruck(trucks))
        }
    }, [trucks])


    return (
        <TrucksContainer>
            <TrucksTable tableData={tableData}/>
        </TrucksContainer>
    );
};

export default Trucks;

import React, {useState} from 'react';
import VStack from "../../ui/VStack";
import {theme} from "../../../theme";
import Input from "../../ui/Input";
import Button from "../../ui/Button/Button";
import Text from "../../ui/Text";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Login from "../../../services/Login";
import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {emailValidator, passwordValidator} from "../../../validators/login";
import {useNavigate} from "react-router-dom";
import {getNavigateRouteFromRole} from "../../../utils";

const LoginForm = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {register, handleSubmit} = useForm();

    const onSubmit = data => {
        dispatch(Login(data)).then(unwrapResult).then(res => {
            const role = res.user.role
            navigate(getNavigateRouteFromRole(role), {replace: true})
        }).catch((rejectedValueOrSerializedError, i) => {
            if (rejectedValueOrSerializedError.status === 404) {
                alert('error 404')
            } else if (rejectedValueOrSerializedError.status === 401) {
                alert('Autenticazione fallita')
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack space={theme.spacing["spacing-05"]} style={{flex: 1, alignItems: 'center'}}>
                <Text variant={'h3'} alignText={'left'}>{t('routes.login.form.title')}</Text>
                <Input placeholder={t('routes.login.form.placeholders.email')}
                       type={'email'} {...register("email", emailValidator)}/>
                <Input placeholder={t('routes.login.form.placeholders.password')}
                       type={'password'} {...register("password", passwordValidator)}/>
                <Button text={t('routes.login.form.button')} type={'submit'} disabled={loading}/>
            </VStack>
        </form>
    );
};

export default LoginForm;

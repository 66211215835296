import Home from './Home'
import Login from './Login'
import Missing from "./Missing";
import Recovery from "./Recovery";
import Unauthorized from "./Unauthorized";
import Profile from "./Profile";
import Users from "./Users";
import Aging from "./Aging";

export const routes = {
    home: <Home/>,
    profile: <Profile/>,
    login: <Login/>,
    missing: <Missing/>,
    recovery: <Recovery/>,
    unauthorized: <Unauthorized/>,
    createUsers: <Users/>,
    aging: <Aging/>
}


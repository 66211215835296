import React from 'react';
import {theme} from "../../../../theme";
import {config} from "../../../../config";
import useModifiers from "../../../../hooks/useModifiers";
import {useModal} from "../../../../contexts/ModalContext";
import {useTranslation} from "react-i18next";
import AddSaltModal from "./AddSaltModal";
import PermanentModifiersModal from "./PermanentModifiersModal";

declare type ModifierSelectorProps = {
    row: any,
    itemId: string,
}

const ModifierSelector = ({row, itemId}: ModifierSelectorProps) => {
    const {categories, modifiers} = useModifiers(config.processingSteps.STEP_06)
    const {openModal} = useModal() as any
    const {t} = useTranslation()
    const chainCode = row.chainCodes[0]

    const handleChange = (e) => {
        const category = e.target.value as string
        switch (category) {
            case config.modifiers.categories.SALT: {
                openModal({
                    title: t('commons.modifiers.salting'),
                    child: <AddSaltModal row={row} itemId={itemId} chainCode={chainCode}
                                         modifiersData={modifiers?.[category]}/>,
                })
                break;
            }
            case config.modifiers.categories.WASHING: {
                const modifier = modifiers?.[category][0]
                if (modifier) {
                    openModal({
                        title: t('commons.modifiers.washing'),
                        child: <PermanentModifiersModal category={category} itemId={itemId} chainCode={chainCode}
                                                        modifierId={modifier.id} row={row}/>,
                    })
                }
                break;
            }
        }
    }

    return (
        // @ts-ignore
        <select style={selectStyle} onChange={handleChange}
                defaultValue={t('commons.selectProcessing').toString()}>
            <option disabled>{t('commons.selectProcessing').toString()}</option>
            {
                categories && categories.map((category, i) => {
                    return <option key={i} defaultValue={t('commons.select').toString()}
                                   value={category}>{t(`commons.modifiers.${category}`)}</option>

                })
            })
        </select>
    );
};

const selectStyle = {
    border: `1px solid ${theme.colors.lightGrey}`,
    width: '100%',
    height: 40,
    borderRadius: 10,
    ...theme.components.Text.variants.default_text,
}

export default ModifierSelector;

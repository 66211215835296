import React from 'react';
import {config} from "../../config";

const ProcessPressModel = (row, itemId) => {
    return {
        step: config.processingSteps.STEP_03,
        source: {
            itemId: row.id,
        },
        destination: {
            itemId: itemId,
            loadAmount: row?.loadAmount || 0,
            waste: row?.waste || 0,
        },
    }
};

export default ProcessPressModel;

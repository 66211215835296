import {config} from "./config";
import {HistoryModel} from "./models/HistoryModel";
import Tank from "./components/molecules/modals/tank/Tank";
import Polyvalent from "./components/molecules/modals/polyvalent/Polyvalent";
import Press from "./components/molecules/modals/press/Press";
import HotChamber from "./components/molecules/modals/hotChamber/HotChamber";
import RipeningChamber from "./components/molecules/modals/ripeningChamber/RipeningChamber";
import Racks from "./components/molecules/modals/rack/Racks";
import _ from 'lodash'
import jsPDF from "jspdf";
import Packaging from "./components/molecules/modals/packaging/Packaging";
import SeasoningRacks from "./components/molecules/modals/seasoningRack/SeasoningRacks";
import Seasoning from "./components/molecules/modals/seasoning/Seasoning";
import moment from "moment";
import {ProductModel} from './models/ProductModel';
import {ModifierModel} from './models/ModifierModel';
import {TruckModel} from './models/TruckModel';

export const getWasteCount = (waste) => {
    let wasteCount = 0
    waste.forEach(wasteItem => {
        wasteCount = wasteCount + wasteItem.amount
    })
    return wasteCount
}

export const updateRipeningChamberSalt = (saltArray, amount, lotId) => {
    let saltArrayReplica = saltArray
    const foundIndex = saltArray.findIndex(saltItem => saltItem.id === lotId)
    // if waste for a {{rackId}} already exists
    if (foundIndex !== -1) {
        // check if {{newWaste}} is empty and remove item from the array
        if (amount === '') {
            saltArrayReplica = saltArrayReplica.filter(wasteItem => wasteItem.itemId !== lotId)
        } else {
            // or update waste with {{newWaste}}
            saltArrayReplica[foundIndex].amount = amount
        }
    } else {
        // if waste doesn't exist just push {{newWaste}} for that {{rackId}}
        saltArrayReplica.push({id: lotId, amount: parseInt(amount)})
    }
    console.log('saltArrayReplica', saltArrayReplica)
    return saltArrayReplica
}

export const updateRipeningChamberWaste = (wasteArray, newWaste, rackId) => {
    let wasteReplica = [...wasteArray]
    const foundIndex = wasteArray.findIndex(wasteItem => wasteItem.itemId === rackId)
    const isNewWasteValid = !(newWaste === '' || newWaste === 0 || newWaste === '0' || isNaN(newWaste))

    // if waste for a {{rackId}} already exists
    if (foundIndex !== -1) {
        // check if {{newWaste}} is empty and remove item from the array
        if (isNewWasteValid) {
            // apply {{newWaste}} only if it's valid
            wasteReplica[foundIndex] = {itemId: rackId, amount: parseInt(newWaste)}
        }
    } else {
        // if waste doesn't exist just push {{newWaste}} for that {{rackId}}
        wasteReplica.push({itemId: rackId, amount: parseInt(newWaste)})
    }

    if (!isNewWasteValid) {
        // filter out if exists
        wasteReplica = wasteReplica.filter(wasteItem => wasteItem.itemId !== rackId)
    }

    return wasteReplica
}


export const getFormattedElapsedTime = (createdAt) => {
    const a = moment()
    const b = createdAt
    const diffInSeconds = a.diff(b, 'seconds')
    return moment.duration(diffInSeconds, 'seconds').locale("it").humanize()
}

export const normalizeBlendsByModifiers = (blends) => {
    const itemList = _(blends).groupBy(blend => blend.chainCodes[0]).map((blends, id) => {
        const isolateModifiers = blend => {
            return {...blend.params?.modifiers[0], note: blend.params?.note}
        }
        return ({
            id: id,
            chainCode: id,
            amount: _.last(blends).amount,
            waste: _.last(blends).waste,
            createdAt: _.last(blends).createdAt,
            modifiers: _.map(blends, isolateModifiers)
        })
    }).value()

    return itemList
}
export const filterRacksByChainCode = (racks, chainCode) => {
    return racks?.filter(rack => rack.blends[0]?.chainCodes[0] === chainCode)
}

export const getItemFromChainCode = (list, chainCode) => {
    const found = findChainCodeFromList(list, chainCode)
    if (found) {
        return found
    }

    return null
}

export const findChainCodeFromList = (list, chainCode) => {
    const found = list.find(item => item?.chainCodes[0] === chainCode)
    if (!found) return null
    return found
}

export const findItem = (list, id) => {
    const found = list.find(item => item.id === id)
    if (!found) return null
    return found
}

export const getCallbackByStep = (step) => {

}

export const getTitleByStep = (step, data) => {
    switch (step) {
        case config.processingSteps.STEP_01:
            return `${data.name} • ${data.amount}/${data.capacity} Litri`
        case config.processingSteps.STEP_02:
            return `${data.name} • ${data.amount}/${data.capacity} Litri`
        case config.processingSteps.STEP_03:
        case config.processingSteps.STEP_04:
        case config.processingSteps.STEP_05:
        case config.processingSteps.STEP_06:
        case config.processingSteps.STEP_07:
        case config.processingSteps.STEP_09:
            return `${data.name}`
        default:
            return <></>
    }
}

export const containsTemperatureModifier = (loads) => {
    const found = loads.find(item => item.name === 'temperature')
    return !!found;
}

export const isLoaded = (loads) => {
    if (loads?.length > 0) {
        const isStepClosed = containsTemperatureModifier(loads)
        return isStepClosed
    }
    return false
}

const compareTextsForFilter = (string1, string2) => {
    return string1.toLowerCase().includes(string2.toLowerCase())
}

export const racksFilterRules = (item, filterText) => {
    if (compareTextsForFilter(item.name, filterText))
        return item
    if (item.blends.length <= 0) return
    if (compareTextsForFilter(item.blends[0].chainCodes[0], filterText))
        return item
}

export const getComponentByStep = (step, data) => {
    switch (step) {
        case config.processingSteps.STEP_01:
            return <Tank data={data} id={data.id}/>
        case config.processingSteps.STEP_02:
            return <Polyvalent data={data} id={data.id}/>
        case config.processingSteps.STEP_03:
            return <Press data={data} id={data.id}/>
        case config.processingSteps.STEP_04:
            return <HotChamber data={data} id={data.id}/>
        case config.processingSteps.STEP_05:
            return <Racks/>
        case config.processingSteps.STEP_06:
            return <RipeningChamber data={data} id={data.id}/>
        case config.processingSteps.STEP_07:
            return <Packaging data={data} id={data.id}/>
        case config.processingSteps.STEP_08:
            return <SeasoningRacks data={data} id={data.id}/>
        case config.processingSteps.STEP_09:
            return <Seasoning data={data} id={data.id}/>
        default:
            return <></>
    }
}

export const getSeasonersFromUsers = users => {
    return users.filter(user => user.role === config.loginRoles.EXTERNAL_SEASONER || user.role === config.loginRoles.INTERNAL_SEASONER)
}

export const getExternalSeasonersFromUsers = users => {
    return users.filter(user => user.role === config.loginRoles.EXTERNAL_SEASONER)
}

export const isInternalSeasoner = ({seasonerId, users}) => {
    const found = users.find(user => user.id === seasonerId)
    return found?.role === config.loginRoles.INTERNAL_SEASONER
}

export const getNavigateRouteFromRole = (role) => {
    switch (role) {
        case config.loginRoles.PROCESSOR:
        case config.loginRoles.STORAGE_MANAGER:
            return config.routes.HOME
        case config.loginRoles.ISSUER:
            return config.routes.CREATE_USERS
        case config.loginRoles.EXTERNAL_SEASONER:
        case config.loginRoles.INTERNAL_SEASONER:
            return config.routes.AGING
        default:
            return config.routes.UNAUTHORIZED
    }
}

export const isStatusFinal = status => {
    console.log('status', status)
    switch (status) {
        case config.blendStatus.ORIGIN_REJECTED:
        case config.blendStatus.ORIGIN_ACCEPTED:
        case config.blendStatus.PROCESSOR_CLOSED:
            return true
        default:
            return false
    }
}

export const hasSuggestedAmount = dispute => {
    const lastMessage = _.last(dispute.messages)
    if (lastMessage.isProcessor && lastMessage.message === 'Il caseificio ha accettato la tua proposta, vuoi confermare?')
        return false
    return dispute.proposal?.suggestedAmount

}

export const statusAllowsDisputeReply = (status) => {
    switch (status) {
        case config.blendStatus.ORIGIN_REJECTED:
        case config.blendStatus.ORIGIN_ACCEPTED:
        case config.blendStatus.PROCESSOR_CLOSED : {
            return false
        }
        default:
            return true
    }
}

export const getCTAFromStatus = (status, dispute) => {
    if (dispute) {
        return config.listActionCTAs.DISPUTE_DETAILS
    }

    switch (status) {
        case config.blendStatus.PROCESSOR_VERIFIED:
        case config.blendStatus.DELIVERED: {
            return config.listActionCTAs.CREATE_DISPUTE
        }
        case config.blendStatus.ORIGIN_DISPUTED:
        case config.blendStatus.ORIGIN_REJECTED: {
            return config.listActionCTAs.DISPUTE_DETAILS
        }
        default: {
            return null
        }
    }
}

export const formatHistory = (history) => {
    return _.map(history, HistoryModel)
}

export const formatProduct = (product) => {
    return _.map(product, ProductModel)
}

export const formatModifier = (modifier) => {
    return _.map(modifier, ModifierModel)
}

export const formatTruck = (truck) => {
    return _.map(truck, TruckModel)
}

export const getBlendIdsFromSelectedRows = (selectedRows) => {
    return _.map(selectedRows, item => {
        return item.id
    })
}

const isValidModifier = ({item, processItem, typeComparator}) => {
    const hasType = (item.type === typeComparator)
    const hasLot = item.lotId !== ''
    const hasValidValue = parseInt(item.amount) > 0
    const found = processItem.modifiers.find(modifier => modifier.id === item.id)
    let amountIsMatchingAvailable = false
    if (found) {
        amountIsMatchingAvailable = found.amount >= parseInt(item.amount)
    }
    return hasType && hasLot && hasValidValue && amountIsMatchingAvailable
}

export const disableCloseStepButton = (values, processItem, formValues, productId) => {
    let hasAtLeastOneEnzyme = !!(formValues.find(item => isValidModifier({
        item,
        processItem,
        typeComparator: config.polyvalent.modifiers.enzymes
    })))
    let hasAtLeastOneRennet = !!(formValues.find(item => isValidModifier({
        item,
        processItem,
        typeComparator: config.polyvalent.modifiers.rennet
    })))

    const hasProduct = !!productId
    const hasBlends = processItem.blends?.length > 0

    if (!hasAtLeastOneEnzyme || !hasAtLeastOneRennet || !hasBlends || !hasProduct) return true
    if (hasBlends && hasAtLeastOneRennet && hasAtLeastOneEnzyme) return false

    return _.isEmpty(values);
}

export const processStatusLabel = (status) => {
    switch (status) {
        case config.processStatus.AVAILABLE:
            return 'Disponibile';
        case config.processStatus.CLOSED:
            return 'Chiuso';
        case config.processStatus.RESET:
            return 'Reset';
        case config.processStatus.BUSY:
            return 'Caricata';
        default:
            return 'N/A';
    }
}

export const getAvailableRacks = (racks) => {
    const availableRacks = racks.filter(rack => rack.capacity > rack.amount)
    return availableRacks.length || 0
}

export const getFilledRacks = (racks) => {
    const availableRacks = racks.filter(rack => rack.amount > 0)
    return availableRacks.length || 0
}

export const createPDF = async (id) => {
    const pdf = new jsPDF("landscape", "pt", "a4");
    const data = await document.querySelector(id);
    pdf.html(data).then(() => {
        pdf.save("shipping_label.pdf");
    });
};

export const groupByChainCode = blends => {
    if (blends?.length > 0) {
        const data = _(blends).groupBy(blend => blend.chainCodes[0]).map((blends, id) => {
            return ({
                id: id,
                chainCode: id,
                amount: _.last(blends).amount,
            })
        }).value()
        return data
    }
    return []
}

export const getTotalWaste = (wasteArray) => {
    let totalWaste = 0
    wasteArray.forEach(item => totalWaste += item.amount)
    return totalWaste
}

import {useSelector} from "react-redux";
import {usersSelector} from "../../../../store/users/usersSlice";

const SeasonerCell = ({seasonerId}) => {
    const {users} = useSelector(usersSelector)
    const found = users.find(user => user.id === seasonerId)
    if (found) return found.name
    return 'N/A'
}

export default SeasonerCell

import React, {useEffect, useState} from 'react';
import { useModal } from '../../../contexts/ModalContext';
import { analyticsColumns } from '../../../data/tables/analyticsColumns';
import {historyColumns} from "../../../data/tables/historyColumns";
import DataTable from "../../molecules/DataTable";
import {config} from "../../../config";
import {useHistory} from "../../../contexts/HistoryProvider";
import {useSocketEmit} from "../../../hooks/useSocketEmit";
import {useTranslation} from "react-i18next";
import {formatHistory, getBlendIdsFromSelectedRows} from "../../../utils";
import AnalyticsReportForm from '../../molecules/forms/AnalyticsReportForm';
import Button from "../../ui/Button/Button";
import HistoryComboBox from "../../molecules/tableFilters/HistoryComboBox";
import {theme} from "../../../theme";
import _ from 'lodash'
import CustomPagination from "../../table/CustomPagination";
import TableFilter from "../../molecules/tableFilters/HistoryFilter";
import DateRangePicker from "../../molecules/dateRangePicker";
import moment from "moment";

const HistoryTable = ({tableData, isAnalytics}) => {
    const {refreshHistory, history} = useHistory()
    const {verify, getHistory} = useSocketEmit()
    const {t} = useTranslation()

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    const [selectedValue, setSelectedValue] = useState('')
    const [filterText, setFilterText] = useState('')
    const [filteredItems, setFilteredItems] = useState(tableData)
    const {openModal} = useModal()


    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);


    const contextActions = React.useMemo(() => {
        const handleApprove = () => {
            if (window.confirm(t('history.approveSelected.confirm'))) {
                setToggleCleared(!toggleCleared);
                const blendIds = getBlendIdsFromSelectedRows(selectedRows)
                verify(blendIds).then(() => {
                    refreshHistory()
                }).catch(console.error)
            }
        };

        return (
            <Button key="approve" onClick={handleApprove} icon text={t('history.approveSelected.button')}/>
        );
    }, [refreshHistory, selectedRows, toggleCleared, verify, t]);

    const rowDisabledCriteria = row => row.status !== config.blendStatus.DELIVERED

    useEffect(() => {
        if (filterText.length >= 4) {
            getHistory({params: {plateId: filterText}}).then(res => {
                if (res.blends.length > 0) setFilteredItems(formatHistory(res.blends))
            }).catch(console.error)
        } else {
            setFilteredItems(tableData)
        }
    }, [filterText])

    const [dateRange, setDateRange] = useState([new Date(), new Date()]);

    const handleChange = (e) => {
        setDateRange(e)
        console.log({
            dateRange: {
                start: moment(e[0]).valueOf(),
                end: moment(e[1]).valueOf()
            }
        })
        getHistory({
            dateRange: {
                start: moment(e[0]).valueOf(),
                end: moment(e[1]).valueOf()
            }
        }).then(res => setFilteredItems(formatHistory(res.blends))).catch(console.error)
    }

    useEffect(()=>{
        if(history) {
            setFilteredItems(formatHistory(history))
            setFilterText('')
        }
    }, [history])

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <div style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: 'flex',
                columnGap: theme.spacing["spacing-04"]
            }}>
                <DateRangePicker className={"date-range-picker"} format={"y-MM-dd"} onChange={handleChange}
                                 value={dateRange} maxDate={new Date()}/>
                {!isAnalytics && <TableFilter onFilter={e => setFilterText(e.target.value)}
                             inputPlaceholder={t("history.plateIdFilterPlaceholder")}
                             filterText={filterText}/>}
                <HistoryComboBox selectedValue={selectedValue} setSelectedValue={setSelectedValue}/>
            </div>
        );
    }, [selectedValue, dateRange, filterText]);

    const paginationComponent = () => {
        const lastElementId = _.last(tableData).id
        const firstElementId = _.first(tableData).id

        let filter = null;
        if (selectedValue !== '') {
            filter = {params: {originId: selectedValue}}
        }

        return <CustomPagination lastElementId={lastElementId} firstElementId={firstElementId} filter={filter}/>
    }

    const handleReport = (data) => {
        openModal({
            title: data.report ? t('analytics.editModal.title') : t('analytics.addModal.title'),
            child: <AnalyticsReportForm item={data}/>,
        })
    }

    return (
        <DataTable
            selectableRowDisabled={rowDisabledCriteria}
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            title={t('history.title')}
            columns={isAnalytics ? analyticsColumns(handleReport): historyColumns}
            selectableRows={true}
            data={filteredItems}
            subHeaderComponent={subHeaderComponentMemo}
            subHeader={true}
            pagination={true}
            paginationComponent={paginationComponent}
        />
    );
};

export default HistoryTable;

import * as Yup from 'yup';

export const CreateDisputeSchema = Yup.object().shape({
    blendId: Yup.string()
        .min(24, 'Too Short!')
        .max(24, 'Too Long!')
        .required('Required'),
    amount: Yup.number()
        .max(100000, 'Too Long!')
        .min(1, 'Should be longer'),
    message: Yup.string().max(500, 'Too Long!').required('Required'),
});

import {createAsyncThunk} from '@reduxjs/toolkit';
import {PublicClient as api} from '../gateways';
import {config} from "../config";

export default createAsyncThunk('auth/refresh', async (data, rejectWithValue) => {
    try {
        const response = await api.post(config.endpoints.REFRESH, {refreshToken: data});
        console.log(response.data)
        return response.data;
    } catch (err) {
        return rejectWithValue({status: err.response.status, message: err.response.message})
    }
});

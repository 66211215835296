import RipeningChamberDetailButton from "../../components/molecules/modals/ripeningChamber/RipeningChamberDetailButton";
import {getFormattedElapsedTime} from "../../utils";
import moment from "moment";
import DownloadRackSheetCell from "../../components/molecules/modals/ripeningChamber/DownloadRackSheetCell";

export const ripeningChamberLoadsColumns = [
    {
        name: 'Identificativo',
        selector: row => row.chainCode,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Ultima salatura',
        selector: row => moment(row.createdAt).format("YYYY-MM-DD HH:mm"),
        sortable: true,
        reorder: true,
    },
    {
        name: 'Ultima salatura da...',
        selector: row => getFormattedElapsedTime(row.createdAt),
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Trasferite',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Perse',
        selector: row => row.waste,
        sortable: true,
        reorder: true,
    },
    // {
    //     name: 'Sale',
    //     selector: row => {
    //         const reducedSaltLots = row.params.modifiers.map(modifier => `${modifier.lotId} (${modifier.amount}g)`)
    //         const salting = reducedSaltLots.join(", ")
    //         return salting
    //     },
    //     grow: 2,
    //     sortable: true,
    //     reorder: true
    // },
    {
        cell: row => {
            return row.chainCode && <DownloadRackSheetCell row={row}/>
        },
    },
    {
        selector: row => <RipeningChamberDetailButton data={row}/>,
        sortable: true,
    }
];

import React from 'react';
import {useModifiers} from "../../../../../contexts/ModifiersFormContext";
import {theme} from "../../../../../theme";
import ListAction from "../../../../ui/ListAction";

const RemoveModifierListAction = ({index}) => {
    const {removeRow} = useModifiers()

    const handleClick = () => {
        removeRow(index)
    }

    return (
        <ListAction onClick={handleClick} target="_blank" rel="noopener noreferrer" text={'Rimuovi'}
                    style={{color: theme.colors.error}}/>
    );
};

export default RemoveModifierListAction;

import {useSelector} from "react-redux";
import Text from "../../ui/Text";
import {processItemsSelector} from "../../../store/processItems/processItemsSlice";
import {config} from "../../../config";

const TankColumnField = ({tankId}) => {
    const {processItems} = useSelector(processItemsSelector)
    const tanks = processItems[config.processingSteps.STEP_01]
    const tankName = tanks.find(tank => tank.id === tankId)?.name
    return <Text variant={'default_text'}>{tankName || 'N/A'}</Text>
}

export default TankColumnField

import {config} from "../../config";
import ProcessStepButton from "../../components/molecules/buttons/ProcessStepButton";
import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import ProcessPressModel from "../../models/addTabs/ProcessPressModel";
import {validatePressAdd} from "../../validators/processStepValidators";

export const pressColumns = ({resetForm, onValueChange, itemId, blends, handleSubmit}) => [
    {
        name: 'Polivalenti',
        selector: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Litri',
        selector: row => row.amount,
        sortable: true,
        reorder: true
    },
    {
        name: 'Forme',
        cell: row => (<ProcessStepInput data={row} type={config.input.LOAD_AMOUNT} placeholder={'Quantità...'}
                                        updatevalue={onValueChange}
                                        disabled={(row.amount <= 0 && row.processStatus === config.processStatus.AVAILABLE)}/>),
        sortable: true,
        reorder: true,
    },
    {
        name: 'Perso',
        cell: row => (<ProcessStepInput data={row} type={config.input.WASTE} placeholder={'Quantità...'}
                                        updatevalue={onValueChange}
                                        disabled={(row.amount <= 0 && row.processStatus === config.processStatus.AVAILABLE)}/>),
        sortable: true,
        reorder: true,
    },
    {
        cell: row => {
            const options = ProcessPressModel(row, itemId)
            const isValid = validatePressAdd({row, blends})

            const onSubmit = () => {
                resetForm(row.id)
                handleSubmit()
            }

            return <ProcessStepButton options={options}
                                      disabled={!isValid}
                                      isValid={isValid}
                                      onSubmit={onSubmit}/>
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: true,
        reorder: true,
    }
];

import React from 'react';
import Text from "../../../ui/Text";
import VStack from "../../../ui/VStack";
import Center from "../../../ui/Center";

const ProcessingItemIsFull = ({text}) => {
    return (
        <VStack style={{height: 100}}>
            <Center>
                <Text variant={'default_text'}>{text}</Text>
            </Center>
        </VStack>
    );
};

export default ProcessingItemIsFull;

import React from 'react';
import {theme} from '../../../theme';
import HStack from '../HStack';
import Text from '../Text';
import Button from '../Button/Button';
import {useTranslation} from 'react-i18next';
import {CardProps} from './types';
import {CardAvailability, CardContainer, CardContent} from './styles';
import {useSelector} from 'react-redux';
import {userSelector} from '../../../store/user/userSlice';
import {config} from '../../../config';
import {processStatusLabel} from '../../../utils';

const Card = ({name, capacity, unit, handleClick, amount, status, useStatus, buttonLabel}: CardProps) => {
  const {t} = useTranslation()
  const user = useSelector(userSelector)
  const style = user.roles.includes(config.loginRoles.PROCESSOR) && status === config.processStatus.UNAVAILABLE ? styles.hide : {};
  return (
    <CardContainer style={style}>
      <HStack>
        <Text variant={'h6'}>{name}</Text>
      </HStack>
      <CardContent>
        <CardAvailability>
          {!useStatus && <Text variant={'h3'}>{amount}/{capacity}</Text>}
          <Text variant={'h6'} color={theme.colors.midGrey}>{useStatus ? processStatusLabel(status) : unit}</Text>
        </CardAvailability>
        {user.roles.includes(config.loginRoles.STORAGE_MANAGER)
          && status === config.processStatus.AVAILABLE
          && <Text style={styles.link} variant={'h6'} onClick={handleClick} color={theme.colors.primary}>{t('components.card.disable')}</Text>}
        {user.roles.includes(config.loginRoles.STORAGE_MANAGER)
          && status === config.processStatus.UNAVAILABLE
          && <Text style={styles.link} variant={'h6'} onClick={handleClick} color={theme.colors.primary}>{t('components.card.enable')}</Text>}
        {user.roles.includes(config.loginRoles.PROCESSOR) && <Button text={buttonLabel} onClick={handleClick}/>}
      </CardContent>
    </CardContainer>
  );
};

const styles = {
  hide: {
    display: 'none'
  },
  link: {
    cursor: 'pointer'
  },
}
export default Card;

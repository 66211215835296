import React, {useEffect} from 'react';
import Tab from "../tab/Tab";
import {theme} from "../../../theme";

const StepperModal = ({tabData, initialTabIndex, refresh}) => {
    useEffect(() => {
        if (refresh) {
            refresh()
        }
    }, [])

    return (
        <div style={{paddingTop: theme.spacing["spacing-06"]}}>
            <Tab data={tabData} hasProfile={false} initialTabIndex={initialTabIndex}/>
        </div>
    );
};

export default StepperModal;

import React, {useState} from 'react';
import {theme} from "../../theme";
import {useMediaQuery} from "react-responsive";

const ListAction = ({text, onClick, style}) => {
    const [clicked, setClicked] = useState(false)
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})
    const mobileFont = isTabletOrMobile ? {...theme.components.Text.variants.default_text} : {...theme.components.Text.variants.h5}
    return (
        <div
            onMouseDown={() => setClicked(!clicked)}
            onMouseUp={() => setClicked(!clicked)}
            style={{
                color: theme.colors.primary,
                mobileFont,
                cursor: 'pointer',
                opacity: clicked ? 0.5 : 1,
                ...style
            }}
            onClick={onClick}>
            {text}
        </div>
    );
};

export default ListAction;

import React from 'react';
import {theme} from "../../theme";

const AuthLayout = (props) => {
    return (
        <div style={{
            display: 'flex',
            flex: 1,
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: theme.spacing["spacing-03"],
            paddingRight: theme.spacing["spacing-03"]
        }}>
            {props.children}
        </div>
    );
};

export default AuthLayout;

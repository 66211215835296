import {config} from "../../config";
import ProcessStepButton from "../../components/molecules/buttons/ProcessStepButton";
import ProcessStepInput from "../../components/molecules/inputs/ProcessStepInput";
import ProcessPolyvalentModel from "../../models/addTabs/ProcessPolyvalentModel";
import {validatePolyvalentAdd} from "../../validators/processStepValidators";
import ProductColumnField from "../../components/molecules/columnFields/ProductColumnField";

export const addMilkToPolyvalentColumns = ({handleSubmit, onValueChange, itemId, capacity}) => {

    return [
        {
            name: 'Cisterna',
            selector: row => `${row.name}`,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Tipo Latte',
            cell: row => <ProductColumnField productId={row.blends[0].params.productId}/>,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Carico',
            selector: row => row.amount,
            sortable: true,
            reorder: true,
        },
        {
            name: 'Trasferito',
            cell: row => {
                return <ProcessStepInput placeholder={'Litri'} data={row}
                                         type={config.input.LOAD_AMOUNT}
                                         updatevalue={onValueChange}
                                         disabled={row.amount <= 0}/>
            },
            sortable: true,
            reorder: true,
        },
        {
            name: 'Perso',
            cell: row => {
                return <ProcessStepInput placeholder={'Litri'} data={row}
                                         type={config.input.WASTE}
                                         updatevalue={onValueChange}
                                         disabled={row.amount <= 0}/>
            },
            sortable: true,
            reorder: true,
        },
        {
            cell: row => {
                const options = ProcessPolyvalentModel(row, itemId)
                const isValid = validatePolyvalentAdd({options, row, capacity})

                return <ProcessStepButton options={options} onSubmit={handleSubmit}
                                          disabled={!isValid} isValid={isValid}/>
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            sortable: true,
            reorder: true,
        },
    ];
}

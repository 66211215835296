
export const ProductModel = (data) => {

    return {
        name: data.name,
        description: data.description,
        status: data.status,
        code: data.code,
        type: data.type,
        id: data.id,
    }
}

import React from 'react';
import Button from "../../../ui/Button/Button";
import {useModal} from "../../../../contexts/ModalContext";
import Rack from "./Rack";
import {useTranslation} from "react-i18next";
import {FormProvider} from "../../../../hooks/useForm";
import {config} from "../../../../config";

const OpenRackDetailButton = ({data}) => {
    const {openModal} = useModal()
    const {t} = useTranslation()

    const handleClick = () => {
        openModal({
            title: `${data.name} • ${data.capacity} Forme`,
            child: <FormProvider><Rack id={data.id}/></FormProvider>,
        })
    }

    return (
        <Button text={t('processing.rack.buttons.openRackDetailButton')} onClick={handleClick}
                disabled={data.processStatus === config.processStatus.CLOSED}/>
    )
};

export default OpenRackDetailButton;

import React, {useEffect, useState} from 'react';
import { formatProduct } from '../../utils';
import {useProducts} from "../../contexts/ProductsProvider";
import ProductsContainer from './products/ProductsContainer';
import ProductsTable from './products/ProductsTable';


const Products = () => {
    const {products} = useProducts()
    const [tableData, setTableData] = useState([])


    useEffect(() => {
        if (products) {
            setTableData(formatProduct(products))
        }
    }, [products])


    return (
        <ProductsContainer>
            <ProductsTable tableData={tableData}/>
        </ProductsContainer>
    );
};

export default Products;

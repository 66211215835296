import {createContext, useEffect, useState} from "react";
import {useProcessingItems} from "./ProcessingItemsContext";
import {config} from "../config";
import {TankLoadModel} from "../models/TankLoadModel";

export const TankLoadsContext = createContext();

// TODO this can be refactored like press behavior to work without a dedicated provider, just like press, with getTankLoads / updateTankLoadById

const TankLoadsProvider = ({children}) => {
    const {processingItems} = useProcessingItems()

    const [tankLoads, setTankLoads] = useState([])

    useEffect(() => {
        if (processingItems.length > 0) {
            const found = processingItems.find(item => item.step === config.processingSteps.STEP_01)
            const tankLoads = found?.items?.map(load => (TankLoadModel(load))) || []
            setTankLoads(tankLoads)
        }
    }, [processingItems])


    const updateTankLoadById = (id, type, value) => {
        setTankLoads(loads => {
            const foundIndex = loads.findIndex(load => load.id === id)
            const found = loads.find(load => load.id === id)
            if (foundIndex !== -1) {
                found[type] = parseInt(value)
                return loads
            }
            return loads
        })
    }

    return (
        <TankLoadsContext.Provider
            value={{tankLoads, updateTankLoadById}}>{children}</TankLoadsContext.Provider>
    );
};

export default TankLoadsProvider;

import moment from "moment";
import {useSelector} from "react-redux";
import {usersSelector} from "../../store/users/usersSlice";
import ChangeSeasonerButton from "../../components/molecules/modals/seasoning/ChangeSeasonerButton";

const SeasonerCell = ({seasonerId}) => {
    const {users} = useSelector(usersSelector)
    const found = users.find(user => user.id === seasonerId)
    if (found) return found.name
    return 'N/A'
}

export const seasoningLoadsColumns = [
    {
        name: 'Identificativo',
        selector: row => row.chainCodes[0],
        sortable: true,
        reorder: true,
    },
    {
        name: 'Data e Ora',
        selector: row => moment(row.createdAt).format('DD/MM/YY HH:mm'),
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Trasferite',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Peso (Kg)',
        selector: row => `${row.params?.weight} Kg`,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Stagionatore',
        selector: row => <SeasonerCell seasonerId={row.params?.seasonerId}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Cambia stagionatore',
        selector: row => <ChangeSeasonerButton data={row}/>,
        sortable: true,
        reorder: true,
        width: "250px"
    },
];

import {createContext, useContext, useEffect, useState} from "react";
import {config} from "../config";

export const FormContext = createContext([]);

export const FormProvider = ({children}) => {
    const [formData, setFormData] = useState()

    return (
        <FormContext.Provider value={{formData, setFormData}}>
            {children}
        </FormContext.Provider>
    )
};

export const useForm = (loads, step) => {
    const {formData, setFormData} = useContext(FormContext);

    const initialValues = {discrepancy: '', waste: '', loadAmount: '', weight: '', weightLoss: ''}

    const refreshLoads = (loads) => {
        const initTableData = loads.map(item => {
            return {...item, ...initialValues}
        })
        setFormData(initTableData)
    }

    useEffect(() => {
        refreshLoads(loads)
    }, [])


    const onValueChange = ({id, value, type}) => {
        const updatedValue = formData.map(row => {
            if (row.id === id) {
                if (type === config.input.MODIFIERS) {
                    return {...row, [type]: value || []}
                }
                return {...row, [type]: parseInt(value) || ''}
            }
            return row
        })
        setFormData(updatedValue)
    }

    const resetForm = (id) => {
        if (step === config.processingSteps.STEP_04) {
            const updatedForm = formData.filter(row => row.id !== id)
            setFormData(updatedForm)
        } else {
            const updatedForm = formData.map(row => {
                if (row.id === id) {
                    if (step === config.processingSteps.STEP_03) {
                        return {...row, hasLoads: false, ...initialValues}
                    }
                    const updatedAmount = row.amount - row.discrepancy - row.waste - row.loadAmount
                    return {...row, amount: updatedAmount, ...initialValues}
                }
                return row
            })
            setFormData(updatedForm)
        }
    }

    return {
        formData,
        setFormData,
        resetForm,
        refreshLoads,
        onValueChange
    }
}

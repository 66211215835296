import React from 'react';
import {theme} from "../../../theme";
import {useSelector} from "react-redux";
import {processableProductsSelector} from "../../../store/processableProducts/processableProductsSlice";
import {useModifiers} from "../../../contexts/ModifiersFormContext";

const ProductSelector = () => {
    const {processableProducts} = useSelector(processableProductsSelector)
    const {setProduct, productId} = useModifiers()

    const handleChange = (productId) => {
        setProduct(productId)
    }


    return (
        <select style={{
            border: `1px solid ${theme.colors.lightGrey}`,
            width: '100%',
            height: 40,
            borderRadius: 10,
            ...theme.components.Text.variants.default_text,
        }} onChange={e => handleChange(e.target.value)}
                defaultValue={productId || 'Seleziona un prodotto'}>
            <option disabled>Seleziona un prodotto</option>
            {
                processableProducts?.map((product, i) => {
                    return <option key={i} defaultValue={'Seleziona...'}
                                   value={product.id}>{product.name}</option>
                })
            })
        </select>
    );
};

export default ProductSelector;

import React from 'react';
import DataTable from "../../DataTable";
import {WasteSlice} from "../../../../interfaces/WasteSlice";
import {addSaltColumns} from "../../../../data/tables/addSaltColumns";

declare type SaltSectionProps = {
    chainCode: string
    setModifiers: React.Dispatch<React.SetStateAction<WasteSlice[]>>
    data: object[]
}

const WasteSection = ({chainCode, setModifiers, data}: SaltSectionProps) => {
    return (
        <DataTable
            highlightOnHover={false}
            columns={addSaltColumns(chainCode, setModifiers)}
            data={data}
        />
    );
};

export default WasteSection;

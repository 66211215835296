import React from 'react';
import ListAction from "../../../ui/ListAction";
import {useTranslation} from "react-i18next";

const ShowNote = ({note}) => {
    const {t} = useTranslation()

    return <ListAction text={t('processing.ripeningChamber.notes.show')} onClick={() => alert(note)} style={''}/>
};

export default ShowNote;

import React, {useEffect} from 'react';
import Text from "./Text";
import HStack from "./HStack";
import {theme} from "../../theme";
import Box from "./Box/Box";
import {useModal} from "../../contexts/ModalContext";
import useWindowSize from "../../hooks/useWindowSize";

const Modal = () => {
    const {modal, closeModal} = useModal()

    const historyColors = [theme.colors.lightGrey, theme.colors.midGrey, theme.colors.darkGrey, theme.colors.black]
    const historyLength = modal?.history?.length

    const windowSize = useWindowSize()

    const escFunction = (event) => {
        if (event.key === "Escape") {
            closeModal()
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [modal]);

    return (
        <>
            {modal?.open &&
                <div style={{
                    zIndex: 200,
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    position: 'fixed',
                    height: windowSize.height,
                    width: '100%',
                }}>
                    <HStack style={{justifyContent: 'center', opacity: 1}} onClick={e => e.preventDefault()}>
                        <div style={{
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            zIndex: 112,
                            position: 'absolute',
                            bottom: 0,
                            width: window.innerWidth * .75,
                            height: window.innerHeight * .8,
                            backgroundColor: 'white',
                        }}>
                            {historyLength > 0 && modal.history.map((r, i) => {
                                return (
                                    <div key={i} style={{
                                        height: 25,
                                        backgroundColor: i !== 0 && historyColors[i]
                                    }}>
                                        {
                                            historyLength !== i &&
                                            <div style={{
                                                height: 25,
                                                borderTopLeftRadius: 20,
                                                borderTopRightRadius: 20,
                                                backgroundColor: historyColors[i + 1]
                                            }}/>
                                        }
                                    </div>)
                            })}
                            <div style={{
                                height: 25,
                                backgroundColor: historyLength > 0 && historyColors[historyLength]
                            }}>
                                <div style={{
                                    height: 25,
                                    borderTopLeftRadius: 20,
                                    borderTopRightRadius: 20,
                                    backgroundColor: theme.colors.white
                                }}/>
                            </div>
                            <div style={{
                                paddingRight: theme.spacing["spacing-07"],
                                paddingLeft: theme.spacing["spacing-07"],
                                paddingTop: theme.spacing['spacing-05'],
                                paddingBottom: theme.spacing['spacing-08']
                            }}>
                                <HStack styleoverrides={{height: 36}}>
                                    <Text variant={'h1'}>{modal.data.title}</Text>
                                    <Box style={{justifyContent: 'flex-end'}}>
                                        <Text variant={'h5'} color={theme.colors.primary} style={{cursor: 'pointer'}}
                                              onClick={closeModal}>Chiudi</Text>
                                    </Box>
                                </HStack>
                                <div style={{
                                    overflow: 'auto',
                                    height: '100%',
                                    overflowY: 'hidden',
                                    paddingBottom: theme.spacing["spacing-09"]
                                }}>
                                    {modal.data.child}
                                </div>
                            </div>
                        </div>
                    </HStack>
                </div>}
        </>
    );
};

export default Modal;

import {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {config} from "../config";
import {usersSelector} from "../store/users/usersSlice";

const useOrigins = () => {
    const {users} = useSelector(usersSelector)
    const [origins, setOrigins] = useState([])

    useEffect(() => {
        if (users?.length > 0) {
            setOrigins(users?.filter(user => user.role === config.roles.ORIGIN))
        }
    }, [users])

    return {
        origins
    }
};

export default useOrigins;

import {createSlice} from '@reduxjs/toolkit';

const initialState = []

export const seasonerFormSlice = createSlice({
    name: 'seasonerForm',
    initialState: initialState,
    reducers: {
        setSelectedSeasoner: (state, action) => {
            return action.payload
        },
        resetState: () => {
            return initialState
        }
    },
});

export const {setSelectedSeasoner, resetState} = seasonerFormSlice.actions;

export const selectedSeasonerSelector = state => state.seasonerForm;

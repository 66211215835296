import React, {useEffect, useState} from 'react';
import {formatHistory} from "../../utils";
import {useHistory} from "../../contexts/HistoryProvider";
import HistoryContainer from "./history/HistoryContainer";
import HistoryTable from './history/HistoryTable';


const QuantityAnalysis = ({user}) => {
    if(user.backendUrl.indexOf('sandbox') === -1 ){
        return <div style={{
            paddingTop: 32,
            paddingLeft: 64,
            paddingRight: 64,
        }}>
            <iframe style={{backgroundColor: '#F1F5F4', border: 'none',borderRadius: 2,boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',width: '93vw',height: '100vh'}}  src="https://charts.mongodb.com/charts-project-0-ezbkl/embed/dashboards?id=53a8b858-b898-4981-b6d5-1ae26276eae8&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=fixed&scalingHeight=scale"></iframe>
        </div>
    } else {
        return <div style={{
            paddingTop: 32,
            paddingLeft: 64,
            paddingRight: 64,
        }}>
            <iframe style={{backgroundColor: '#F1F5F4', border: 'none',borderRadius: 2,boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',width: '93vw',height: '100vh'}}  src="https://charts.mongodb.com/charts-project-0-ezbkl/embed/dashboards?id=6ab86f81-ae5f-4680-8fe7-8ccea8ecd89b&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed"></iframe>
        </div>
    }
};

export default QuantityAnalysis;

import {createContext, useCallback, useContext, useState} from "react";
import { useEffectOnce } from '../hooks/useEffectOnce';
import {useSocketEmit} from "../hooks/useSocketEmit";

export const ProductsContext = createContext();

export const useProducts = () => useContext(ProductsContext)

export const ProductsProvider = ({children}) => {
    const [products, setProducts] = useState([]);
    const {getProducts, addProduct} = useSocketEmit()

    useEffectOnce(()=>{
        getProducts().then((data)=>{
            setProducts(data)
        }).catch(error=>console.log(error))
    },[])

    const add = async (data) => {
        return new Promise((resolve, reject) => {
            addProduct({products:[data]}).catch((error => {
                console.error(error)
                reject(error);
            })).then((result) => {
                if(data.id){
                    const prod = products.map(p=>{
                        if(p.id===data.id){
                            return data;
                        } else {
                            return p;
                        }
                    })
                    setProducts(prod);
                } else {
                    setProducts([...products, result[0]])
                }
                resolve();
            })
        })
    }

    return (
        <ProductsContext.Provider
            value={{products, setProducts, add}}>
            {children}
        </ProductsContext.Provider>
    )
};

import {createSlice} from '@reduxjs/toolkit';
import LoginUserService from '../../services/Login';
import RefreshTokenService from '../../services/RefreshToken';
import {UserModel} from "../../models/UserModel";

const initialState = {
    user: {
        auth: null,
    },
    login: {
        isError: false,
        isSuccess: false,
        isFetching: false,
        isRefreshTokenError: false,
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        logout: () => {
            return initialState
        },
        updateToken: (state, {payload}) => {
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
        },
    },
    extraReducers: {
        [LoginUserService.fulfilled]: (state, {payload}) => {
            state = UserModel(payload)
            state = {
                ...state, login: {
                    isSuccess: true,
                    isError: false,
                    isFetching: false
                }
            }
            return state
        },
        [LoginUserService.rejected]: state => {
            state = {
                ...state, login: {
                    isSuccess: false,
                    isError: true,
                    isFetching: false
                }
            }
        },
        [LoginUserService.pending]: state => {
            state = {
                ...state, login: {
                    isSuccess: false,
                    isError: false,
                    isFetching: true
                }
            }
        },
        [RefreshTokenService.fulfilled]: (state, {payload}) => {
            state = UserModel(payload)
            state.login.isError = false;
            state.login.isFetching = false;
            state.login.isSuccess = true;
            state.login.isRefreshTokenError = false;
            return state
        },
        [RefreshTokenService.rejected]: state => {
            state.login.isFetching = false;
            state.login.isRefreshTokenError = true;
        },
    },
});

export const {logout, updateToken} =
    userSlice.actions;

export const userSelector = state => state.user;

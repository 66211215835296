import {createContext, useCallback, useContext, useState} from "react";
import {config, LOT, MODIFIERS} from "../config";

const EmptyRowModel = (index) => {
    return {index: index, id: '', type: '', lotId: '', amount: '', available: ''}
}

export const ModifiersFormContext = createContext(0);

export const useModifiers = () => useContext(ModifiersFormContext)

export const ModifiersFormProvider = ({children}) => {
    const [formValues, setFormValues] = useState([EmptyRowModel(0)]);
    const [availableRennetLots, setAvailableRennetLots] = useState([])
    const [availableEnzymeLots, setAvailableEnzymeLots] = useState([])
    const [productId, setProductId] = useState('')

    const [usedLotIds, setUsedLotIds] = useState([])

    const [modifiers, setModifiers] = useState([])

    const resetModifiersFormValues = () => {
        setFormValues([EmptyRowModel(0)])
        setUsedLotIds([])
        setProductId('')
    }

    const updateIndexes = (array) => {
        const updatedArray = array.map((item, i) => {
            return {...item, index: i,}
        })
        return updatedArray
    }

    const removeRow = (index) => {
        setFormValues(formValues => {
            const {lotId} = formValues[index]
            if (lotId) {
                setUsedLotIds(usedLotIds => {
                    const updatedArr = usedLotIds.filter(usedLotId => usedLotId !== lotId)
                    if (updatedArr.length > 0) {
                        return updatedArr
                    } else return []
                })
            }
            const updatedFormValues = formValues.filter(item => item.index !== index)
            return updateIndexes(updatedFormValues)
        })
    }

    const hasNextRow = (currentRowIndex) => {
        return formValues.length > (currentRowIndex + 1);
    }

    const [enzymes, setEnzymes] = useState([])
    const [rennets, setRennets] = useState([])

    const initModifiers = (data) => {
        setModifiers(data.modifiers)
        const rennets = data.modifiers.filter(item => item.category === config.modifierCategories.RENNET)
        const enzymes = data.modifiers.filter(item => item.category === config.modifierCategories.ENZYMES)
        setEnzymes(enzymes)
        setRennets(rennets)

        const rennetLots = rennets.map(rennet => rennet.lotId)
        const enzymeLots = enzymes.map(enzyme => enzyme.lotId)

        setAvailableRennetLots(rennetLots)
        setAvailableEnzymeLots(enzymeLots)
    }

    const getModifierIdByLotId = (data) => {
        if (data.dataType === config.polyvalent.modifiers.rennet) {
            // @ts-ignore
            const found = rennets.find(rennet => rennet.lotId === data.value)
            // @ts-ignore
            return found.id
        } else if (data.dataType === config.polyvalent.modifiers.enzymes) {
            // @ts-ignore
            const found = enzymes.find(enzyme => enzyme.lotId === data.value)
            // @ts-ignore
            return found.id
        }
        return null
    }
    const updateModifiers = (index, data) => {
        if (!hasNextRow(index)) {
            formValues.push(EmptyRowModel(index + 1))
        }

        const updatedFormValues = formValues.map(formValue => {
            if (formValue.index === index) {
                if (data.type === MODIFIERS) {
                    return {...formValue, type: data.value, lotId: ''}
                } else if (data.type === LOT) {
                    // @ts-ignore
                    const available = modifiers.find(modifier => modifier.lotId === data.value).amount || 0
                    // @ts-ignore
                    setUsedLotIds(usedLotIds => [...usedLotIds, data.value])
                    return {
                        ...formValue,
                        lotId: data.value,
                        id: getModifierIdByLotId(data),
                        available: available
                    }
                }
            }
            return formValue
        })

        const cleanupFormValues = updatedFormValues.map(formValue => {
            if (formValue.lotId === data.value && index !== formValue.index) {
                return {...formValue, lotId: '', id: '', available: ''}
            } else return formValue
        })

        if (cleanupFormValues) {
            setFormValues(cleanupFormValues)
        }
    }

    const setAmount = (index, value) => {
        // @ts-ignore
        setFormValues(formValues => {
            const updatedFormValues = formValues.map((item, i) => {
                if (i === index) {
                    return {...item, amount: parseInt(value)}
                } else return item
            })
            return updatedFormValues
        })
    }

    const getAvailableModifiersType = useCallback(() => (
        [config.polyvalent.modifiers.enzymes, config.polyvalent.modifiers.rennet]
    ), [])

    const getAvailableLotIdsByType = (type) => {
        if (type === config.polyvalent.modifiers.rennet) {
            return availableRennetLots
        } else if (type === config.polyvalent.modifiers.enzymes) {
            return availableEnzymeLots
        } else return []
    }

    const setProduct = (productId) => {
        setProductId(productId)
    }

    return (
        // @ts-ignore
        <ModifiersFormContext.Provider
            // @ts-ignore
            value={{
                formValues: formValues,
                usedLotIds,
                setAmount,
                setProduct,
                productId,
                resetModifiersFormValues,
                removeRow,
                updateModifiers,
                initModifiers,
                getAvailableLotIdsByType,
                getAvailableModifiersType
            }}>
            {children}
        </ModifiersFormContext.Provider>
    )
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { useModal } from '../../../contexts/ModalContext';
import { useTrucks } from '../../../contexts/TrucksProvider';
import { FormProvider } from '../../../hooks/useForm';
import { theme } from '../../../theme';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input';
import Text from '../../ui/Text';
import { plateIdValidator, tankCapacityValidator, tankIdValidator, descriptionValidator} from '../../../validators/Truck';
import DisplayTanks from './DisplayTanks';

const TruckForm = ({ item }) => {
  const { t } = useTranslation();
  const [isNew, setIsNew] = useState(!item);
  const [tanks, setTanks] = useState(item? item.tanks : []);
  const [loading, setLoading] = useState(false);
  const {closeModal} = useModal()
  const {add, trucks} = useTrucks()



  const {getValues, register, handleSubmit,  formState: {errors}} = useForm({
    mode:'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    defaultValues: {
      plateId: item?.plateId || '',
      description: item?.description || '',
    }
  });

  const onSubmit = data => {
    if(isNew) {
      data.plateId = data.plateId.toUpperCase();
    } else {
      delete data.plateId;
      data.id = item.id;
    }

    delete data.tankId;
    delete data.tankCapacity;
    data.tanks= tanks;

    add(data).catch(console.error).finally(() => {
       closeModal()
    })
  };

  useEffect(() => {
    setIsNew(!item);
    return () => {
      setIsNew(false);
    };
  }, []);

  const addTank = (e) =>{
    if(getValues('tankId') && getValues('tankCapacity')) {
      setTanks(prev => {
        let res = [...prev];
        if (res.find(t => t.id === getValues('tankId'))) {
          res = res.filter(t => t.id !== getValues('tankId'));
        }
        res.push({ id: getValues('tankId'), capacity: getValues('tankCapacity'), unit: 'lt' });
        return res
      })
    }
  }

  const removeTank = (id) => {
    setTanks(prev => prev.filter(t=>t.id !== id))
  }

  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={styles.formContainer}>

          {isNew && <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('trucks.common.plateId')}</Text>
            <Input style={ errors?.name ? styles.erroredInput: {}} placeholder={t('trucks.common.plateId')} type={'text'} {...register('plateId', plateIdValidator(trucks))} />
            {errors?.plateId && <Text style={styles.error} variant={'p1'}>{t(errors.plateId.message)}</Text>}
          </div>}

        </div>
          <div style={styles.inputContainer}>
            <Text  style={styles.label} variant={'h5'} alignText={'left'}>{t('trucks.common.description')}</Text>
            <textarea  {...register('description', descriptionValidator)} placeholder={t('trucks.common.description')}  style={styles.textArea}/>
            {errors?.description && <Text style={styles.error} variant={'p1'}>{t(errors.description.message)}</Text>}
          </div>

        <DisplayTanks tanks={tanks} remove={removeTank}/>

        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('trucks.common.tankId')}</Text>
            <Input style={ errors?.tankId ? styles.erroredInput: {}} placeholder={t('trucks.common.tankId')} type={'text'} {...register('tankId', ()=>tankIdValidator(tanks.length>0))} />
            {errors?.tankId && <Text style={styles.error} variant={'p1'}>{t(errors.tankId.message)}</Text>}
          </div>
          <div style={styles.inputContainer}>
            <Text style={styles.label} variant={'h5'} alignText={'left'}>{t('trucks.common.tankCapacity')}</Text>
            <Input style={ errors?.tankCapacity ? styles.erroredInput: {}} placeholder={t('trucks.common.tankCapacityPlaceholder')} type={'number'} {...register('tankCapacity', ()=>tankCapacityValidator(tanks.length>0))} />
            {errors?.tankCapacity && <Text style={styles.error} variant={'p1'}>{t(errors.tankCapacity.message)}</Text>}
          </div>
        </div>
        <Button text={t('trucks.common.addTank')} type={'button'} onClick={addTank}/>

        <div style={styles.buttonContainer}>
          <Button text={t('products.common.confirm')} disabled={loading || tanks.length <1}/>
        </div>
      </form>
    </FormProvider>
  );
}

const styles = {
  columnHeader: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['spacing-04'],
  },
  newItem: { cursor: 'pointer' },
  select: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.colors.lightGrey,
    borderRadius: 10,
    paddingLeft: theme.spacing["spacing-05"],
    paddingRight: theme.spacing["spacing-05"],
    paddingTop: theme.spacing["spacing-05"],
    paddingBottom: theme.spacing["spacing-05"],
    ...theme.components.Text.variants.h5,
  },
  label: {
    marginBottom: theme.spacing['spacing-04']
  },
  textArea: {
    ...theme.components.Text.variants.default_text,
    padding: theme.spacing["spacing-05"],
    outline: 'none',
    borderRadius: 10,
    resize: 'none',
    borderColor: theme.colors.lightGrey,
    width:'90%',
  },
  formContainer: {
    display: 'flex',
    flex:1,
    flexDirection:'row',
    flexWrap:'wrap',
    marginTop: theme.spacing['spacing-08'],

  },
  inputContainer: {
    margin: theme.spacing['spacing-04']
  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-08']
  },
  error: {
    color: theme.colors.error
  },
  erroredInput: {
    color: theme.colors.error,
    borderColor: theme.colors.error
  },
};

export default TruckForm;

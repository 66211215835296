import WasteInput from "../../components/molecules/modals/ripeningChamber/WasteInput";

export const addWasteColumns = (chainCode, setWaste) => [
    {
        name: 'Rack',
        cell: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Quantità presente',
        cell: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Perse',
        selector: row => <WasteInput rackId={row.rackId} chainCode={chainCode} setWaste={setWaste}
                                     maxAmount={row.amount}/>
        ,
        sortable: true,
        reorder: true,
    },
];

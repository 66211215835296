
export const ModifierModel = (data) => {

    return {
        id: data.id,
        name: data.name,
        unit: data.unit,
        status: data.status,
        type: data.type,
        step: data.step,
        lotId: data.lotId,
        amount: data.amount,
        category: data.category,
    }
}




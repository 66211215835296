import {useCallback, useContext} from 'react';
import {SocketContext} from "../contexts/SocketProvider";
import {config} from "../config";
import _ from 'lodash'
import ReactGA from "react-ga";

export const useSocketEmit = () => {
    const {socket} = useContext(SocketContext);
    const addProcessingItem = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.ADD_PROCESS_ITEM, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }
    const deleteProcessingItem = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.SET_PROCESSING_ITEM_STATUS, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }
    const closeStep = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.CLOSE_STEP, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const terminateSeasoning = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.CLOSE_STEP, options, response => {
                console.log('response', response)
                ReactGA.event({
                    category: config.analytics.events.processing.LABEL,
                    action: config.analytics.events.processing.TERMINATE_SEASONING,
                })
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const removeLoad = async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.REMOVE_LOAD, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const verify = useCallback(async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.VERIFY, options, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }, [socket])

    const processStep = useCallback(async (options) => {
        return new Promise((resolve, reject) => {
            console.log('processStep options', options)
            socket.emit(config.actions.PROCESS_STEP, options, response => {
                ReactGA.event({
                    category: config.analytics.events.processing.LABEL,
                    action: config.analytics.events.processing.PROCESS_STEP,
                    label: options?.step
                })
                console.log('processStep response', response)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }, [socket])

    const getHistory = useCallback(async (options) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.BLEND_HISTORY, {type: 'retrieved', ...options, limit: 10}, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }, [socket])

    const getHistoryByDeliveryId = async (deliveryId, tankId, plateId) => {
        console.log('deliveryId, tankId, plateId', deliveryId, tankId, plateId)
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.BLEND_HISTORY, {
                type: 'retrieved',
                params: {
                    plateId: plateId, // optional
                    tankId: tankId, // optional
                }, deliveryId: deliveryId
            }, response => {
                if (response.result?.success) {
                    console.log('result', response.result.data)
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const getProcessItemById = async (itemId) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.GET_PROCESS_ITEMS, {itemId: itemId}, response => {
                console.log('getProcessItemById', response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const getProcessItemByStep = async (step) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.GET_PROCESS_ITEMS, {step: step}, response => {
                if (response.result?.success) {
                    const data = response.result.data
                    if (_.has(data, 'item'))
                        resolve(response.result.data.item)
                    else resolve(response.result.data.items)
                } else reject(response)
            })
        })
    }

    const getProcessItems = async () => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.GET_PROCESS_ITEMS, {}, response => {
                console.log('getProcessItems', response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const getDeliveries = async () => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.GET_DELIVERIES, {}, response => {
                console.log('getDeliveries', response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else {
                    reject(response)
                }
            })
        })
    }

    const createDispute = async (body) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.CREATE_DISPUTE, body, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const acceptDisputeAmount = async (body) => {
        return new Promise((resolve, reject) => {
            console.log('body', body)
            socket.emit(config.actions.CREATE_DISPUTE, body, response => {
                console.log("response", response)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const closeDefinitively = async (body) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.CLOSE, body, response => {
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const getEditableUsers = async () => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.LIST_USERS, {roles: config.editableUsers}, response => {
                console.log(config.actions.LIST_USERS, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const editUser = async (data) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.UPDATE_USER, data, response => {
                console.log(config.actions.UPDATE_USER, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const disableUser = async (userId) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.DISABLE_USER, {userId}, response => {
                console.log(config.actions.DISABLE_USER, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response.error.result.error.message)
            })
        })
    }

    const getProducts = async () => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.LIST_PRODUCTS, {}, response => {
                console.log(config.actions.LIST_PRODUCTS, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const addProduct = async (data) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.ADD_PRODUCT, data, response => {
                console.log(config.actions.ADD_PRODUCT, response.result)
                if (response.result?.success) {
                    resolve(response.result.data.products)
                } else reject(response)
            })
        })
    }

    const getModifiers = async () => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.LIST_MODIFIERS, {}, response => {
                console.log(config.actions.LIST_MODIFIERS, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const addModifier = async (data) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.ADD_MODIFIER, data, response => {
                console.log(config.actions.ADD_MODIFIER, response.result)
                if (response.result?.success) {
                    resolve(response.result.data.modifier)
                } else reject(response)
            })
        })
    }

    const getTrucks = async () => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.GET_TRUCKS, {}, response => {
                console.log(config.actions.GET_TRUCKS, response.result)
                if (response.result?.success) {
                    resolve(response.result.data.trucks)
                } else reject(response)
            })
        })
    }

    const addTruck = async (data) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.ADD_TRUCK, data, response => {
                console.log(config.actions.ADD_TRUCK, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const setTruckStatus = async (data) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.SET_TRUCK_STATUS, data, response => {
                console.log(config.actions.SET_TRUCK_STATUS, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    const addReport = async (data) => {
        return new Promise((resolve, reject) => {
            socket.emit(config.actions.BLEND_ADD_REPORT, data, response => {
                console.log(config.actions.BLEND_ADD_REPORT, response.result)
                if (response.result?.success) {
                    resolve(response.result.data)
                } else reject(response)
            })
        })
    }

    return {
        socket,
        verify,
        removeLoad,
        closeStep,
        terminateSeasoning,
        processStep,
        getDeliveries,
        getProcessItems,
        getProcessItemById,
        getProcessItemByStep,
        createDispute,
        acceptDisputeAmount,
        closeDefinitively,
        getHistory,
        getHistoryByDeliveryId,
        addProcessingItem,
        deleteProcessingItem,
        getEditableUsers,
        editUser,
        disableUser,
        getProducts,
        addProduct,
        getModifiers,
        addModifier,
        addTruck,
        getTrucks,
        setTruckStatus,
        addReport,
    }
}

import {createSlice} from '@reduxjs/toolkit';

const initialState = {processItems: []}

export const processItemsSlice = createSlice({
    name: 'processItems',
    initialState: initialState,
    reducers: {
        setProcessItems: (state, action) => {
            state.processItems = action.payload
        },
    },
});

export const {setProcessItems} =
    processItemsSlice.actions;

export const processItemsSelector = state => state.processItems;

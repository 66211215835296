import {createSlice} from '@reduxjs/toolkit';

const initialState = {processableProducts: []}

export const processableProductsSlice = createSlice({
    name: 'processableProducts',
    initialState: initialState,
    reducers: {
        setProcessableProducts: (state, action) => {
            state.processableProducts = action.payload
        },
    },
});

export const {setProcessableProducts} =
    processableProductsSlice.actions;

export const processableProductsSelector = state => state.processableProducts;

import React from 'react';
import {theme} from "../../../theme";
import {useHistory} from "../../../contexts/HistoryProvider";
import useOrigins from "../../../hooks/useOrigins";
import Text from "../../ui/Text";

const HistoryComboBox = ({selectedValue, setSelectedValue}) => {
    // const [selectedValue, setSelectedValue] = useState('')
    const {origins} = useOrigins()
    const {refreshHistory} = useHistory()

    const handleChange = (e) => {
        setSelectedValue(e.target.value)
        let params = {}
        if (e.target.value !== '') {
            params = {params: {originId: e.target.value}}
        }
        refreshHistory(params)
    }


    return (
        <div style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            columnGap: theme.spacing["spacing-04"]
        }}>
            <Text variant={'default_text'}>Filtra per pastore</Text>
            <select style={{
                borderRadius: 10,
                border: `1px solid ${theme.colors.lightGrey}`,
                padding: theme.spacing["spacing-02"], ...theme.components.Text.variants.default_text,
            }}
                    onChange={handleChange}
                    value={selectedValue}>
                <option defaultValue={''} value={''}/>
                {origins?.map((option, i) => {
                    return (
                        <option key={i} defaultValue={'Seleziona...'} value={option.id}>{option.name}</option>
                    )
                })}
            </select>
        </div>
    )
};

export default HistoryComboBox;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import LoadsTab from "../LoadsTab";
import {tankLoadsColumns} from "../../../../data/tables/tankLoadsColumns";
import StepperModal from "../StepperModal";
import useProcessingItem from "../../../../hooks/useProcessingItem";
import AddTab from "../AddTab";
import {addMilkToTankColumns} from "../../../../data/tables/addMilkToTankColumns";
import {config} from "../../../../config";
import {FormProvider} from "../../../../hooks/useForm";
import {TankModel} from "../../../../models/processingItems/TankModel";

const Tank = ({id}) => {
    const {t} = useTranslation()
    const {processItem, loading, refreshProcessingItems} = useProcessingItem(id, TankModel)
    const [tabData, setTabData] = useState()

    useEffect(() => {
        if (processItem) {
            const tab1 = <LoadsTab loads={processItem.blends} extraData={{columns: tankLoadsColumns}}/>
            const tab2 = <AddTab loads={processItem.sourceItems} columns={addMilkToTankColumns}
                                 refreshProcessingItems={refreshProcessingItems}
                                 itemId={processItem.id} step={config.processingSteps.STEP_01}/>

            setTabData({
                tabs: [t('processing.tank.tab.loadOrigin'),
                    t('processing.tank.tab.add')],
                contentItems: [tab1, tab2]
            })
        }
    }, [loading, processItem])


    return (
        <FormProvider>
            {
                tabData &&
                <StepperModal tabData={tabData} refresh={() => console.log('implement refresh for tank modal')}/>
            }
        </FormProvider>
    );
};

export default Tank;

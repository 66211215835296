import moment from "moment";
import {Trans} from "react-i18next";
import ShowNote from "../../components/molecules/modals/ripeningChamber/ShowNote";

export const ripeningChamberLogColumns = [
    {
        name: <Trans i18nKey={`commons.processingTimestamp`}/>,
        cell: row => moment(row.appliedAt).format("YYYY-MM-DD HH:mm:ss"),
        sortable: true,
        reorder: true,
        grow: 2,
    },
    {
        name: <Trans i18nKey={`commons.processingCategory`}/>,
        cell: row => {
            return <Trans i18nKey={`commons.modifiers.${row.category}`}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={`commons.processing`}/>,
        cell: row => row.name,
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={`commons.lot`}/>,
        cell: row => row.lotId,
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={`commons.quantity`}/>,
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        cell: row => row.note && <ShowNote note={row.note}/>,
        sortable: true,
        reorder: true,
    },
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import {theme} from "../../../theme";
import Card from "../../ui/Card/Card";
import ProcessStepRackCardModel from "../../../models/ProcessStepRackCardModel";
import {useModal} from "../../../contexts/ModalContext";
import {getFilledRacks} from "../../../utils";

/**
 * This card works differently from ProcessingItemCard, so it was isolated in a specialized component
 * as it's supposed to show all the racks and polyvalents from within its details.
 * @param processingItem
 * @returns {JSX.Element}
 * @constructor
 */
const RackCard = ({processingItem}) => {
    const {t} = useTranslation()
    const {openModal} = useModal()
    const quantity = processingItem?.items?.length
    const handleClick = () => {
        openModal(ProcessStepRackCardModel(processingItem.step, processingItem))
    }

    return (
        <div style={{paddingBottom: theme.spacing["spacing-05"]}}>
            <Card name={t('processing.rack.cardName')} capacity={quantity} amount={getFilledRacks(processingItem.items)}
                  unit={'Caricati'} buttonLabel={'Apri'}
                  handleClick={handleClick}/>
        </div>
    );
};

export default RackCard;

import React, {useEffect, useMemo} from 'react';
import useProcessingItem from "../../../../hooks/useProcessingItem";
import {useForm} from "../../../../hooks/useForm";
import {useModal} from "../../../../contexts/ModalContext";
import PressLoadsModel from "../../../../models/loadTabs/PressLoadsModel";
import TableContainer from "../../tab/TableContainer";
import DataTable from "../../DataTable";
import {addLoadsToSeasoningRackColumns} from "../../../../data/tables/addLoadsToSeasoningRackColumns";
import {seasoningRackLoadsColumns} from "../../../../data/tables/seasoningRackLoadsColumns";

const SeasoningRackModel = (data) => {
    console.log("seasoningRack", data)
    return {...data.item, sourceItems: data.sourceItems}

}

const SeasoningRack = ({id}) => {
    const {processItem, loading} = useProcessingItem(id, SeasoningRackModel)
    const {formData, setFormData, onValueChange, resetForm} = useForm([])
    const {closeModal} = useModal()

    useEffect(() => {
        if (!loading && processItem) {
            setFormData(PressLoadsModel(processItem.sourceItems[0]?.blends))
        }
    }, [loading])

    const hasLoads = useMemo(() => processItem?.blends?.length > 0, [formData, processItem?.blends])
    const tableData = useMemo(() => (hasLoads ? processItem?.blends : formData), [formData, processItem?.blends])

    const handleSubmit = () => {
        closeModal()
    }

    return (
        <TableContainer>
            <DataTable
                highlightOnHover={false}
                columns={hasLoads ? seasoningRackLoadsColumns(processItem?.blends) : addLoadsToSeasoningRackColumns({
                    resetForm,
                    onValueChange,
                    handleSubmit,
                    itemId: processItem?.id, capacity: processItem?.capacity,
                    containerId: processItem?.extraData?.containerId
                })}
                data={tableData}
            />
        </TableContainer>
    )
};

export default SeasoningRack;

import React, {useState} from 'react';
import {theme} from "../../../theme";
import {useSelector} from "react-redux";
import {usersSelector} from "../../../store/users/usersSlice";
import {getExternalSeasonersFromUsers, getSeasonersFromUsers} from "../../../utils";
import useSelectedSeasoner from "../../../hooks/useSelectedSeasoner";

const SeasonerSelector = ({data, filterExternal}) => {
    const {users} = useSelector(usersSelector)
    const {seasonerForm, setSelectedSeasonerId} = useSelectedSeasoner()
    const [seasoners] = useState(filterExternal ? getExternalSeasonersFromUsers(users) : getSeasonersFromUsers(users))

    const handleChange = (seasonerId) => {
        setSelectedSeasonerId({chainCode: data.chainCodes[0], seasonerId: seasonerId})
    }

    const getDefaultValue = () => {
        const found = seasonerForm?.find(item => item.chainCode === data.chainCodes[0])
        if (found) {
            return (found.seasonerId)
        }
        return null
    }

    return (
        <select style={{
            border: `1px solid ${theme.colors.lightGrey}`,
            width: '100%',
            height: 40,
            borderRadius: 10,
            ...theme.components.Text.variants.default_text,
        }} onChange={e => handleChange(e.target.value)}
                defaultValue={'-- Seleziona uno stagionatore --'}>
            {/*<option disabled>-- Seleziona un tipo --</option>*/}
            <option disabled
                    value={'-- Seleziona uno stagionatore --'}/>
            {
                seasoners?.map((seasoner, i) => {
                    return <option key={i} defaultValue={'Seleziona...'}
                                   value={seasoner.id}>{seasoner.name}</option>
                })
            })
        </select>
    );
};

export default SeasonerSelector;

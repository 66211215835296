import DataTable from "../DataTable";
import {useForm} from "../../../hooks/useForm";
import TableContainer from "../tab/TableContainer";
import {useEffect} from "react";

const AddTab = ({loads, columns, itemId, step, blends, refreshProcessingItems, capacity, ignoreTableContainer}) => {
    const {formData, onValueChange, resetForm, refreshLoads} = useForm(loads, step)

    useEffect(() => {
        refreshLoads(loads)
    }, [loads])

    const handleSubmit = (id) => {
        resetForm(id)
        refreshProcessingItems()
    }

    return (
        <>
            {ignoreTableContainer ? <DataTable
                    highlightOnHover={false}
                    columns={columns({resetForm, onValueChange, itemId, blends, handleSubmit, capacity})}
                    data={formData}
                /> :
                <TableContainer>
                    <DataTable
                        highlightOnHover={false}
                        columns={columns({resetForm, onValueChange, itemId, blends, handleSubmit, capacity})}
                        data={formData}
                    />
                </TableContainer>}
        </>
    )
}

export default AddTab

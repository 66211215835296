import SaltInput from "../../components/molecules/modals/ripeningChamber/SaltInput";

export const addSaltColumns = (chainCode, setModifiers) => [
    {
        name: 'Lotto',
        cell: row => row.lotId,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Quantità (g)',
        cell: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Inserisci quantità (g)',
        selector: row => <SaltInput lotId={row.id} setModifiers={setModifiers} maxAmount={row.amount}/>
        ,
        sortable: true,
        reorder: true,
    },
];

import {useSelector} from "react-redux";
import {productsSelector} from "../../../store/products/productsSlice";
import Text from "../../ui/Text";

const ProductColumnField = ({productId}) => {
    const {products} = useSelector(productsSelector)
    const productName = products.find(product => product.id === productId)?.name
    return <Text variant={'default_text'}>{productName || 'N/A'}</Text>
}

export default ProductColumnField

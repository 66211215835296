import { useTranslation } from 'react-i18next';
import {useModal} from "../../../contexts/ModalContext";
import ProcessStepRackCardModel from "../../../models/ProcessStepRackCardModel";
import {theme} from "../../../theme";
import Card from "../../ui/Card/Card";
import {getFilledRacks} from "../../../utils";

const SeasoningRackCard = ({processingItem}) => {
    const {openModal} = useModal()
    const quantity = processingItem?.items?.length
    const { t } = useTranslation();

    const handleClick = () => {
        openModal(ProcessStepRackCardModel(processingItem.step, processingItem))
    }

    return (
        <div style={{paddingBottom: theme.spacing["spacing-05"]}}>
            <Card name={t('processing.vRack.title')} capacity={quantity} amount={getFilledRacks(processingItem.items)}
                  unit={t('components.card.loaded')} buttonLabel={t('components.card.open')}
                  handleClick={handleClick}/>
        </div>
    );
};

export default SeasoningRackCard;

import React, {useState} from 'react';
import Button from "../../../ui/Button/Button";
import HStack from "../../../ui/HStack";
import Text from "../../../ui/Text";
import VStack from "../../../ui/VStack";
import {theme} from "../../../../theme";
import ProcessRipeningChamberModel from "../../../../models/addTabs/ProcessRipeningChamberModel";
import {useSocketEmit} from "../../../../hooks/useSocketEmit";
import {PermanentModifiersModalProps} from "../../../../interfaces/PermanentModifiersModalProps";
import {useModal} from "../../../../contexts/ModalContext";
import {useProcessingItems} from "../../../../contexts/ProcessingItemsContext";
import {useTranslation} from "react-i18next";
import {getWasteCount} from "../../../../utils";
import {WasteSlice} from "../../../../interfaces/WasteSlice";
import WasteSection from "./WasteSection";
import AddNoteSection from "./AddNoteSection";
import TableContainer from "../../tab/TableContainer";


const PermanentModifiersModal = ({category, itemId, chainCode, modifierId, row}: PermanentModifiersModalProps) => {
    const {t} = useTranslation()
    const [waste, setWaste] = useState<WasteSlice[]>([])
    const [note, setNote] = useState<string>("")
    const {processStep} = useSocketEmit()
    const {closeModal} = useModal()
    const {refreshProcessingItems} = useProcessingItems()

    const handleSubmit = () => {
        if (window.confirm(t(`processing.ripeningChamber.modifiers.${category}.confirmation`, {waste: getWasteCount(waste)}) as string)) {
            const options = ProcessRipeningChamberModel({
                chainCode, itemId, modifiers: [{id: modifierId, amount: 1}], waste: waste, note: note
            })

            processStep(options).catch(err => {
                console.error(err)
                // alert(t(`processing.ripeningChamber.error.${err.result.error.code}`))
            }).finally(() => {
                refreshProcessingItems()
                closeModal()
            })
        }
    }

    return (
        <TableContainer>
            <VStack space={theme.spacing["spacing-04"]} style={{paddingTop: theme.spacing["spacing-04"]}}>
                <Text>{t(`processing.ripeningChamber.modifiers.${category}.modal`)}</Text>
                <WasteSection chainCode={chainCode} setWaste={setWaste} data={row.rackInfo}/>
                <AddNoteSection setNote={setNote} text={t(`processing.ripeningChamber.notes.addNote`)}/>
                <HStack style={{justifyContent: 'center', alignItems: 'center'}}>
                    <Button
                        text={`${t('commons.register')} ${t(`commons.modifiers.${category}`)}`}
                        onClick={handleSubmit}/>
                </HStack>
            </VStack>
        </TableContainer>
    );
};

export default PermanentModifiersModal;

import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectedSeasonerSelector, setSelectedSeasoner} from "../store/seasonerForm/seasonerFormSlice";

const useSelectedSeasoner = () => {
    const seasonerForm = useSelector(selectedSeasonerSelector)
    const dispatch = useDispatch()

    const setSelectedSeasonerId = ({chainCode, seasonerId}) => {
        const found = seasonerForm.find(item => item.chainCode === chainCode)
        let updatedForm = [...seasonerForm];
        if (found) {
            updatedForm = seasonerForm.map(item => {
                if (item.chainCode === chainCode)
                    return {...item, seasonerId: seasonerId}
                else return item
            })
        } else {
            updatedForm.push({chainCode: chainCode, seasonerId: seasonerId})
        }

        dispatch(setSelectedSeasoner(updatedForm))
    }

    return {
        seasonerForm,
        setSelectedSeasonerId
    }
};

export default useSelectedSeasoner;

export const validateTankAdd = ({options, row}) => {
    const sumCheck = options.destination.waste + options.destination.loadAmount + options.destination.discrepancy
    return (sumCheck <= row.amount && sumCheck !== 0)
}

export const validateHotChamberAdd = ({options, row}) => {
    const sumCheck = options.destination.waste
    return sumCheck <= row.amount
}

export const validateTerminateSeasoning = ({options, row}) => {
    const currentWaste = options.destination.waste
    const amount = row.amount
    return currentWaste <= amount
}

export const validatePolyvalentAdd = ({options, row, capacity}) => {
    // console.log({options, row, capacity})
    const total = parseInt(options.destination.loadAmount) + parseInt(options.destination.waste)
    return row.amount >= total && capacity >= total && total !== 0
}

export const validatePressAdd = ({row, blends}) => {
    const hasValidLoadAmount = parseInt(row.loadAmount + row.waste) > 0
    if (blends.length === 0 && hasValidLoadAmount) return true
    return false
}

export const validateRipeningChamberAdd = (options) => {
    if (!options) return true
    if (options.destination.modifiers?.length > 0) {
        return false
    }
    return true
}

export const validatePackagingAdd = ({options}) => {
    if (!options) return false
    if (options.destination.modifiers?.length > 0) {
        return true
    }
    return false
}


export const validateStartSeasoning = (options) => {
    if (!options) return true
    if (options.destination.seasonerId && options.destination.weight) {
        return false
    }
    return true
}

export const validateChangeSeasoner = (options) => {
    if (!options) return true
    if (options.destination.seasonerId && options.destination.weight) return false
    return true
}

import {useProcessingItems} from "../../../contexts/ProcessingItemsContext";
import {config} from "../../../config";
import {theme} from "../../../theme";
import {useEffect, useState} from "react";

const PackagingModifiersSelector = ({updatevalue, data, type}) => {
    const {processingItems} = useProcessingItems()
    const [modifiers, setModifiers] = useState([])

    useEffect(() => {
        const found = processingItems.find(item => item.step === config.processingSteps.STEP_07)
        if (found) {
            setModifiers(found.items[0].modifiers)
        }

    }, [processingItems])

    const handleChange = (e) => {
        const modifierId = modifiers.find(modifier => modifier.lotId === e.target.value).id
        updatevalue({id: data.id, type: type, value: [{id: modifierId}]})
    }

    return (
        <select style={{
            border: `1px solid ${theme.colors.lightGrey}`,
            width: '100%',
            height: 40,
            borderRadius: 10,
            ...theme.components.Text.variants.default_text,
        }} onChange={handleChange}
                defaultValue={'-- Seleziona un tipo --'}>
            <option disabled>-- Seleziona un tipo --</option>
            {
                modifiers.map((modifier, i) => {
                    return <option key={i} defaultValue={'Seleziona...'}
                                   value={modifier.lotId}>{modifier.lotId}</option>
                })
            })
        </select>
    )
}

export default PackagingModifiersSelector

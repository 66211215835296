import React from 'react';
import {useSocketEmit} from "../../../../hooks/useSocketEmit";
import {useTranslation} from "react-i18next";
import Button from "../../../ui/Button/Button";
import useLoads from "../../../../hooks/useLoads";
import {useModal} from "../../../../contexts/ModalContext";

const RemoveLoadFromRackButton = ({data}) => {
    const {removeLoad} = useSocketEmit()
    const {t} = useTranslation()
    const {refreshLoads} = useLoads(data.itemId)
    const {closeModal} = useModal()

    const handleClick = () => {
        const options = {itemId: data.itemId}
        removeLoad(options).then((r) => {
            refreshLoads()
            closeModal()
        }).catch(() => {
            alert(t('common.errors.api.generic'))
        })
    }

    return (
        <Button onClick={handleClick} text={t('components.processStepButton.remove')}/>
    );
};

export default RemoveLoadFromRackButton;

import React from 'react';
import Text from "../../ui/Text";
import {theme} from "../../../theme";
import {useFormik} from "formik";
import {CloseDefinitivelySchema} from "../../../validators/CloseDefinitivelySchema";
import {useSocketEmit} from "../../../hooks/useSocketEmit";
import TextArea from "../../ui/TextArea";
import CloseDisputeFormBottomContainer from "../dispute/CloseDisputeFormBottomContainer";
import {useTranslation} from "react-i18next";
import {useModal} from "../../../contexts/ModalContext";

const CloseDefinitively = ({blendId}) => {
    const {t} = useTranslation()
    const {closeDefinitively} = useSocketEmit()
    const {resetModal} = useModal()

    const {values, handleChange, handleSubmit, errors} = useFormik({
        initialValues: {
            blendIds: [blendId && blendId],
            reason: ''
        },
        validationSchema: CloseDefinitivelySchema,
        onSubmit: async (values) => {
            if (window.confirm(t('dispute.confirmations.closeDefinitively'))) {
                closeDefinitively(values).then(() => resetModal()).catch(console.error)
            }
        },
    });

    return (
        <div style={{paddingTop: theme.spacing['spacing-05']}}>
            <div style={{paddingBottom: theme.spacing["spacing-06"]}}>
                <Text style={{fontWeight: 'bold', color: theme.colors.error}}>Attenzione! L'operazione non è
                    reversibile. Una volta chiusa il pastore non potrà più confermare il carico e sulla blockchain
                    risulterà un carico senza conferma.
                    Se sei davvero sicuro di voler chiudere la transazione inserisci il motivo e conferma la
                    chiusura.</Text>
            </div>
            <form onSubmit={handleSubmit}>
                <TextArea id={'reason'} name={'reason'} handleChange={handleChange}
                          value={values.reason} errors={errors.reason}/>
                <CloseDisputeFormBottomContainer errors={errors.reason}/>
            </form>
        </div>
    );
};

export default CloseDefinitively;

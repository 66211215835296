import error from './ic-error.svg'
import success from './ic-success.svg'
import verified from './ic-verified.svg'
import warning from './ic-warning.svg'
import delivered from './ic-delivered.svg'
import closed from './ic-closed.svg'
import verifiedAndDisputed from './ic-verified-and-disputed.svg'
import {theme} from "../../../../theme";
import {config} from "../../../../config";

export const icons = {
    'verified': {
        src: verified,
        color: theme.colors.primary
    },
    'error': {
        src: error,
        color: theme.colors.error
    },
    'success': {
        src: success,
        color: theme.colors.success
    },
    'warning': {
        src: warning,
        color: theme.colors.warning
    },
    'delivered': {
        src: delivered,
        color: theme.colors.lightGrey
    },
    'verifiedAndDisputed': {
        src: verifiedAndDisputed,
        color: theme.colors.primary
    },
    'closed': {
        src: closed,
        color: theme.colors.success
    }
}


export const getStatusIcon = ({status, dispute}) => {
    const processorVerified = status === config.blendStatus.PROCESSOR_VERIFIED

    if (dispute && processorVerified) {
        return icons.warning
    }

    switch (status) {
        case config.blendStatus.ORIGIN_DISPUTED:
        case config.blendStatus.PROCESSOR_CLOSED : {
            return icons.closed
        }
        case config.blendStatus.DELIVERED: {
            return icons.delivered
        }
        case config.blendStatus.ORIGIN_ACCEPTED: {
            return icons.success
        }
        case config.blendStatus.PROCESSOR_VERIFIED: {
            return icons.verified
        }
        case config.blendStatus.ORIGIN_REJECTED: {
            return icons.error
        }
        default:
            return icons.success
    }
}

import moment from "moment";

export const HistoryModel = (data) => {
    const historyModel = {
        chainCode: data.chainCodes[0],
        closeReason: data.closeReason,
        deliveryIds: data.deliveryIds,
        originName: data.origin?.name || "N/A",
        id: data.id,
        ownerId: data.ownerId,
        createdAt: moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss') || "N/A",
        dispute: data.dispute || null,
        parentIds: data.parentIds,
        params: data.params,
        status: data.status,
        plateId: data.params?.plateId,
        tankId: data.params?.tankId,
        quantity: data.params?.quantity,
        report: data.report,
    }

    return historyModel
}

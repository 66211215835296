import React from 'react';
import {config} from "../../config";

const ProcessTankModel = (data, itemId) => {
    return ({
        step: config.processingSteps.STEP_01,
        source: {
            deliveryId: data.deliveryId,
            tankId: data.tankId,
        },
        destination: {
            itemId: itemId,
            loadAmount: data.loadAmount || 0,
            waste: data.waste || 0,
            discrepancy: data.discrepancy || 0
        },
    })
};

export default ProcessTankModel;

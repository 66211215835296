import React from 'react';

const RackLoadsModel = (data) => {
    const normalizedRackLoads = data.flatMap(item => {
        if (item.blends?.length > 0) {
            return ({
                ...item.blends[0],
                rackInfo: {name: item.name, amount: item.amount, rackId: item.id},
            })
        }
        return []
    })

    const loads = []
    normalizedRackLoads.forEach(load => {
        if (loads.length > 0) {
            const index = loads.findIndex(item => load.chainCodes[0] === item.chainCodes[0])
            if (index !== -1) {
                const oldItemIds = loads[index].itemIds
                const updatedItemIds = [...oldItemIds, load.itemId]
                loads[index].itemIds = updatedItemIds
                loads[index].amount += load.amount
                loads[index].originalAmount += load.amount
                const oldRackInfo = loads[index].rackInfo
                const updatedRackInfo = [...oldRackInfo, {...load.rackInfo, waste: load.waste}]
                loads[index].rackInfo = updatedRackInfo
            } else {
                loads.push({
                    ...load,
                    waste: [],
                    rackInfo: [{...load.rackInfo, waste: load.waste}],
                    itemIds: [load.itemId]
                })
            }
        } else {
            loads.push({
                ...load,
                waste: [],
                rackInfo: [{...load.rackInfo, waste: load.waste}],
                itemIds: [load.itemId]
            })
        }
    })
    // todo explore useMemo / useCallback
    return loads
};

export default RackLoadsModel;

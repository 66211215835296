import React from 'react';
import Button from "../../../ui/Button/Button";
import {useTranslation} from "react-i18next";
import {useModal} from "../../../../contexts/ModalContext";
import ChangeSeasonerModal from "./ChangeSeasonerModal";
import {useSelector} from "react-redux";
import {isInternalSeasoner} from "../../../../utils";
import {usersSelector} from "../../../../store/users/usersSlice";


const ChangeSeasonerButton = ({data}) => {
    const {t} = useTranslation()
    const {openModal} = useModal()
    const seasonerId = data.params?.seasonerId
    const {users} = useSelector(usersSelector)

    const handleClick = () => {
        openModal({
            title: t('processing.seasoning.changeSeasoner'),
            child: <ChangeSeasonerModal data={data}/>
        })
    }

    return (
        <>
            {isInternalSeasoner({seasonerId, users}) &&
                <Button text={t('processing.seasoning.changeSeasoner')} onClick={handleClick}/>
            }
        </>
    );
};

export default ChangeSeasonerButton;

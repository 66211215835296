import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import useProcessingItem from "../../../../hooks/useProcessingItem";
import useRipeningChamberInit from "../../../../hooks/useRipeningChamberInit";
import LoadsTab from "../LoadsTab";
import AddTab from "../AddTab";
import RackLoadsModel from "../../../../models/loadTabs/RackLoadsModel";
import {FormProvider} from "../../../../hooks/useForm";
import StepperModal from "../StepperModal";
import {seasoningAddColumns} from "../../../../data/tables/seasoningAddColumns";
import {seasoningLoadsColumns} from "../../../../data/tables/seasoningLoadsColumns";
import SeasoningModel from "../../../../models/processingItems/SeasoningModel";

const Seasoning = ({id}) => {
    const {t} = useTranslation()
    const {processItem, loading, refreshProcessingItems} = useProcessingItem(id, SeasoningModel)
    const [tabData, setTabData] = useState(null)
    const {initRipeningChamberLoads} = useRipeningChamberInit()

    const [sourceItems, setSourceItems] = useState([])
    const [blends, setBlends] = useState(null)

    useEffect(() => {
        if (sourceItems && blends) {
            const tab1 = <LoadsTab loads={blends} extraData={{columns: seasoningLoadsColumns}}/>
            const tab2 = <AddTab loads={RackLoadsModel(sourceItems)} itemId={id}
                                 refreshProcessingItems={refreshProcessingItems}
                                 columns={seasoningAddColumns}/>

            setTabData({
                tabs: [t('processing.tank.tab.load'),
                    t('processing.tank.tab.add')],
                contentItems: [tab1, tab2]
            })
        }
    }, [sourceItems, blends])

    useEffect(() => {
        if (sourceItems?.length > 0) {
            initRipeningChamberLoads(RackLoadsModel(sourceItems))
        }
    }, [sourceItems])

    useEffect(() => {
        if (!loading && processItem) {
            setSourceItems(processItem?.sourceItems || [])
            setBlends(processItem.blends)
        }
    }, [processItem, loading])


    return (
        <FormProvider>
            {tabData &&
                <StepperModal tabData={tabData} initialTabIndex={1}/>
            }
        </FormProvider>
    );
};

export default Seasoning;

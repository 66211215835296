import React, {useEffect, useState} from 'react';
import {useSocketEmit} from "./useSocketEmit";

const useProcessingItem = (id, model) => {
    const {getProcessItemById} = useSocketEmit()
    const [processItem, setProcessItem] = useState()
    const [loading, setLoading] = useState(true)


    const refreshProcessingItems = () => {
        setLoading(true)
        getProcessItemById(id).then(res => {
            if (model) {
                return setProcessItem(model(res))
            } else return setProcessItem(res)
        }).catch(console.error).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        refreshProcessingItems()
    }, [])

    return {
        processItem,
        loading,
        refreshProcessingItems
    }
};

export default useProcessingItem;

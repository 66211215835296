import ModifierSelector from "../../components/molecules/modals/ripeningChamber/ModifierSelector";
import {Trans} from "react-i18next";

export const ripeningChamberAddColumns = ({resetForm, itemId}) => [
    {
        name: <Trans i18nKey={'commons.id'}/>,
        cell: row => row.chainCodes[0],
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={'commons.weightKg'}/>,
        selector: row => {
            const weight = row.params?.weight
            return weight ? `${weight} Kg` : ''
        },
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={'commons.forms'}/>,
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: <Trans i18nKey={'commons.applyProcessing'}/>,
        cell: row => {
            return <ModifierSelector row={row} itemId={itemId}/>
        }
    },
];

import React from 'react';
import {theme} from "../../../../theme";
import DataTable from "../../DataTable";
import {ripeningChamberLogColumns} from "../../../../data/tables/ripeningChamberLogColumns";

const RipeningChamberExpandedComponent = ({data}) => {
    return (
        <div style={{paddingLeft: theme.spacing["spacing-08"], paddingRight: theme.spacing["spacing-08"]}}>
            <DataTable
                highlightOnHover={false}
                columns={ripeningChamberLogColumns}
                data={data.modifiers}
            />
        </div>
    );
};

export default RipeningChamberExpandedComponent;

import React, {useEffect, useState} from 'react';
import {formatHistory} from "../../utils";
import {useHistory} from "../../contexts/HistoryProvider";
import HistoryContainer from "./history/HistoryContainer";
import HistoryTable from './history/HistoryTable';


const Analytics = () => {
    const {history, refreshHistory} = useHistory()
    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        refreshHistory()
    }, [refreshHistory])

    useEffect(() => {
        if (history) {
            setTableData(formatHistory(history))
        }
    }, [history])


    return (
        <HistoryContainer>
            {tableData && <HistoryTable isAnalytics={true} tableData={tableData}/>}
        </HistoryContainer>
    );
};

export default Analytics;

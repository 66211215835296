const _config = {
  analytics: {
    events: {
      processing: {
        LABEL: 'processing',
        PROCESS_STEP: 'process_step',
        TERMINATE_SEASONING: 'terminate_seasoning',
      },

    },
  },
  modifiers: {
    categories: {
      SALT: 'salt',
      ANTIMOLD: 'antimold',
      WASHING: 'washing',
    },
  },
  filters: {
    rack: {
      EMPTY: 'empty',
      FULL: 'full',
      ALL: 'all',
    },
  },
  loginRoles: {
    USER: 'user',
    DIARY: 'diary',
    PROCESSOR: 'processor',
    ADMIN: 'admin',
    ISSUER: 'issuer',
    SEASONER: 'seasoner',
    INTERNAL_SEASONER: 'internal-seasoner',
    EXTERNAL_SEASONER: 'external-seasoner',
    STORAGE_MANAGER: 'storage-manager',
  },
  processStatus: {
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable',
    CLOSED: 'closed',
    BUSY: 'busy',
    RESET: 'reset',
  },
  processingSteps: {
    STEP_01: 'step-01',
    STEP_02: 'step-02',
    STEP_03: 'step-03',
    STEP_04: 'step-04',
    STEP_05: 'step-05',
    STEP_06: 'step-06',
    STEP_07: 'step-07',
    STEP_08: 'step-08',
    STEP_09: 'step-09',
  },
  events: {
    CONNECTED: 'connected',
    CONNECT_ERROR: 'connect_error',
    CLOSED: 'closed',
    ERROR: 'error',
  },
  actions: {
    REMOVE_LOAD: 'empty-item',
    VERIFY: 'verify',
    PROCESS_STEP: 'process-step',
    GET_PROCESS_ITEMS: 'get-process-items',
    BLEND_HISTORY: 'blend-history',
    CREATE_DISPUTE: 'dispute',
    CLOSE: 'close',
    CLOSE_STEP: 'close-step',
    GET_DELIVERIES: 'deliveries',
    ADD_PROCESS_ITEM: 'add-process-item',
    SET_PROCESSING_ITEM_STATUS: 'set-process-item-status',
    LIST_USERS: 'list-users',
    LIST_PRODUCTS: 'list-products',
    LIST_MODIFIERS: 'list-modifiers',
    UPDATE_USER: 'update-user',
    DISABLE_USER: 'disable-user',
    ADD_PRODUCT: 'add-product',
    ADD_MODIFIER: 'add-modifier',
    GET_TRUCKS: 'get-trucks',
    ADD_TRUCK: 'add-truck',
    ENABLE_TRUCKS: 'add-trucks',
    SET_TRUCK_STATUS: 'set-truck-status',
    BLEND_ADD_REPORT: 'blend-add-report',
  },

  productStatus: {
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable',
  },

  productType: {
    SOURCE: 'source',
    DESTINATION: 'destination',
  },

  modifierType: {
    PERMANENT: 'permanent',
    CONSUMABLE: 'consumable',
  },

  modifierStatus: {
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable',
  },

  truckStatus: {
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable',
  },

  modifierUnits: {
    '-': '-',
    'gr': 'gr',
    'lt': 'lt',
    'C': 'C',
    'sec': 'sec',
  },

  routes: {
    ROOT: '/',
    HOME: '/dashboard',
    PROFILE: '/profile',
    LOGIN: '/login',
    RECOVER_PASSWORD: '/recovery',
    UNAUTHORIZED: '/unauthorized',
    CREATE_USERS: '/create-users',
    AGING: '/aging',
    MISSING: '*',
  },
  endpoints: {
    LOGIN: 'user/authenticate',
    REFRESH: 'user/token/refresh',
    SEND_RECOVERY_LINK: 'user/recover',
  },
  blendStatus: {
    CREATED: 'created',
    DELIVERED: 'delivered',
    PROCESSOR_VERIFIED: 'processor-verified',
    ORIGIN_ACCEPTED: 'origin-accepted',
    ORIGIN_REJECTED: 'origin-rejected',
    ORIGIN_DISPUTED: 'origin-disputed',
    PROCESSOR_CLOSED: 'processor-closed',
  },
  disputeCTAs: {
    CREATE_DISPUTE: 'createDispute',
    DISPUTE_DETAILS: 'disputeDetails',
  },
  listActionCTAs: {
    CREATE_DISPUTE: 'createDispute',
    DISPUTE_DETAILS: 'disputeDetails',
    DELETE: 'delete',
  },
  roles: {
    ORIGIN: 'origin',
    CARRIER: 'carrier',
    PROCESSOR: 'processor',
    EXTERNAL_SEASONER: 'external-seasoner',
    INTERNAL_SEASONER: 'internal-seasoner',
  },
  polyvalent: {
    MODIFIER: 'modifier',
    MODIFIERS: 'modifiers',
    modifiers: {
      TEMPERATURE: 'temperatura',
      TIME: 'tempo',
      rennet: 'Caglio',
      enzymes: 'Fermenti',
      dropdownTypes: {
        MODIFIERS: 'modifiers',
        LOT: 'lotId',
      },
    },
  },
  input: {
    LOAD_AMOUNT: 'loadAmount',
    WASTE: 'waste',
    WEIGHT: 'weight',
    WEIGHT_LOSS: 'weightLoss',
    DISCREPANCY: 'discrepancy',
    MODIFIERS: 'modifiers',
  },
  qrCodeLinkTemplate: 'https://stage.unblended.it/?apiUrl=%%backendUrl%%/chaincode/%%chainCode%%',
  userStatus: {
    ACTIVE: 'active',
    DISABLED: 'disabled',
  },
  blockchainSyncStatuses: {
    NOT_SYNCHRONIZED: 'not-synchronized',
    SYNCHRONIZED: 'synchronized',
    TO_BE_UPDATED: 'to-be-updated',
    TO_BE_REGISTERED: 'to-be-registered',
    TO_BE_DISABLED: 'to-be-disabled',
  },
  modifierCategories: {
    ENZYMES: 'enzymes',
    RENNET: 'rennet',
    SALT: 'salt',
    PACKAGING: 'packaging',
    TIME: 'time',
    TEMPERATURE: 'temperature',
    WASHING: 'washing',
  },
  analyticsReportEvaluation: ['POSITIVE', 'NEGATIVE'],
};

const MODIFIERS = _config.polyvalent.modifiers.dropdownTypes.MODIFIERS;
const LOT = _config.polyvalent.modifiers.dropdownTypes.LOT;
const config = _config;

config.editableUsers = [config.roles.ORIGIN, config.roles.CARRIER, config.roles.EXTERNAL_SEASONER, config.roles.INTERNAL_SEASONER];

export { MODIFIERS, LOT, config };

import React from 'react';
import TabItem from "./TabItem";

const TabItems = (props) => {
    const {data, setTabIndex, tabIndex} = props

    return (
        <>
            {
                data.tabs.map((tab, i) => (
                    <TabItem key={i} id={i} title={tab} setTabIndex={setTabIndex} tabIndex={tabIndex}
                             isLastItem={data.tabs.length === (i + 1)}/>))
            }
        </>
    );
};

export default TabItems;

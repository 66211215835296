import React from 'react';
import {useModal} from "../../../contexts/ModalContext";
import TankBlendHistory from "../modals/tank/TankBlendHistory";
import ListAction from "../../ui/ListAction";

const TankLoadsDetailButton = ({tankId, deliveryId, plateId}) => {
    const {openModal} = useModal()

    const handleClick = () => {
        openModal({
            title: tankId,
            child: <TankBlendHistory deliveryId={deliveryId} tankId={tankId} plateId={plateId}/>
        })
    }
    
    return (
        <ListAction text='Dettagli' onClick={handleClick}/>
    );
};

export default TankLoadsDetailButton;

import React, {useEffect, useState} from 'react';
import { useModifiers } from '../../contexts/ModifiersProvider';
import { formatModifier } from '../../utils';
import ModifiersContainer from './modifiers/ModifiersContainer';
import ModifiersTable from './modifiers/ModifiersTable';


const Modifiers = () => {
    const {modifiers} = useModifiers();
    const [tableData, setTableData] = useState([])


    useEffect(() => {
        if (modifiers) {
            setTableData(formatModifier(modifiers))
        }
    }, [modifiers])


    return (
        <ModifiersContainer>
            <ModifiersTable tableData={tableData}/>
        </ModifiersContainer>
    );
};

export default Modifiers;

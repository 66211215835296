import {createContext, useContext, useState} from "react";

export const TableFormContext = createContext(0);

export const useTableForm = () => useContext(TableFormContext)

export const TableFormProvider = ({children}) => {
    const [tableForm, setTableForm] = useState(null);

    const resetTableForm = () => {
        setTableForm(null)
    }

    return (
        <TableFormContext.Provider value={{tableForm, setTableForm, resetTableForm}}>
            {children}
        </TableFormContext.Provider>
    )
};

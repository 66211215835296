export const nameValidator = {
  required: true,
  minLength: { value: 3, message: 'modifiers.common.errors.minLength' },
  maxLength: { value: 1000, message: 'modifiers.common.errors.maxLength' },
};

export const lotIdValidator = {
  required: true,
  minLength: { value: 3, message: 'modifiers.common.errors.minLength' },
  maxLength: { value: 20, message: 'modifiers.common.errors.maxLength' },
};

export const amountValidator = {
  required: true,
  min: {value:1, message:'modifiers.common.errors.minValue'},
  valueAsNumber: true,
};




import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import HistoryContainer from '../components/layout/history/HistoryContainer';
import AddTab from '../components/molecules/modals/AddTab';
import Button from '../components/ui/Button/Button';
import Center from '../components/ui/Center';
import { config } from '../config';
import { agingColumns } from '../data/tables/agingColumns';
import { FormProvider } from '../hooks/useForm';
import useProcessingItems from '../hooks/useProcessingItems';
import { logout } from '../store/user/userSlice';

const Aging = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { processItem, loading, refreshProcessingItems } = useProcessingItems(config.processingSteps.STEP_09);
  const [tableData, setTableData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      refreshProcessingItems();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (processItem) {
      console.log('processITem', processItem);
      if (processItem[0].blends?.length > 0) {
        console.log('processItem[0].blends', processItem[0].blends);
        setTableData(processItem[0].blends);
      } else setTableData([]);
    }
  }, [processItem]);

  const handleRefresh = () => {
    setRefresh(true);
  };

  return (
    <FormProvider>
      <HistoryContainer>
        <Center>
          <Button text={t('logout')} onClick={() => dispatch(logout())}/>
        </Center>
        {tableData.length > 0 &&
          <AddTab
            loads={tableData} columns={agingColumns}
            itemId={''}
            step={t(`processing.steps.step-09`)}
            refreshProcessingItems={handleRefresh}
          />
        }
      </HistoryContainer>
    </FormProvider>
  );
};

export default Aging;

import React, {useEffect, useState} from 'react';
import {racksTabColumns} from "../../../../data/tables/racksTabColumns";
import TableContainer from "../../tab/TableContainer";
import DataTable from "../../DataTable";
import TableFilter from "../../tableFilters/HistoryFilter";
import useProcessingItems from "../../../../hooks/useProcessingItems";
import {config} from "../../../../config";
import {racksFilterRules} from "../../../../utils";
import {useTranslation} from "react-i18next";
import {theme} from "../../../../theme";
import RackEmptyFilter from "../../tableFilters/RackEmptyFilter";

const Racks = () => {
    const [filterText, setFilterText] = React.useState('');
    const [emptyFilter, setEmptyFilter] = React.useState(null);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const {processItem: racks, loading} = useProcessingItems(config.processingSteps.STEP_05)
    const [filteredItems, setFilteredItems] = useState([])
    const {t} = useTranslation()

    useEffect(() => {
        if (emptyFilter) {
            switch (emptyFilter) {
                case config.filters.rack.EMPTY: {
                    const updatedValues = racks?.filter(item => item.amount === 0)
                    setFilteredItems(updatedValues)
                    break;
                }
                case config.filters.rack.FULL: {
                    const updatedValues = racks?.filter(item => item.amount > 0)
                    setFilteredItems(updatedValues)
                    break;
                }
                default:
                    setFilteredItems(racks)
            }
        } else setFilteredItems(racks)
    }, [emptyFilter])

    useEffect(() => {
        if (!loading && racks) {
            const filteredData = racks?.filter(
                item => {
                    const result = racksFilterRules(item, filterText)
                    return result
                },
            )
            setFilteredItems(filteredData)
        }
    }, [loading, filterText])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <>
                <RackEmptyFilter onFilter={e => setEmptyFilter(e.target.value)}/>
                <TableFilter onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                             inputPlaceholder={t("processing.rack.filterPlaceholder")}
                             clearButtonText={t("processing.rack.removeFilter")}
                             filterText={filterText}/>
            </>
        );
    }, [filterText, emptyFilter, resetPaginationToggle]);

    const conditionalRowStyles = [
        {
            when: row => !row.blends[0]?.chainCodes[0],
            style: {
                backgroundColor: theme.colors.lightGrey,
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }]

    return (
        <TableContainer>
            <DataTable
                subHeader={true}
                subHeaderComponent={subHeaderComponentMemo}
                highlightOnHover={false}
                columns={racksTabColumns}
                data={filteredItems}
                conditionalRowStyles={conditionalRowStyles}
            />
        </TableContainer>
    );
};

export default Racks;

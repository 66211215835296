import React from 'react';
import {theme} from "../../../theme";
import ModalInput from "../../ui/ModalInput";
import HStack from "../../ui/HStack";
import {useTranslation} from "react-i18next";
import CreateDispute from "../../molecules/modals/CreateDispute";
import {useModal} from "../../../contexts/ModalContext";
import Text from "../../ui/Text";
import Box from "../../ui/Box/Box";
import {hasSuggestedAmount, isStatusFinal} from "../../../utils";
import {useSocketEmit} from "../../../hooks/useSocketEmit";
import {useProcessingItems} from "../../../contexts/ProcessingItemsContext";

const DisputeDetailProposal = ({dispute, rowData}) => {
    const {t} = useTranslation()
    const {openModal, closeModal} = useModal()
    const canAcceptRequest = hasSuggestedAmount(dispute)
    const {createDispute} = useSocketEmit()
    const {refreshProcessingItems} = useProcessingItems()

    const showDisputeCTAs = !isStatusFinal(rowData.status)

    const createNewRequest = () => {
        openModal({
            title: t('dispute.disputeDetails.makeNewRequest'),
            child: <CreateDispute id={rowData.id} quantity={rowData.params.quantity}/>,
        })
    }

    const handleClick = () => {
        if (window.confirm(t('dispute.confirmations.acceptRequest'))) {
            const body = {
                blendId: dispute.id,
                message: t('dispute.createDispute.processorAcceptedMessage'),
                amount: dispute.proposal.suggestedAmount
            }
            createDispute(body).then(() => {
                refreshProcessingItems()
                closeModal()
            }).catch(console.error)
        }
    }

    return (
        <HStack style={{paddingTop: theme.spacing["spacing-06"]}}>
            <ModalInput placeholder={t('dispute.disputeDetails.lt')}
                        title={t('dispute.disputeDetails.originalAmount')}
                        value={dispute.originalAmount}
                        readOnly/>
            <ModalInput placeholder={t('dispute.disputeDetails.lt')}
                        title={t('dispute.disputeDetails.amount')}
                        value={dispute.amount}
                        readOnly/>
            <ModalInput placeholder={t('dispute.disputeDetails.lt')}
                        title={t('dispute.disputeDetails.suggestedAmount')}
                        value={dispute.proposal?.suggestedAmount}
                        readOnly/>
            {showDisputeCTAs && <Box style={{
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer'
            }}>
                {canAcceptRequest && <Text variant={'h5'} color={theme.colors.primary}
                                           onClick={handleClick}>{t('dispute.disputeDetails.acceptRequest')}</Text>}
                <Text variant={'h5'} color={theme.colors.primary}
                      onClick={createNewRequest}>{t('dispute.disputeDetails.makeNewRequest')}</Text>
            </Box>}
        </HStack>
    );
};

export default DisputeDetailProposal;

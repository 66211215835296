import React from 'react';
import chevronRight from '../../assets/icons/table/chevron.right.svg'
import chevronLeft from '../../assets/icons/table/chevron.left.svg'
import IconButton from "../ui/IconButton";
import {useHistory} from "../../contexts/HistoryProvider";

const CustomPagination = ({firstElementId, lastElementId, filter}) => {
    const {refreshPaginationHistory} = useHistory()

    const handlePaginationBack = () => {
        const options = {startingAfter: firstElementId, ...filter}
        console.log('handlePaginationBack', options)
        refreshPaginationHistory(options)
    }

    const handlePaginationNext = () => {
        const options = {endingBefore: lastElementId, ...filter}
        console.log('handlePaginationNext', options)
        refreshPaginationHistory(options)
    }

    return (
        <div style={{
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-end'
        }}>
            <div style={{flexDirection: 'row', display: 'flex'}}>
                <IconButton src={chevronLeft} onClick={handlePaginationBack}/>
                <IconButton src={chevronRight} onClick={handlePaginationNext}/>
            </div>
        </div>
    );
};

export default CustomPagination;

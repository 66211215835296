import React from "react";
import {theme} from "../../../theme";
import Button from "../../ui/Button/Button";

export default function CloseDisputeFormBottomContainer({errors}) {
    return <div style={{
        paddingTop: theme.spacing["spacing-09"],
        justifyContent: "center",
        flexDirection: "row",
        display: "flex"
    }}>
        <div style={{paddingRight: theme.spacing["spacing-04"]}}>
            <Button type={"submit"} text={"Chiudi definitivamente"} background={theme.colors.error}
                    disabled={errors}/>
        </div>
    </div>;
}

import React from 'react';
import Message from "./Message";
import HR from "../../ui/HR";
import useWindowSize from "../../../hooks/useWindowSize";

const MessageList = ({dispute}) => {
    const windowSize = useWindowSize()

    return (
        <div style={{height: windowSize.height * .8 - 180, overflowY: 'scroll',}}>
            {dispute.messages?.map((message, i) => {
                return (
                    <React.Fragment key={i}>
                        <Message message={message}/>
                        <HR/>
                    </React.Fragment>
                )
            }).reverse()}
        </div>
    );
};

export default MessageList;

import React, {useEffect} from 'react';
import Button from "../../../ui/Button/Button";
import {useFormik} from "formik";
import {CloseStep02Model} from "../../../../models/CloseStep02Model";
import {useModifiers} from "../../../../contexts/ModifiersFormContext";
import {useSocketEmit} from "../../../../hooks/useSocketEmit";
import HStack from "../../../ui/HStack";
import ModalInput from "../../../ui/ModalInput";
import DataTable from "../../DataTable";
import {polyvalentModifiersColumns} from "../../../../data/tables/polyvalentModifiersColumns";
import {disableCloseStepButton} from "../../../../utils";
import {useModal} from "../../../../contexts/ModalContext";
import {theme} from "../../../../theme";
import Text from "../../../ui/Text";
import ProductSelector from "../../selects/ProductSelector";
import { config } from '../../../../config'

const ModifiersForm = ({processItem}) => {
    const {initModifiers, formValues, resetModifiersFormValues, productId} = useModifiers()
    const {closeStep} = useSocketEmit()
    const {closeModal} = useModal()
    const temperature = processItem.modifiers?.find(item => item.category === config.modifierCategories.TEMPERATURE)
    const time = processItem.modifiers?.find(item => item.category === config.modifierCategories.TIME)
    const hasModifiers = temperature && time

    useEffect(() => {
        initModifiers(processItem)
    }, [])

    const {values, handleChange, handleSubmit, errors, isValid, isSubmitting} = useFormik({
        initialValues: {
            time: time?.amount / 60,
            temperature: temperature?.amount,
        },
        onSubmit: (values) => {
            const options = CloseStep02Model(processItem, values, formValues, productId)
            console.log('modifiers', options)
            closeStep(options).catch(console.error).finally(() => {
                resetModifiersFormValues()
                closeModal()
            })
        },
    });

    return (
        <form onSubmit={handleSubmit} style={{paddingTop: theme.spacing["spacing-04"]}}>
            <Text variant={'h3'} style={{fontWeight: 400, padding: theme.spacing["spacing-05"]}}>Modificatori</Text>
            <ProductSelector/>
            {hasModifiers && <HStack>
                <ModalInput placeholder={'°'} title={'Temperatura Polivalente'} type={'number'}
                            id={'temperature'} name={'temperature'}
                            onChange={handleChange} value={`${values.temperature}`}
                />
                <ModalInput id={'time'} name={'time'} placeholder={'minuti...'}
                            title={'Tempo (min)'} type={'number'} value={`${values.time}`}
                            onChange={handleChange}
                />
            </HStack>}
            {formValues.length > 0 &&
                <DataTable
                    title={'Ingredienti'}
                    highlightOnHover={false}
                    columns={polyvalentModifiersColumns}
                    data={formValues}
                />
            }
            <Button text={'Salva'} type='submit' style={{marginTop: 4}}
                    disabled={disableCloseStepButton(values, processItem, formValues, productId)}/>
        </form>
    );
};

export default ModifiersForm;

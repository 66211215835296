import React, {useEffect, useState} from 'react';
import useProcessingItem from "../../../../hooks/useProcessingItem";
import PackagingModel from "../../../../models/processingItems/PackagingModel";
import {groupByChainCode} from "../../../../utils";
import AddTab from "../AddTab";
import {packagingAddColumns} from "../../../../data/tables/packagingAddColumns";
import {config} from "../../../../config";
import {FormProvider} from "../../../../hooks/useForm";
import {useModal} from "../../../../contexts/ModalContext";
import QRCode from "../../qrcode/QRCode";

const Packaging = ({id}) => {
    const {processItem, loading, refreshProcessingItems} = useProcessingItem(id, PackagingModel)
    const [data, setData] = useState([])
    const [isBusy, setIsBusy] = useState(false)
    const {closeModal} = useModal()

    useEffect(() => {
        if (!loading && processItem) {
            const {sourceItems} = processItem
            if (sourceItems.length > 0) {
                setData(groupByChainCode(sourceItems[0].blends))
            }
        }
    }, [processItem])

    useEffect(() => {
        if (processItem) {
            setIsBusy(processItem.processStatus === config.processStatus.BUSY)
        }
    }, [processItem])

    const handleSubmit = () => {
        closeModal()
    }

    return (
        <FormProvider>
            {isBusy && <QRCode chainCode={processItem.blends[0]?.chainCodes[0]}/>}
            {!isBusy && data &&
                <AddTab loads={data} itemId={id} step={config.processingSteps.STEP_07}
                        refreshProcessingItems={refreshProcessingItems} handleSubmit={handleSubmit}
                        columns={packagingAddColumns}/>
            }
        </FormProvider>
    );
};

export default Packaging;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import StepperModal from "../StepperModal";
import {FormProvider} from "../../../../hooks/useForm";
import useProcessingItem from "../../../../hooks/useProcessingItem";
import RackLoadsModel from "../../../../models/loadTabs/RackLoadsModel";
import useRipeningChamberInit from "../../../../hooks/useRipeningChamberInit";
import LoadsTab from "../LoadsTab";
import AddTab from "../AddTab";
import {ripeningChamberAddColumns} from "../../../../data/tables/ripeningChamberAddColumns";
import {ripeningChamberLoadsColumns} from "../../../../data/tables/ripeningChamberLoadsColumns";
import {normalizeBlendsByModifiers} from "../../../../utils";
import RipeningChamberExpandedComponent from "./RipeningChamberExpandedComponent";

const RipeningChamberModel = (data) => {
    return {...data.item, sourceItems: data.sourceItems}
}

const RipeningChamber = ({id}) => {
    const {t} = useTranslation()
    const {processItem, loading, refreshProcessingItems} = useProcessingItem(id, RipeningChamberModel)
    const [tabData, setTabData] = useState(null)
    const [sourceItems, setSourceItems] = useState(null)
    const [blends, setBlends] = useState(null)
    const {initRipeningChamberLoads} = useRipeningChamberInit()

    useEffect(() => {
        if (sourceItems && blends) {
            const itemList = normalizeBlendsByModifiers(blends)
            const tab1 = <LoadsTab loads={itemList} extraData={{columns: ripeningChamberLoadsColumns}} expandableRows
                                   expandableRowsComponent={RipeningChamberExpandedComponent}/>
            const tab2 = <AddTab loads={RackLoadsModel(sourceItems)} itemId={id}
                                 refreshProcessingItems={refreshProcessingItems}
                                 columns={ripeningChamberAddColumns}/>

            setTabData({
                tabs: [t('processing.tank.tab.load'),
                    t('processing.tank.tab.add')],
                contentItems: [tab1, tab2]
            })
        }
    }, [sourceItems, blends])

    useEffect(() => {
        if (sourceItems?.length > 0) {
            initRipeningChamberLoads(RackLoadsModel(sourceItems))
        }
    }, [sourceItems])

    useEffect(() => {
        if (!loading && processItem) {
            setSourceItems(processItem.sourceItems)
            setBlends(processItem.blends)
        }
    }, [processItem, loading])


    return (
        <FormProvider>
            {tabData &&
                <StepperModal tabData={tabData} initialTabIndex={1}/>
            }
        </FormProvider>
    );
};

export default RipeningChamber;

import LoadAmountColumn from "../../components/molecules/modals/tank/LoadAmountColumn";
import ProductColumnField from "../../components/molecules/columnFields/ProductColumnField";

export const tankLoadsColumns = [
    {
        name: 'Veicolo',
        selector: row => `${row.params?.plateId} · ${row.params?.tankId}`,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Tipo Latte',
        cell: row => <ProductColumnField productId={row.params?.productId}/>,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Trasferito',
        cell: row => {
            return <LoadAmountColumn row={row}/>
        },
        sortable: true,
        reorder: true,
    },
    {
        name: 'Perso',
        selector: row => row.waste,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Discrepanza',
        selector: row => row.discrepancy,
        sortable: true,
        reorder: true,
    },
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/ui/Button/Button';
import moment from "moment";

const TranslateKey = ({translateKey}) => {
    const {t: pippo} = useTranslation()
    return (
      <>{pippo(translateKey)}</>
    )
}
export function analyticsColumns(handleReport) {
    return [
    {
        name: <TranslateKey translateKey="history.fields.date"/> ,
        selector: row => moment(row.createdAt).format('DD/MM/YY HH:mm:ss'),
        sortable: true,
        reorder: true,
        hide: 'md'
    },
    {
        name: <TranslateKey translateKey="history.fields.origin"/> ,
        selector: row => row.originName,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="history.fields.amount"/> ,
        selector: row => row.quantity,
        sortable: true,
        reorder: true,
    },
    {
        name: <TranslateKey translateKey="analytics.common.evaluation.label"/> ,
        selector: row => row.report?.evaluation ? <TranslateKey translateKey={"analytics.common.evaluation.values." + row.report?.evaluation}/> : '',
        sortable: true,
        reorder: true,
    },
    {
        selector: row => <Button text={!row.report ? <TranslateKey translateKey="analytics.addReport"/> : <TranslateKey translateKey="analytics.editReport"/>} onClick={()=>handleReport(row)}/>,
        style: {
            justifyContent:'right',
        }
    },
]};

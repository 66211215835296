import React from 'react';
import TableInput from "../../ui/TableInput";
import {useTranslation} from "react-i18next";

const ProcessStepInput = ({type, data, disabled, placeholder, placeholderKey, updatevalue}) => {
    const {t} = useTranslation()

    const handleChange = (e) => {
        updatevalue({id: data.id, type: type, value: parseInt(e.target.value) || 0})
    }

    return (
        <div style={{padding: 4}}>
            <TableInput onChange={handleChange} placeholder={placeholderKey ? t(placeholderKey) : placeholder}
                        disabled={disabled} value={data[type]}/>
        </div>
    );
};

export default ProcessStepInput;

import RemoveLoadFromRackButton from "../../components/molecules/modals/rack/RemoveLoadFromRackButton";

export const rackLoadsColumns = () => [
    {
        name: 'Identificativo',
        cell: row => row.chainCodes[0],
        sortable: true,
        reorder: true,
    },
    {
        name: 'Forme Trasferite',
        selector: row => row.amount,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Perso',
        selector: row => row.waste,
        sortable: true,
        reorder: true,
    },
    {
        cell: row => {
            return <RemoveLoadFromRackButton data={row}/>
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: true,
        reorder: true,
    },
];

import { useSelector } from 'react-redux';
import History from "../components/layout/History";
import Analytics from "../components/layout/Analytics";
import Products from '../components/layout/Products';
import Modifiers from '../components/layout/Modifiers';
import QualityAnalysis from '../components/layout/QualityAnalysis';
import QuantityAnalysis from '../components/layout/QuantityAnalysis';
import Trucks from '../components/layout/Trucks';
import Tab from "../components/molecules/tab/Tab";
import {useTranslation} from "react-i18next";
import TabLayout from "../components/layout/TabLayout";
import Processors from "../components/layout/Processors";
import { config } from '../config';
import { userSelector } from '../store/user/userSlice';

const Home = () => {
    const {t} = useTranslation()
    const user = useSelector(userSelector)

    let tabs = [<Processors/>]
    let tabsLabel = [t('components.navbar.production')];
    let startIndex = 0
    if( user.roles.includes(config.loginRoles.PROCESSOR)){
        tabs = [<History/>, <Processors/>, <Analytics/>, <QuantityAnalysis user={user}/>, <QualityAnalysis user={user}/>]
        tabsLabel = [
          t('components.navbar.carriers'),
            t('components.navbar.production'),
            t('components.navbar.analytics'),
            t('components.navbar.quantityAnalysis'),
            t('components.navbar.qualityAnalysis')];
        startIndex = 1;
    }

    if( user.roles.includes(config.loginRoles.STORAGE_MANAGER)){
        tabs = [<Processors/>, <Products/>, <Modifiers/>, <Trucks/> ]
        tabsLabel = [t('components.navbar.production'), t('components.navbar.products'), t('components.navbar.modifiers'), t('components.navbar.trucks')]
    }

    const data = {
        tabs:tabsLabel,
        contentItems: tabs
    }

    return (
        <TabLayout>
            <Tab data={data} hasProfile={true} title={t('components.navbar.title')} hasPadding={true}
                 initialTabIndex={startIndex}/>
        </TabLayout>
    );
}

export default Home

import React from 'react';
import {theme} from "../../theme";

const Input = React.forwardRef((props, ref) => {
    return (
        <input
            ref={ref}
            {...props}
            style={{...styles.root, ...props.style}}
        />
    );
});

const styles = {
  root: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.colors.lightGrey,
    borderRadius: 10,
    paddingLeft: theme.spacing["spacing-05"],
    paddingRight: theme.spacing["spacing-05"],
    paddingTop: theme.spacing["spacing-05"],
    paddingBottom: theme.spacing["spacing-05"],
    ...theme.components.Text.variants.h5,
  },
}

export default Input;

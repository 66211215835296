import React from 'react';
import {theme} from "../../theme";

const TextArea = ({handleChange, id, name, value, errors, placeholder}) => {
    return (
        <textarea id={id} name={name} onChange={handleChange}
                  value={value}
                  placeholder={placeholder || 'Spiega il motivo della segnalazione...'}
                  style={{
                      ...theme.components.Text.variants.default_text,
                      padding: theme.spacing["spacing-05"],
                      width: '100%',
                      outline: 'none',
                      borderRadius: 10,
                      height: 284,
                      resize: 'none',
                      border: errors ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.lightGrey}`
                  }}/>
    );
};

export default TextArea;

import React, {useState} from 'react';
import TableInput from "../../../ui/TableInput";
import {updateRipeningChamberSalt} from "../../../../utils";

const SaltInput = ({lotId, setModifiers, maxAmount}) => {
    const [value, setValue] = useState('')

    const handleChange = (e) => {
        const newSaltAmount = e.target.value > maxAmount ? maxAmount : parseInt(e.target.value)
        setValue(newSaltAmount.toString())
        setModifiers(modifier => {
            const returnData = updateRipeningChamberSalt(modifier, newSaltAmount, lotId)
            return returnData
        })
    }

    return (
        <TableInput placeholder={'g'} value={value} type={'number'}
                    onChange={handleChange}/>
    );
};

export default SaltInput;

import React from 'react';
import Text from "../../ui/Text";
import {theme} from "../../../theme";

const TabItem = ({id, title, tabIndex, setTabIndex, isLastItem, ...props}) => {
    const isActiveTab = tabIndex === id

    return (
        <div style={{marginBottom: -1}}>
            <Text {...props}
                  variant={'h3'}
                  color={isActiveTab ? theme.colors.primary : theme.colors.midGrey}
                  style={{
                      paddingBottom: theme.spacing["spacing-04"],
                      borderBottom: isActiveTab && `1px ${theme.colors.primary} solid`,
                      cursor: 'pointer',
                      marginRight: !isLastItem && theme.spacing["spacing-06"],
                      whiteSpace: 'nowrap',
                  }}
                  onClick={() => setTabIndex(id)}>
                {title}
            </Text>
        </div>
    )
}

export default TabItem;

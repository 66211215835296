import React, {useEffect, useState} from 'react';
import {useSocketEmit} from "./useSocketEmit";

const useLoads = (itemId) => {
    const {getProcessItemById} = useSocketEmit()
    const [loads, setLoads] = useState([])

    useEffect(() => {
        refreshLoads()
    }, [])

    const refreshLoads = () => {
        getProcessItemById(itemId).then(res => {
            if (res?.loads) {
                const filteredLoads = res.loads.filter(load => load.type !== 'modifier')
                setLoads(res.loads)
            }
        }).catch(console.error)
    }

    return {
        loads,
        refreshLoads
    }

};

export default useLoads;
